import { createSelector } from 'reselect';

const catalogSelector = state => state.catalog;

export const categoriesDataSelector = createSelector([catalogSelector], catalog => catalog.categories.data);
export const categoriesLoadingSelector = createSelector([catalogSelector], catalog => catalog.categories.loading);
export const categoriesErrorsSelector = createSelector([catalogSelector], catalog => catalog.categories.error);

export const productsDataSelector = createSelector([catalogSelector], catalog => catalog.products.data);
export const productsLoadingSelector = createSelector([catalogSelector], catalog => catalog.products.loading);
export const productsErrorsSelector = createSelector([catalogSelector], catalog => catalog.products.error);

export const productVariantsSelector = createSelector([catalogSelector], catalog => catalog.productVariants.data);
export const productVariantsLoadingSelector = createSelector([catalogSelector], catalog => catalog.productVariants.loading);
export const productVariantsErrorsSelector = createSelector([catalogSelector], catalog => catalog.productVariants.error);