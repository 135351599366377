import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getHistory } from 'app/store/actions/history';
import { getAuditInfo as getOrderAuditInfo } from 'app/store/actions/order';
import { historyDataSelector, historyLoadingSelector, historyErrorsSelector } from 'app/store/selectors/history';
import { auditInfoLoadingSelector as orderAuditInfoLoadingSelector } from 'app/store/selectors/order';
import { Table, TableHeader, Card, LoadingAnimation, Pagination, AuditInfoModal, Link } from 'app/components';
import { ExclamationCircle } from 'react-bootstrap-icons';
import moment from 'moment';
import './index.scss';

const RefundHistoryView = (props) => {
  const { orderId } = props;
  const dispatch = useDispatch();

  const [auditInfo, setAuditInfo] = useState(null);

  const historyData = useSelector(historyDataSelector);
  const historyDataLoading = useSelector(historyLoadingSelector);
  const historyDataError = useSelector(historyErrorsSelector);
  const orderAuditInfoLoading = useSelector(orderAuditInfoLoadingSelector);

  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const sortDir = searchParams.get('sortDir') || 'desc';

  useEffect(() => {
    dispatch(getHistory({ parentType: 'Order', parentId: orderId, entityType: null, page: currentPage, pageSize: pageSize, sortAscending: sortDir }));
  }, [currentPage, pageSize, sortDir, dispatch]);

  const onRowClick = (history) => {
    switch (history.entityType) {
      case 'Order': dispatch(getOrderAuditInfo({ auditUrl: history.auditUrl, cb: (auditInfo) => onAuditInfoReceived(auditInfo) })); break;
      default: break;
    }
  };

  const onAuditInfoReceived = (auditInfo) => {
    setAuditInfo(auditInfo?.events || []);
  }

  const refundLogs = historyData?.historyLogs?.filter(log => log.event === "Refund") || [];

  return (
    <Card className="refund-history-view">
      <Card.Header>
        Refund History
      </Card.Header>
      {(historyDataLoading || orderAuditInfoLoading) && <LoadingAnimation />}
      {historyData && (
        <Card.Body>
          <Table size="small" verticalAlign="top" highlightRows={false}>
            <TableHeader
              sortBy="timestamp"
              sortDir={sortDir}
              options={[
                { id: 'timestamp', label: 'Timestamp', orderable: true },
                { id: 'user', label: 'User' },
                { id: 'source', label: 'Source' },
                { id: 'event', label: 'Event' },
                { id: 'message', label: 'Message', width: "30%" },
                { id: 'action', label: 'Action', width: "105px" },
              ]}
            />
            <tbody className="table-body">
              {refundLogs.map((history) => (
                <tr key={history.id}>
                  <td>{moment(history.dateCreated).local().format('MMM DD, YYYY h:mm:ss a')}</td>
                  <td>{history.userName}</td>
                  <td>{history.entityType}</td>
                  <td>{history.event}</td>
                  <td className="history-description">{history.description}</td>
                  <td>
                    {['Order'].includes(history.entityType) ? (
                      <Link
                        label="View Log"
                        onClick={() => {
                          onRowClick(history)
                        }}
                      />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination totalItems={historyData?.total || 0} />
        </Card.Body>
      )}
      {historyData && refundLogs.length === 0 && !historyDataLoading && (
        <Card.Body>
          <div className="history-data-empty">
            No history data found
          </div>
        </Card.Body>
      )}
      {historyDataError && !historyDataLoading && (
        <Card.Body>
          <div className="history-data-failed">
            <ExclamationCircle />History Data failed to load.  Refresh the page to try again.
          </div>
        </Card.Body>
      )}
      {auditInfo !== null && (
        <AuditInfoModal
          title="Audit Log Details"
          auditInfo={auditInfo}
          onClose={() => setAuditInfo(null)}
        />
      )}
    </Card>
  );
};

export default RefundHistoryView;
