import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  createOrder,
  createOrderSuccess,
  createOrderFailure,
  globalOrdersSearch,
  globalOrdersSearchSuccess,
  globalOrdersSearchFailure,
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  getOrderDetails,
  getOrderDetailsSuccess,
  getOrderDetailsFailure,
  updateShippingAddress,
  updateShippingAddressSuccess,
  updateShippingAddressFailure,
  updateBillingAddress,
  updateBillingAddressSuccess,
  updateBillingAddressFailure,
  cancelOrder,
  cancelOrderSuccess,
  cancelOrderFailure,
  cancelOrderItems,
  cancelOrderItemsSuccess,
  cancelOrderItemsFailure,
  createRefundRequest,
  createRefundRequestSuccess,
  createRefundRequestFailure,
  updateItemImageUrl,
  updateItemImageUrlSuccess,
  updateItemImageUrlFailure,
  getAuditInfo,
  getAuditInfoSuccess,
  getAuditInfoFailure,
} from "app/store/actions/order"
import OrderServices from 'app/services/orderServices';
import HistoryServices from 'app/services/historyServices';
import ShipmentServices from 'app/services/shipmentServices';
import { getLatestHistorySuccess } from 'app/store/actions/history';
import { getShipmentsByOrderIdSuccess } from 'app/store/actions/shipment';

function* doCreateOrder(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.createOrder], data);
    
    if (resp.orderId) {
      yield put(createOrderSuccess(data, resp));
      toast.success("Order Successfully Created", {
        theme: 'colored',
      });
      if(cb) cb(resp.orderId);
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(createOrderFailure(error));
    toast.error("Order Creation Failed", {
      theme: 'colored',
    });
  }
}

function* doGlobalOrdersSearch(action) {
  const data = action.payload;
  
  try {
    const resp = yield call([OrderServices, OrderServices.getOrders], data);
    yield put(globalOrdersSearchSuccess(resp));
    if(data.cb) data.cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(globalOrdersSearchFailure());
    toast.error("Searching Orders Failed", {
      theme: 'colored',
    })
  }
}

function* fetchOrders(action) {
  const data = action.payload;
  
  try {
    const resp = yield call([OrderServices, OrderServices.getOrders], data);
    yield put(getOrdersSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getOrdersFailure());
  }
}

function* fetchOrderDetails(action) {
  const orderId = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
    yield put(getOrderDetailsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getOrderDetailsFailure(error));
  }
}

function* doUpdateShippingAddress(action) {
  const { orderId, data } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.updateOrderDetails], orderId, data);
    if (resp.updated) {
      yield put(updateShippingAddressSuccess(data, resp));
      toast.success("Details Successfully Updated", {
        theme: 'colored',
      })
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateShippingAddressFailure(error));
    toast.error("Details Failed To Update", {
      theme: 'colored',
    })
  }
}

function* doUpdateBillingAddress(action) {
  const { orderId, data } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.updateOrderDetails], orderId, data);
    if (resp.updated) {
      yield put(updateBillingAddressSuccess(data, resp));
      toast.success("Details Successfully Updated", {
        theme: 'colored',
      });
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateBillingAddressFailure(error));
    toast.error("Details Failed To Update", {
      theme: 'colored',
    })
  }
}

function* doCancelOrder(action) {
  const { orderId, data } = action.payload;

  try {
    yield call([OrderServices, OrderServices.cancelOrder], orderId, data);

    // fetch order details and history
    try {
      const updatedOrderDetails = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
      yield put(getOrderDetailsSuccess(updatedOrderDetails));

      const updatedShipmentDetails = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
      yield put(getShipmentsByOrderIdSuccess(updatedShipmentDetails));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    }

    yield put(cancelOrderSuccess());
    toast.success("Cancellation successful", {
      theme: 'colored',
    });
    try {
      const resp = yield call([HistoryServices, HistoryServices.getHistory], 'Order', orderId, null, 1, 5, null);
      yield put(getLatestHistorySuccess(resp));
    } catch (error) {
      // dont bother throwing an error, not a big deal if this fails
    }
  } catch (error) {
    console.error('error', error);
    yield put(cancelOrderFailure(error));
    toast.error("Cancellation Failed", {
      theme: 'colored',
    })
  }
}

function* doCancelOrderItems(action) {
  const { orderId, data } = action.payload;
  try {
    yield call([OrderServices, OrderServices.cancelOrderItems], orderId, data);
    yield put(cancelOrderItemsSuccess(data));
    toast.success("Item cancellation successful", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(cancelOrderItemsFailure(error));
    toast.error("Item cancellation Failed", {
      theme: 'colored',
    })
  }
}

function* doCreateRefundRequest(action) {
  const { data, cb } = action.payload;

  try {
    const resp = yield call([OrderServices, OrderServices.createRefundRequest], data);
    yield put(createRefundRequestSuccess(resp));
    toast.success("Refund request successfully issued", {
      theme: 'colored',
    });
    if(cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(createRefundRequestFailure(error));
    toast.error("Failed to issue refund request", {
      theme: 'colored',
    })
  }
}

function* doUpdateItemImageUrl(action) {
  const { orderId, itemId, data } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.updateItemImageUrl], orderId, itemId, data);
    if (resp.updated) {
      yield put(updateItemImageUrlSuccess({itemId, data}));
      toast.success("Item image successfully updated", {
        theme: 'colored',
      });
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateItemImageUrlFailure(error));
    toast.error("Failed to update item image", {
      theme: 'colored',
    })
  }
}

function* fetchAuditInfo(action) {
  const { auditUrl, cb } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.getAuditInfo], auditUrl);
    yield put(getAuditInfoSuccess(resp));
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getAuditInfoFailure(error));
    toast.error("Failed to get audit info", {
      theme: 'colored',
    })
  }
}


function* watchData() {
  yield takeEvery(createOrder().type, doCreateOrder);
  yield takeEvery(globalOrdersSearch().type, doGlobalOrdersSearch);
  yield takeEvery(getOrders().type, fetchOrders);
  yield takeEvery(getOrderDetails.toString(), fetchOrderDetails);
  yield takeEvery(updateShippingAddress.toString(), doUpdateShippingAddress);
  yield takeEvery(updateBillingAddress.toString(), doUpdateBillingAddress);
  yield takeEvery(cancelOrder.toString(), doCancelOrder);
  yield takeEvery(cancelOrderItems.toString(), doCancelOrderItems);
  yield takeEvery(createRefundRequest.toString(), doCreateRefundRequest);
  yield takeEvery(updateItemImageUrl.toString(), doUpdateItemImageUrl);
  yield takeEvery(getAuditInfo.toString(), fetchAuditInfo);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}