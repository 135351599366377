import React from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from 'app/utils';
import { Clipboard } from 'react-bootstrap-icons';
import './index.scss';

const IconData = ({
  label = '',                     
  className = '',                
  onClick = null,                
  iconOffset = undefined,        
  enableClipboardCopy = false,   
  allowOverflow = false,         
  icon,                         
  hoverIcon = null,              
  maxWidth = null,
  darkMode = false,               
}) => {
  const handleClick = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    } else if (enableClipboardCopy) {
      e.stopPropagation();
      copyToClipboard(label);
    }
  };

  return (
    <div className={`icon-data ${className}`} style={{ maxWidth }}>
      <div className={`icon-data-value ${enableClipboardCopy ? 'allow-copy' : ''} ${allowOverflow ? 'allow-overflow' : ''} ${darkMode ? 'dark-mode' : ''}`}>
        <span
          className={`text-content ${onClick ? 'clickable' : ''} ${darkMode ? 'dark-mode' : ''}`}
          onClick={handleClick}
        >
          <div className="icon-container">
            {React.cloneElement(icon, {
              className: 'button-icon',
              style: { marginTop: iconOffset ? `${iconOffset}px` : undefined }
            })}
          </div>
          <div className="label">{label}</div>
        </span>
        {enableClipboardCopy && !onClick && (
          <div className="data-point-icon">
            {React.cloneElement(hoverIcon || <Clipboard />)}
          </div>
        )}
      </div>
    </div>
  );
};

IconData.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconOffset: PropTypes.number,
  enableClipboardCopy: PropTypes.bool,
  allowOverflow: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  hoverIcon: PropTypes.element,
  maxWidth: PropTypes.string,
  darkMode: PropTypes.bool,
};

export default IconData;
