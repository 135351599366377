import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonIcon, Card, TabbedMenu, IconData, LoadingAnimation } from 'app/components';
import { getFacilityDetails } from 'app/store/actions/vendor';
import { facilityDetailsSelector, facilityDetailsLoadingSelector, facilityDetailsErrorsSelector } from 'app/store/selectors/vendor';
import DetailsView from './DetailsView';
import VasView from './VasView';
import FacilityProductsView from './FacilityProductsView';
import ShippingMappingView from './ShippingMappingView';
import { ArrowLeft, Upc, GeoAlt } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { usePermission } from 'app/permissions';
import './index.scss';

const FacilityDetails = () => {
  const { vendorId, facilityId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const facilityDetails = useSelector(facilityDetailsSelector);
  const loading = useSelector(facilityDetailsLoadingSelector);
  const facilityDetailsErrorsFound = useSelector(facilityDetailsErrorsSelector);

  // get user permissions
  const canViewVendor = usePermission('vendor', 'search');

  useEffect(() => {
    if (facilityId && (!facilityDetails || facilityDetails.facilityId !== facilityId)) {
      dispatch(getFacilityDetails({vendorId, facilityId}));
    }
  }, []);

  const tabs = [
    ...(canViewVendor ? [{
      path: 'facilities',
      label: 'Facility Products',
      content: FacilityProductsView,
    }] : []),
    {
      path: 'shipping-mapping',
      label: 'Shipping Mapping',
      content: ShippingMappingView,
    },
    {
      path: 'vas',
      label: 'VAS',
      content: VasView,
    },
    {
      path: 'details',
      label: 'Facility Details',
      content: DetailsView,
    },
  ];

  const displayHeader = () => {
    if (facilityId) {
      if (facilityDetails && facilityDetails.name) {
        return facilityDetails.name;
      } else if (facilityDetailsErrorsFound || facilityDetails) {
        return (
          <span>Facility Details</span>
        );
      } else {
        return (
          <span>&nbsp;</span>
        );
      }
    } else {
      return 'Add New Facility';
    }
  }

  const filteredTabs = facilityDetails?.facilities?.length > 0 ? tabs : tabs.filter(tab => tab.path !== 'products');

  const address = facilityDetails ? [
    facilityDetails.address?.line1,
    facilityDetails.address?.line2,
    facilityDetails.address?.city,
    facilityDetails.address?.state ? `${facilityDetails.address?.state},` : '',
    facilityDetails.address?.zip,
    facilityDetails.address?.countryCode
  ].filter(Boolean).join(' ') : '';

  return (
    <div className="facility-details">
      {loading && <LoadingAnimation />}
      <Card className="header-card">
        <div className="facility-name">
          <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(`/admin/vendors/${vendorId}`)}  />
          {displayHeader()}
        </div>
        {facilityDetails && (
          <div className="header-data">
            {(facilityDetails.shortId || facilityDetails.facilityId) && (
              <IconData label={facilityDetails.shortId || facilityDetails.facilityId} icon={<Upc />} /> 
            )}
            {address && (
              <IconData label={address} icon={<GeoAlt />} /> 
            )}
          </div>
        )}
        <div className="action-buttons-placeholder"></div>
      </Card>
      <TabbedMenu tabs={filteredTabs} showTabs={Boolean(facilityDetails)} vendorId={vendorId} facilityId={facilityId} />
    </div>
  )
}

export default FacilityDetails;