import { Card, Input, ButtonIcon } from 'app/components'
import { PlusLg } from 'react-bootstrap-icons';
import Filter from './Filter';
import './index.scss';
import React from 'react';

const FilterInfo = props => {
  const {
    blockIndex,
    values,
    data,
    categories,
    editMode,
    errors,
    handleChange,
    submitCount,
    setFieldValue,
    validateForm,
  } = props;

  const addFilter = () => {
    let filterInfo = values.blocks[blockIndex].filterInfo;
    if (!filterInfo) {
      filterInfo = {
        filterName: '',
        filters: [],
      };
    }

    filterInfo.filters.push({
      condition: '-',
      logic: '',
      value: '',
      value2: '',
    });
    
    setFieldValue(`blocks[${blockIndex}].filterInfo`, filterInfo);
  }

  const removeFilter = (filterIndex) => {
    const updatedFilters = values.blocks[blockIndex].filterInfo.filters.filter((_, index) => index !== filterIndex);
    if (updatedFilters.length === 0) {
      setFieldValue(`blocks[${blockIndex}].filterInfo`, null);
      return;
    }
    setFieldValue(`blocks[${blockIndex}].filterInfo.filters`, updatedFilters);
  }

  return (
    <Card className={`filter-component ${editMode && data ? 'no-bot-padding' : ''}`}>
      <Card.Header className={`filter-component-header ${editMode ? 'no-bot-padding' : ''} ${!data ? 'no-filters' : ''}`}>
        {!data && (
          <>
            <div className="filter-header">
              <div>Filters</div>
              <div className="optional-filter-message">Filters are optional.  They allow you specify rules on the block.</div>
            </div>
            {editMode && (
              <div className="section-options">
                <ButtonIcon
                  icon={<PlusLg />}
                  onClick={() => addFilter()}
                />
              </div>
            )}
          </>
        )}
        {!editMode && data && (
          <div>
            <div className="filter-name">{data.filterName}</div>
          </div>
        )}
        {editMode && data && (
          <Input
            label="Filter Name"
            className="filter-name-input"
            name={`blocks.${blockIndex}.filterInfo.filterName`}
            value={data.filterName}
            onChange={handleChange}
            readonly={!editMode}
            placeholder="Filter Name"
            errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filterName}
          />
        )}
      </Card.Header>
      {data && (
        <div className="filter-body">
          {data && (
            <div className="filters-container">
              {data.filters.map((filter, filterIndex) => (
                <React.Fragment key={`filter-${filterIndex}`}>
                  <Filter 
                    blockIndex={blockIndex}
                    filterIndex={filterIndex}
                    values={values}
                    data={filter}
                    categories={categories}
                    editMode={editMode}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    errors={errors}
                    handleChange={handleChange}
                    submitCount={submitCount}
                    setFieldValue={setFieldValue}
                    validateForm={validateForm}
                  />
                  {filterIndex !== data.filters.length - 1 && (
                    <div className={`filter-and-keyword ${editMode ? 'edit-mode' : ''}`}>
                      AND
                    </div>
                  )}
                </React.Fragment>
              ))}
              {data.filters.length === 0 && (
                <div className="malformed-filter-expression">Filter Expression appears malformed : {data.filterExpression}</div>
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  )
}

export default FilterInfo;