import { Card, Dropdown, DataPoint } from 'app/components'
import './index.scss';

const RetryRules = props => {
  const {
    blockIndex,
    values,
    errors,
    editMode,
    handleChange,
    setFieldValue,
    submitCount,
  } = props;

  const retryRules = [{ "Name": "Full", "Id": "Full" }, { "Name": "Split In Group", "Id": "SplitInGroup" }, { "Name": "Split Outside Group", "Id": "SplitOutsideOfGroup" }, { "Name": "Hold Order", "Id": "CustomerCareHold" }];
  
  const onDropdownChange = (e, rule) => {
    if (rule === 1) {
      setFieldValue(`blocks.${blockIndex}.retryRules.rule2`, '');
      setFieldValue(`blocks.${blockIndex}.retryRules.rule3`, '');
    } else if (rule === 2) {
      setFieldValue(`blocks.${blockIndex}.retryRules.rule3`, '');
    }
    handleChange(e);
  };
  
  return (
    <Card className={`retry-rules-component ${editMode ? 'edit-mode' : ''}`}>
      <Card.Header>
        <div className="retry-rules-name">
          Retry Rules
        </div>
      </Card.Header>
      <div className="retry-rules-body">
        {editMode && (
          <>
            <Dropdown
              label="Default"
              name={`blocks.${blockIndex}.retryRules.rule1`}
              value={values.blocks[blockIndex].retryRules.rule1}
              onChange={e => onDropdownChange(e, 1)}
              readonly={true}
              options={retryRules.map(rule => (
                { value: rule.Id, label: rule.Name }
              ))}
              errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.retryRules?.rule1}
            />
            <Dropdown
              label="Rule 1"
              name={`blocks.${blockIndex}.retryRules.rule2`}
              value={values.blocks[blockIndex].retryRules.rule2}
              onChange={e => onDropdownChange(e, 2)}
              options={retryRules.filter(rule => rule.Id === '' || rule.Id !== values.blocks[blockIndex].retryRules.rule1).map(rule => (
                { value: rule.Id, label: rule.Name }
              ))}
              readonly={values.blocks[blockIndex].retryRules.rule1.length === 0 || !editMode}
              errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.retryRules?.rule2}
            />
            <Dropdown
              label="Rule 2"
              name={`blocks.${blockIndex}.retryRules.rule3`}
              value={values.blocks[blockIndex].retryRules.rule3}
              onChange={e => onDropdownChange(e, 3)}
              options={retryRules.filter(rule => rule.Id === '' || (rule.Id !== values.blocks[blockIndex].retryRules.rule1 && rule.Id !== values.blocks[blockIndex].retryRules.rule2)).map(rule => (
                { value: rule.Id, label: rule.Name }
              ))}
              readonly={values.blocks[blockIndex].retryRules.rule2.length === 0 || !editMode}
              errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.retryRules?.rule3}
            />
          </>
        )}
        {!editMode && (
          <>
            <DataPoint title="Default" data={values.blocks[blockIndex].retryRules.rule1} />
            <DataPoint title="Rule 1" data={values.blocks[blockIndex].retryRules.rule2} />
            <DataPoint title="Rule 2" data={values.blocks[blockIndex].retryRules.rule3} />
          </>
        )}
      </div>
    </Card>
  )
}

export default RetryRules;