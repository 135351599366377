import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RoutingView from './RoutingView';
import StrategyEditCreate from './StrategyEditCreate';
import RoutingDetails from './RoutingDetails';

function Routing() {
  return (
    <Routes>
      <Route exact path="/" element={<RoutingView />} />
      <Route exact path="/add-new" element={<StrategyEditCreate />} />
      <Route exact path="/add-new/:merchantId" element={<StrategyEditCreate />} />
      <Route exact path="/:merchantId" element={<RoutingDetails />} />
      <Route exact path="/:merchantId/:strategyId" element={<StrategyEditCreate />} />
    </Routes>
  );
}

export default Routing;