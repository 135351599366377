import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class CatalogServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.CatalogMS.Url
    });

    SetupInterceptors(this.api);
  }

  getCategories = async () => {
    try {
      const response = await this.api.get(`${Config.CatalogMS.Url}/v1/categories`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching catalog data');
    }
  };

  getProducts = async (category, productType, currentPage, pageSize) => {
    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('category', category);
    addParam('productType', productType);
    addParam('page', currentPage);
    addParam('pageSize', pageSize);

    try {
      const response = await this.api.get(`${Config.CatalogMS.Url}/v1/products?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching product listing');
    }
  };

  getProductVariants = async (productId, currentPage, pageSize) => {
    try {
      const response = await this.api.get(`${Config.CatalogMS.Url}/v1/products/${productId}/variants?page=${currentPage}&pageSize=${pageSize}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching product variants');
    }
  };
}

export default new CatalogServices();