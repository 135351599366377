import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { getIntegrationVariables, getMerchantIntegrationVariables, connectToIntegration, updateMerchantVariables, reset } from 'app/store/actions/integration';
import { integrationDetailsSelector, integrationDetailsLoadingSelector, integrationDetailsErrorsSelector } from 'app/store/selectors/integration';
import { currentUserSelector } from 'app/store/selectors/user';
import { Button, ButtonIcon, Card, LoadingAnimation, MessageBar, Input } from 'app/components';
import { ArrowLeft } from 'react-bootstrap-icons';
import './index.scss';

const ConnectVariables = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pluginId, pluginVersionId, merchantPluginId } = useParams();

  const integrationDetails = useSelector(integrationDetailsSelector);
  const loading = useSelector(integrationDetailsLoadingSelector);
  const errorsFound = useSelector(integrationDetailsErrorsSelector);
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (merchantPluginId) {
      dispatch(getMerchantIntegrationVariables({ merchantPluginId }));
    } else {
      dispatch(getIntegrationVariables({ pluginId, pluginVersionId }));
    }
  }, [dispatch, pluginId, pluginVersionId, merchantPluginId]);

  const initialValues = merchantPluginId
    ? integrationDetails?.variableOverrides || {}
    : integrationDetails?.variableNames?.reduce((acc, variable) => {
      acc[variable] = '';
      return acc;
    }, {});

  const validationSchema = object().shape(
    merchantPluginId
      ? Object.keys(integrationDetails?.variableOverrides || {}).reduce((acc, variable) => {
        acc[variable] = string().required('Required');
        return acc;
      }, {})
      : integrationDetails?.variableNames?.reduce((acc, variable) => {
        acc[variable] = string().required('Required');
        return acc;
      }, {})
  );

  return (
    <div className="connect-variables">
      {loading && <LoadingAnimation />}
      {(integrationDetails || errorsFound) && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (merchantPluginId) {
              const data = {
                variableOverrides: {
                  ...values
                }
              }
              dispatch(updateMerchantVariables({ merchantPluginId, data, cb: () => navigate('/admin/integrations/my-integrations') }));
            } else {
              const data = {
                pluginId,
                pluginVersionId,
                merchantId: currentUser.id,
                variableOverrides: {
                  ...values
                }
              };
              dispatch(connectToIntegration({ data, cb: () => navigate('/admin/integrations/my-integrations') }));
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            submitCount,
          }) => (
            <>
              <Card className="connect-variables-header">
                <div className="header-name">
                  <ButtonIcon icon={<ArrowLeft />} onClick={() => {
                    if (merchantPluginId) {
                      navigate(`/admin/integrations/my-integrations`);
                    } else {
                      navigate('/admin/integrations')
                    }
                  }} />
                  {`${merchantPluginId ? 'Edit' : 'Connect to'} ${integrationDetails?.pluginName || 'Integration'}`}
                </div>
                <div className="action-buttons">
                  <>
                    <Button
                      variant="secondary"
                      size="medium"
                      label={errorsFound ? 'Back' : 'Cancel'}
                      onClick={() => {
                        if (merchantPluginId) {
                          navigate(`/admin/integrations/my-integrations`);
                        } else {
                          navigate('/admin/integrations')
                        }
                      }
                      }
                    />
                    {!errorsFound && (
                      <Button
                        variant="primary"
                        size="medium"
                        label={merchantPluginId ? 'Save Changes' : 'Connect'}
                        onClick={() => handleSubmit()}
                      />
                    )}
                  </>
                </div>
              </Card>
              {errorsFound && (
                <MessageBar color="yellow">
                  An error occurred while loading integration data
                </MessageBar>
              )}
              {integrationDetails && !errorsFound && (
                <form onSubmit={handleSubmit}>
                  <Card className="connect-variables-card">
                    <Card.Header>
                      Variables
                    </Card.Header>
                    <Card.Body>
                      <div className="connect-variables-list">
                        {merchantPluginId
                          ? Object.keys(integrationDetails.variableOverrides || {}).map(variable => (
                            <Input
                              key={variable}
                              label={variable}
                              name={variable}
                              value={values[variable]}
                              onChange={handleChange}
                              errorMessage={errors[variable] && submitCount > 0 ? errors[variable] : ''}
                            />
                          ))
                          : integrationDetails?.variableNames && integrationDetails.variableNames.length > 0
                            ? integrationDetails.variableNames.map(variable => (
                              <Input
                                key={variable}
                                label={variable}
                                name={variable}
                                value={values[variable]}
                                onChange={handleChange}
                                errorMessage={errors[variable] && submitCount > 0 ? errors[variable] : ''}
                              />
                            )) : (
                              <div className="no-variables">
                                No variables overrides required.
                              </div>
                            )}
                      </div>
                    </Card.Body>
                  </Card>
                </form>
              )}
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ConnectVariables;
