import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { PermissionDenied, PageNotFound } from 'app/components';
import VendorsView from './VendorsView';
import VendorDetails from './VendorDetails';
import FacilityDetails from './FacilityDetails';
import { usePermission } from 'app/permissions';

function Vendors() {
  // get user permissions
  const canManageVendor = usePermission('vendor', 'create');

  return (
    <Routes>
      <Route exact path="/" element={<VendorsView />} />
      <Route exact path="/create-vendor" element={<Navigate to="details" replace={true} />} />
      <Route path="/create-vendor/*" element={canManageVendor ? <VendorDetails /> : <PermissionDenied />} />
      {canManageVendor && <Route exact path="/:vendorId" element={<Navigate to="facilities" replace={true} />} />}
      <Route path="/:vendorId/add-products" element={canManageVendor ? <VendorDetails /> : <PermissionDenied />} />
      <Route exact path="/:vendorId/add-facility" element={<Navigate to="details" replace={true} />} />
      <Route path="/:vendorId/add-facility/*" element={canManageVendor ? <FacilityDetails /> : <PermissionDenied />} />
      <Route path="/:vendorId/facility/:facilityId/*" element={<FacilityDetails />} />
      <Route path="/:vendorId/*" element={<VendorDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Vendors;