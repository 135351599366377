import { put, takeEvery, call, all } from 'redux-saga/effects';
import { 
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailure,
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  getAllProducts,
  getAllProductsSuccess,
  getAllProductsFailure,
  getProductVariants,
  getProductVariantsSuccess,
  getProductVariantsFailure
} from "app/store/actions/catalog"
import CatalogServices from 'app/services/catalogServices';

function* fetchCategories() {
  try {
    const data = yield call([CatalogServices, CatalogServices.getCategories]);
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getCategoriesFailure(error));
  }
}

function* fetchProducts(action) {
  const { category, productType, currentPage, pageSize } = action.payload;
  try {
    const data = yield call([CatalogServices, CatalogServices.getProducts], category, productType, currentPage, pageSize);
    yield put(getProductsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getProductsFailure(error));
  }
}

function* fetchAllProducts() {
  let currentPage = 1;
  const pageSize = 250;
  let totalProducts = 0;
  let products = [];

  try {
    // fetch the first page to get the total number of products
    const initialResp = yield call(CatalogServices.getProducts, null, null, currentPage, pageSize);
    totalProducts = initialResp.total;
    products = initialResp.products;

    // calculate the total number of pages needed to fetch all products
    const totalPages = Math.ceil(totalProducts / pageSize);

    // fetch remaining pages
    for (currentPage = 2; currentPage <= totalPages; currentPage++) {
      const resp = yield call(CatalogServices.getProducts, null, null, currentPage, pageSize);
      products = products.concat(resp.products);
    }
  
    // store the products
    yield put(getAllProductsSuccess(products));
  } catch (error) {
    console.error('error', error);
    yield put(getAllProductsFailure(error));
  }
}

function* fetchProductVariants(action) {
  const { productId, currentPage, pageSize, cb } = action.payload;
  try {
    const data = yield call([CatalogServices, CatalogServices.getProductVariants], productId, currentPage, pageSize);
    data.productId = productId;
    yield put(getProductVariantsSuccess(data));

    if (cb) cb(data);
  } catch (error) {
    console.error('error', error);
    yield put(getProductVariantsFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getCategories.toString(), fetchCategories);
  yield takeEvery(getProducts.toString(), fetchProducts);
  yield takeEvery(getAllProducts.toString(), fetchAllProducts);
  yield takeEvery(getProductVariants.toString(), fetchProductVariants);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}