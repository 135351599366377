const ShipmentStatusDetails = {
  'available': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    darkModeColor: '#1DB148', // dark green
    darkModeBgColor: 'rgba(29, 177, 72, 0.30)', // light green
    darkModeBorderColor: 'rgba(29, 177, 72, 0.30)', // light green
    displayName: 'Available',
    description: ''
  },
  'discontinued product': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    darkModeColor: '#F2A1A5', // dark red
    darkModeBgColor: 'rgba(218, 30, 40, 0.30)', // light red
    darkModeBorderColor: 'rgba(218, 30, 40, 0.30)', // light red
    displayName: 'Discontinued Product',
    description: ''
  },
  'not set': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    darkModeColor: '#F2A1A5', // dark red
    darkModeBgColor: 'rgba(218, 30, 40, 0.30)', // light red
    darkModeBorderColor: 'rgba(218, 30, 40, 0.30)', // light red
    displayName: 'Not Set',
    description: ''
  },
  'outofstock': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    darkModeColor: '#F2A1A5', // dark red
    darkModeBgColor: 'rgba(218, 30, 40, 0.30)', // light red
    darkModeBorderColor: 'rgba(218, 30, 40, 0.30)', // light red
    displayName: 'Out Of Stock',
    description: ''
  },
  'archived': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    darkModeColor: '#F2A1A5', // dark red
    darkModeBgColor: 'rgba(218, 30, 40, 0.30)', // light red
    darkModeBorderColor: 'rgba(218, 30, 40, 0.30)', // light red
    displayName: 'Archived',
    description: ''
  },
};

export default ShipmentStatusDetails;
