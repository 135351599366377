import {
  getShipmentsByOrderId,
  getShipmentsByOrderIdSuccess,
  getShipmentsByOrderIdFailure,
  updateShipmentById,
  updateShipmentByIdSuccess,
  updateShipmentByIdFailure,
  rerouteShipment,
  rerouteShipmentSuccess,
  rerouteShipmentFailure,
  cancelShipment,
  cancelShipmentSuccess,
  cancelShipmentFailure,
  rejectShipment,
  rejectShipmentSuccess,
  rejectShipmentFailure,
  assignItemsToShipment,
  assignItemsToShipmentSuccess,
  assignItemsToShipmentFailure,
  updatePackageById,
  updatePackageByIdSuccess,
  updatePackageByIdFailure,
  unassignItems,
  unassignItemsSuccess,
  unassignItemsFailure,
  getAuditInfo,
  getAuditInfoSuccess,
  getAuditInfoFailure,
  reset,
} from "app/store/actions/shipment"
import { handleActions } from 'redux-actions';

const initialState = {
  shipments: {
    loading: false,
    data: null,
    error: null,
  },
  auditInfo: {
    loading: false,
    data: null,
    error: null,
  },
}

const shipmentReducer = handleActions(
  {
    [getShipmentsByOrderId]: (state) => ({ 
      ...state,
      shipments: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getShipmentsByOrderIdSuccess]: (state, data) => ({
      ...state,
      shipments: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getShipmentsByOrderIdFailure]: (state, data) => ({ 
      ...state,
      shipments: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [updateShipmentById]: (state) => ({ 
      ...state,
      shipments: {
        ...state.shipments,
        loading: true,
      },
    }),

    [updateShipmentByIdSuccess]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
      },
    }),

    [updateShipmentByIdFailure]: (state, data) => ({ 
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: data.payload,
      },
    }),

    [rerouteShipment]: (state) => ({ 
      ...state,
      shipments: {
        ...state.shipments,
        loading: true,
      },
    }),

    [rerouteShipmentSuccess]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
      },
    }),

    [rerouteShipmentFailure]: (state, data) => ({ 
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: data.payload,
      },
    }),

    [cancelShipment]: (state) => ({ 
      ...state,
      shipments: {
        ...state.shipments,
        loading: true,
        error: null,
      },
    }),

    [cancelShipmentSuccess]: (state, data) => {
      const shipments = [...state.shipments.data];
      const shipmentIndex = shipments.findIndex(s => s.id === data.payload.shipmentId);
      shipments[shipmentIndex].shipmentStatus = 'Canceled';
      
      return ({
      ...state,
      shipments: {
        loading: false,
        data: [
          ...shipments,
        ],  
        error: null,
      },
    })},

    [cancelShipmentFailure]: (state, data) => ({ 
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: data.payload,
      },
    }),

    [rejectShipment]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: true,
      },
    }),

    [rejectShipmentSuccess]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: null,
      },
    }),

    [rejectShipmentFailure]: (state, data) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: data.payload,
      },
    }),

    [assignItemsToShipment]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: true,
      },
    }),

    [assignItemsToShipmentSuccess]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: null,
      },
    }),

    [assignItemsToShipmentFailure]: (state, data) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: data.payload,
      },
    }),

    [updatePackageById]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: true,
      },
    }),

    [updatePackageByIdSuccess]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: null,
      },
    }),

    [updatePackageByIdFailure]: (state, data) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: data.payload,
      },
    }),

    [unassignItems]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: true,
      },
    }),

    [unassignItemsSuccess]: (state) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: null,
      },
    }),

    [unassignItemsFailure]: (state, data) => ({
      ...state,
      shipments: {
        ...state.shipments,
        loading: false,
        error: data.payload,
      },
    }),

    [getAuditInfo]: (state) => ({
      ...state,
      auditInfo: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getAuditInfoSuccess]: (state, data) => ({
      ...state,
      auditInfo: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getAuditInfoFailure]: (state, data) => ({
      ...state,
      auditInfo: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [reset]: () => ({ 
     ...initialState
    })
  },
  initialState,
)

export default shipmentReducer;