import { put, takeEvery, call, all } from "redux-saga/effects";

import {
  bulkUpload,
  bulkUploadFailure,
  bulkUploadSuccess,
  searchInventories,
  searchInventoriesFailure,
  searchInventoriesSuccess,
} from "../actions/inventory";
import inventoryServices from "app/services/inventoryServices";

function* doBulkUpload(action) {
  const { data } = action.payload;
  try {
    const response = yield call(
      [inventoryServices, inventoryServices.bulkUpload],
      data
    );
    yield put(bulkUploadSuccess({ response }));
  } catch (error) {
    console.error("error", error);
    yield put(bulkUploadFailure(error.message));
  }
}

function* doSearch(action) {
  const { page, pageSize, vendorSkuLocationList } = action.payload;
  try {
    const response = yield call(
      [inventoryServices, inventoryServices.searchInventory],
      page,
      pageSize,
      vendorSkuLocationList
    );
    yield put(searchInventoriesSuccess({ response }));
  } catch (error) {
    console.error("error", error);
    yield put(searchInventoriesFailure(error.message));
  }
}

function* watchData() {
  yield takeEvery(bulkUpload.toString(), doBulkUpload);
  yield takeEvery(searchInventories.toString(), doSearch);
}

export default function* rootSaga() {
  yield all([watchData()]);
}
