import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string, array, lazy } from 'yup';
import { webhookDetailsSelector, webhookDetailsLoadingSelector, webhookDetailsErrorsSelector, webhookTestLoadingSelector } from 'app/store/selectors/notification';
import { subscriberSelector } from 'app/store/selectors/user';
import { historyDataSelector, historyLoadingSelector, historyErrorsSelector } from 'app/store/selectors/history';
import { getWebhookDetails, subscribeToWebhook, updateWebhook, testWebhookSubscription, deleteWebhookSubscription } from 'app/store/actions/notification';
import { getHistory, reset as resetHistoryData } from 'app/store/actions/history';
import { Button, ButtonIcon, Card, LoadingAnimation, Dropdown, Table, TableHeader, Input, Link, DataPoint, Modal, MessageBar } from 'app/components';
import { ExclamationCircle, ArrowLeft, Trash3, ThreeDotsVertical } from 'react-bootstrap-icons';
import { usePermission } from 'app/permissions';
import WebhookLogs from './WebhookLogs';
import './index.scss';

const WebhookDetails = (props) => {
  const { startEdit } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { webhookId } = useParams();
  const [searchParams] = useSearchParams();
  const submitFormRef = useRef(null);

  const [editMode, setEditMode] = useState(webhookId && !startEdit ? false : true);
  const [deleteWebhookModal, showDeleteWebhookModal] = useState(null);
  const [deleteDraftModal, showDeleteDraftModal] = useState(null);
  const [actionType, setActionType] = useState('');

  const sortBy = searchParams.get('sortBy') || 'eventType';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);

  const webhookDetails = useSelector(webhookDetailsSelector);
  const webhookDetailsLoading = useSelector(webhookDetailsLoadingSelector);
  const webhookDetailsErrorsFound = useSelector(webhookDetailsErrorsSelector);
  const webhookTestLoading = useSelector(webhookTestLoadingSelector);
  const webhookLogs = useSelector(historyDataSelector);
  const webhookLogsLoading = useSelector(historyLoadingSelector);
  const webhookLogsErrorsFound = useSelector(historyErrorsSelector);
  const subscriber = useSelector(subscriberSelector);

  // get user permissions
  const canManageWebhooks = usePermission('notification', 'create');

  const eventList = [{ "Name": "OrderCreation", "Value": "OrderCreation" }, { "Name": "OrderUpdate", "Value": "OrderUpdate" }, { "Name": "ShipmentCreated", "Value": "ShipmentCreated" }, { "Name": "ShipmentUpdate", "Value": "ShipmentUpdate" }];

  const webhookOptions = [
    ...(canManageWebhooks ? [{ value: 'Delete Draft', label: 'Delete Draft', destructive: true, onClick: () => { showDeleteDraftModal(webhookId); } }] : []),
    ...(canManageWebhooks ? [{ value: 'Delete Webhook', label: 'Delete Webhook', destructive: true, onClick: () => { showDeleteWebhookModal(webhookId) } }] : []),
  ];

  useEffect(() => {
    if (!subscriber) {
      navigate('/admin/webhooks');
    }
  }, [subscriber]);

  useEffect(() => {
    if (webhookId !== webhookDetails?.id) {
      dispatch(resetHistoryData());
      dispatch(getWebhookDetails({ webhookId }));
    }
  }, [webhookId]);

  useEffect(() => {
    if (webhookId !== webhookDetails?.id) {
      dispatch(getHistory({ parentType: 'notification', parentId: webhookId, entityType: 'notification-consumer', page: currentPage, pageSize: pageSize, sortAscending: sortDir }));
    }
  }, [currentPage, pageSize, sortDir, dispatch]);

  const sortEvents = (events) => {
    return events.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return sortDir === 'asc' ? -1 : 1;
      if (a[sortBy] > b[sortBy]) return sortDir === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const addEvent = (selectedEvent, setFieldValue, validateForm, values) => {
    const { events } = values;

    if (selectedEvent && !events.some(event => event.eventType === selectedEvent)) {
      const newEvent = {
        eventType: selectedEvent,
        snippetId: null
      };

      setFieldValue('events', [...events, newEvent], false);

      // call validate form after 1 frame (weird issue where the form is not updated yet when validateForm is called immediately after setFieldValue)
      setTimeout(() => validateForm(), 0);
    }
  };

  const handleHeaderChange = (index, field, value, values, setFieldValue) => {
    const headers = values.headerInfo;
    const lastIndex = headers.length - 1;

    // update the current key/value field
    const updatedHeaders = headers.map((header, idx) =>
      idx === index ? { ...header, [field]: value } : header
    );

    // if the last pair is being edited, add a new empty pair
    if (index === lastIndex && (updatedHeaders[lastIndex].key || updatedHeaders[lastIndex].value)) {
      // check if the last item already has both key and value empty to prevent adding extra empty rows
      if (updatedHeaders[lastIndex].key !== '' || updatedHeaders[lastIndex].value !== '') {
        updatedHeaders.push({ key: '', value: '' });
      }
    }

    setFieldValue('headerInfo', updatedHeaders);
  };

  const renderEventDropdown = (values, errors, submitCount, setFieldValue, validateForm) => {
    const selectedEventTypes = values.events.map(event => event.eventType);

    const unusedEvents = eventList.filter(event => !selectedEventTypes.includes(event.Value))
      .map(event => ({ value: event.Value, label: event.Name }));

    return (
      <Dropdown
        label="Add Events"
        name="selectedEvent"
        placeholder={unusedEvents.length > 0 ? "Select an Event to Add" : "All events have been selected"}
        value={values.selectedEvent}
        disabled={unusedEvents.length === 0}
        onChange={(selectedEvent) => {
          addEvent(selectedEvent.target.value, setFieldValue, validateForm, values);
        }}
        options={unusedEvents}
        errorMessage={submitCount > 0 && errors.events}
      />
    );
  };

  const getFormattedHeaders = (headers) => {
    // check if headers is an object and not null or undefined
    if (headers && typeof headers === 'object') {
      const formattedHeaders = Object.entries(headers).map(([key, value]) => ({
        key,
        value,
      }));
      // add an extra empty object to the end of the array
      formattedHeaders.push({ key: '', value: '' });
      return formattedHeaders;
    }
    // fallback if no headers or invalid format, includes one empty object
    return [{ key: '', value: '' }];
  };

  return (
    <div className="webhook-details">
      {(webhookDetailsLoading || webhookTestLoading || webhookLogsLoading) && <LoadingAnimation />}
      {(!webhookId || webhookDetails || webhookDetailsErrorsFound || webhookLogsErrorsFound) && (
        <>
          <Card className="webhook-details-header">
            <div className="webhook-name">
              <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/webhooks')} />
              {webhookId ? editMode ? 'Edit Draft Webhook' : 'Webhook' : 'Create Webhook'}
            </div>
            <div className="action-buttons">
              {editMode ? (
                <>
                  <Button
                    variant="secondary"
                    size="medium"
                    label="Cancel"
                    onClick={() => webhookDetails ? setEditMode(false) : navigate('/admin/webhooks')}
                  />
                  <Button
                    variant="primary"
                    size="medium"
                    label="Save Draft"
                    onClick={() => {
                      setActionType('saveDraft');
                      submitFormRef.current && submitFormRef.current.handleSubmit();
                    }}
                  />
                  <Button
                    variant="primary"
                    size="medium"
                    label="Publish Draft"
                    onClick={() => {
                      setActionType('publishDraft');
                      submitFormRef.current && submitFormRef.current.handleSubmit();
                    }}
                  />
                </>
              ) : canManageWebhooks ? (
                <Button
                  variant="primary"
                  size="medium"
                  label="Edit"
                  onClick={() => setEditMode(true)}
                />
              ) : null}
              {webhookDetails && webhookOptions.length > 0 && (
                <ButtonIcon icon={<ThreeDotsVertical />} options={webhookOptions} />
              )}
            </div>
          </Card>
          {webhookDetailsErrorsFound && (
            <MessageBar color="yellow">
              An error occurred while loading webhook data
            </MessageBar>
          )}
          {(webhookDetails || !webhookId) && (
            <Formik
              innerRef={submitFormRef}
              enableReinitialize
              initialValues={{
                selectedEvent: '',
                userType: '',
                events: editMode ? (webhookDetails?.draft?.events || webhookDetails?.events || []) : (webhookDetails?.events || []),
                callbackMethod: editMode ? (webhookDetails?.draft?.destinationMethod || webhookDetails?.destinationMethod || 'POST') : (webhookDetails?.destinationMethod || 'POST'),
                callbackUrl: editMode ? (webhookDetails?.draft?.destinationUrl || webhookDetails?.destinationUrl || '') : (webhookDetails?.destinationUrl || ''),
                headerInfo: editMode ? (webhookDetails?.draft?.customHeaders ? getFormattedHeaders(webhookDetails?.draft?.customHeaders) : getFormattedHeaders(webhookDetails?.customHeaders)) : getFormattedHeaders(webhookDetails?.customHeaders),
                isEnabled: editMode ? (webhookDetails?.draft?.isTestSuccessful && webhookDetails?.isEnabled) : (webhookDetails?.isEnabled || false),
                subscriberId: webhookDetails?.subscriberId || '',
                id: webhookDetails?.id || '',
                isTestSuccessful: null,
              }}
              validationSchema={() =>
                object({
                  events: array()
                    .of(
                      object({
                        eventType: string().required("Event type is required"),
                        snippetId: string().notRequired(),
                      })
                    )
                    .min(1, "At least one event is required"),
                  callbackMethod: string().required("Callback method is required"),
                  callbackUrl: string().required("Callback URL is required"),
                  headerInfo: array()
                    .of(
                      lazy(header =>
                        object().shape({
                          key: header.key || header.value ? string().required("Key is required if value is provided") : string().notRequired(),
                          value: header.key || header.value ? string().required("Value is required if key is provided") : string().notRequired(),
                        })
                      )
                    ),
                })
              }
              onSubmit={async (values) => {
                const { callbackUrl, callbackMethod, headerInfo, events } = values;

                // transform the headers from the array of { key, value } objects to an object
                const customHeaders = headerInfo.reduce((acc, header) => {
                  if (header.key && header.value) {
                    acc[header.key] = header.value;
                  }
                  return acc;
                }, {});

                // extract event data, adding transformationId and snippetId if they exist
                const formattedEvents = events.map(event => ({
                  eventType: event.eventType,
                  transformationId: event.transformationId || undefined,
                  snippetId: event.snippetId || undefined
                })).filter(event => event.eventType); // filter out any events without eventType

                // create a data objedct that is a copy of the webhookDetails object
                let data = {};
                if (webhookDetails) {
                  data = { ...webhookDetails };
                } else {
                  data = {
                    id: values.id,
                    subscriberId: subscriber.id,
                    destinationUrl: callbackUrl,
                    destinationMethod: callbackMethod,
                    customHeaders: customHeaders,
                    isEnabled: values.isTestSuccessful === true && webhookDetails?.isEnabled,
                    isTestSuccessful: values.isTestSuccessful,
                    events: formattedEvents,
                  };
                }

                // regardless of the action type (save as draft or publish), we want to update the draft object.
                if (actionType === 'deleteDraft') {
                  data.draft = null;
                } else {
                  data.draft = {
                    id: values.id,
                    subscriberId: subscriber.id,
                    destinationUrl: callbackUrl,
                    destinationMethod: callbackMethod,
                    customHeaders: customHeaders,
                    isEnabled: false,
                    isTestSuccessful: false,
                    events: formattedEvents,
                  };
                }

                // if they are publishing it, we also want to update the main object
                if (actionType === 'publishDraft') {
                  data.destinationUrl = callbackUrl;
                  data.destinationMethod = callbackMethod;
                  data.customHeaders = customHeaders;
                  data.isEnabled = values.isTestSuccessful === true && webhookDetails?.isEnabled;
                  data.isTestSuccessful = values.isTestSuccessful;
                  data.events = formattedEvents;
                }

                if (webhookDetails && actionType === 'deleteDraft') {
                  dispatch(updateWebhook({ data, showToast: true, cb: () => setEditMode(false) }));
                } else if (webhookDetails) {
                  dispatch(updateWebhook({ data, showToast: true, cb: () => navigate(`/admin/webhooks/`) }));
                } else {
                  dispatch(subscribeToWebhook({ data, cb: () => navigate(`/admin/webhooks/`) }));
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                submitCount,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card className={`webhook-details-card ${editMode ? 'webhook-edit-mode' : 'webhook-mode'}`}>
                    <Card.Header>
                      Setup
                      <Button
                        variant="secondary"
                        size="small"
                        label="Test Webhook"
                        onClick={() => dispatch(testWebhookSubscription({ callbackUrl: values.callbackUrl, callbackMethod: values.callbackMethod, headerInfo: values.headerInfo, cb: (results) => setFieldValue('isTestSuccessful', results) }))}
                        disabled={webhookDetailsLoading || !values.callbackUrl}
                      />
                    </Card.Header>
                    <Card.Body>
                      {editMode ? (
                        <div className="edit-mode">
                          <div className="add-event-section">
                            {renderEventDropdown(values, errors, submitCount, setFieldValue, validateForm)}
                          </div>
                          <div className="event-table-section">
                            <div className="event-table-header">
                              Events
                            </div>
                            <Table size="medium">
                              <TableHeader
                                sortBy={sortBy}
                                sortDir={sortDir}
                                options={[
                                  { id: 'eventType', label: 'Event Name', orderable: true },
                                  { id: 'snippetId', label: 'Customization', orderable: true },
                                  { id: 'actions', label: 'Actions' },
                                ]}
                              />
                              <tbody className="table-body">
                                {sortEvents(values.events).map((event) => (
                                  <tr key={event.eventType}>
                                    <td>{event.eventType}</td>
                                    <td>
                                      {!webhookId ? 'Save to Customize' : (
                                        <Link label={event.transformationId ? 'Edit Customization' : 'Create Customization'} onClick={() => {
                                          if (event.transformationId) {
                                            navigate(`/admin/webhooks/${webhookId}/customize?subscriberId=${webhookDetails?.subscriberId}&transformationId=${event.transformationId}&event=${event.eventType}`);
                                          } else {
                                            navigate(`/admin/webhooks/${webhookId}/customize?subscriberId=${webhookDetails?.subscriberId}&event=${event.eventType}`);
                                          }
                                        }} />
                                      )}
                                    </td>
                                    <td>
                                      <ButtonIcon
                                        icon={<Trash3 />}
                                        destructive={true}
                                        onClick={() => {
                                          const newEvents = values.events.filter(e => e.eventType !== event.eventType);
                                          setFieldValue('events', newEvents);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                                {values.events.length === 0 && (
                                  <tr>
                                    <td colSpan="4" className="no-events"><ExclamationCircle /> Select at least one event from the dropdown above to add to this webhook</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <div className="api-request-details-section">
                            <div className="callback-info">
                              <Dropdown
                                label="Callback Method"
                                name="callbackMethod"
                                value={values.callbackMethod}
                                onChange={handleChange}
                                options={[
                                  { value: 'POST', label: `POST` },
                                  { value: 'GET', label: `GET` },
                                  { value: 'PUT', label: `PUT` },
                                  { value: 'PATCH', label: `PATCH` },
                                  { value: 'DELETE', label: `DELETE` },
                                ]}
                                errorMessage={submitCount > 0 && errors.callbackMethod}
                              />
                              <Input
                                label="Callback URL"
                                name="callbackUrl"
                                value={values.callbackUrl}
                                onChange={handleChange}
                                placeholder="Enter a Callback URL"
                                errorMessage={submitCount > 0 && errors.callbackUrl}
                              />
                            </div>
                            <div className="header-info-section">
                              <div className="header-row">
                                <div className="custom-header">Custom Headers</div>
                                <div className="custom-value">Value</div>
                                <div className="trash-can">&nbsp;</div>
                              </div>
                              {values.headerInfo.map((header, index) => (
                                <div className="header-row" key={index}>
                                  <div className="custom-header">
                                    <Input
                                      name={`headerInfo[${index}].key`}
                                      value={header.key}
                                      onChange={e => handleHeaderChange(index, 'key', e.target.value, values, setFieldValue)}
                                      placeholder="Enter a Key"
                                      errorMessage={submitCount > 0 && errors?.headerInfo?.[index]?.key}
                                    />
                                  </div>
                                  <div className="custom-value">
                                    <Input
                                      name={`headerInfo[${index}].value`}
                                      value={header.value}
                                      onChange={e => handleHeaderChange(index, 'value', e.target.value, values, setFieldValue)}
                                      placeholder="Enter a Value"
                                      errorMessage={submitCount > 0 && errors?.headerInfo?.[index]?.value}
                                    />
                                  </div>
                                  <div className="trash-can">
                                    {index < values.headerInfo.length - 1 && (
                                      <ButtonIcon
                                        icon={<Trash3 />}
                                        destructive={true}
                                        onClick={() => {
                                          const newHeaders = values.headerInfo.filter((_, i) => i !== index);
                                          setFieldValue('headerInfo', newHeaders.length ? newHeaders : [{ key: '', value: '' }]);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="view-mode">
                          <div className="event-table-section">
                            <div className="event-table-header">
                              Events
                            </div>
                            <Table size="medium">
                              <TableHeader
                                sortBy={sortBy}
                                sortDir={sortDir}
                                options={[
                                  { id: 'eventType', label: 'Event Name', orderable: true },
                                  { id: 'snippetId', label: 'Customization', orderable: true },
                                ]}
                              />
                              <tbody className="table-body">
                                {sortEvents(values.events).map((event) => (
                                  <tr key={event.eventType}>
                                    <td>{event.eventType}</td>
                                    <td>
                                      {!webhookId ? 'Save to Customize' : event.transformationId ? (
                                        <Link
                                          label="View Customization"
                                          url={`/admin/webhooks/${webhookId}/customize?transformationId=${event.transformationId}&event=${event.eventType}`}
                                          onClick={() => {
                                            navigate(`/admin/webhooks/${webhookId}/customize?transformationId=${event.transformationId}&event=${event.eventType}`);
                                          }}
                                        />
                                      ) : 'No Customization'
                                      }
                                    </td>
                                  </tr>
                                ))}
                                {values.events.length === 0 && (
                                  <tr>
                                    <td colSpan="4" className="no-events"><ExclamationCircle /> Select at least one event from the dropdown above to add to this webhook</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <div className="view-callback-info">
                            <DataPoint
                              title="Callback Method"
                              data={values.callbackMethod}
                            />
                            <DataPoint
                              title="Callback URL"
                              data={values.callbackUrl}
                            />
                          </div>
                          {values.headerInfo?.length > 1 && (
                            <div className="view-header-info">
                              <table className="view-header-table">
                                <thead>
                                  <tr>
                                    <td className="header-title">Custom Headers</td>
                                    <td className="header-title pl-3">Value</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {values.headerInfo.map((header) => (
                                    <tr className="header-list" key={header.key}>
                                      <td className="header-key">{header.key}</td>
                                      <td className="header-value">{header.value}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </form>
              )}
            </Formik>
          )}
          {webhookLogsErrorsFound && (
            <MessageBar color="yellow">
              An error occurred fetching log data
            </MessageBar>
          )}
          {webhookId && webhookLogs && (
            <WebhookLogs
              webhookId={webhookId}
              webhookLogs={webhookLogs}
              sortBy={sortBy}
              sortDir={sortDir}
            />
          )}
          {deleteDraftModal && (
            <Modal
              size="md"
              secondaryButtonLabel="No, Keep It"
              secondaryButtonOnClick={() => { showDeleteDraftModal(null) }}
              primaryButtonLabel="Yes, Delete It"
              primaryButtonVariant="primary"
              primaryButtonDestructive
              primaryButtonOnClick={() => {
                setActionType('deleteDraft');
                submitFormRef.current && submitFormRef.current.handleSubmit();
                showDeleteDraftModal(null);
              }}
              onClose={() => showDeleteDraftModal(null)}
            >
              <div>Are you sure you want to delete this webhook draft?</div>
              <div>This action is not reversible.</div>
            </Modal>
          )}
          {deleteWebhookModal && (
            <Modal
              size="md"
              secondaryButtonLabel="No, Keep It"
              secondaryButtonOnClick={() => { showDeleteWebhookModal(null) }}
              primaryButtonLabel="Yes, Delete It"
              primaryButtonVariant="primary"
              primaryButtonDestructive
              primaryButtonOnClick={() => {
                dispatch(deleteWebhookSubscription({ webhookId: deleteWebhookModal, cb: () => navigate('/admin/webhooks') }));
                showDeleteWebhookModal(null);
              }}
              onClose={() => showDeleteWebhookModal(null)}
            >
              <div>Are you sure you want to delete this webhook subscription?</div>
              <div>This action is not reversible.</div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default WebhookDetails;