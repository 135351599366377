import React from 'react';
import './index.scss';

const CardHeader = ({ hideDivider = false, children, className }) => (
  <div className={`card-header ${hideDivider ? 'hide-divider' : ''} ${className || ''}`}>{children}</div>
);

const CardBody = ({ children, className }) => (
  <div className={`card-body ${className || ''}`}>{children}</div>
);

const Card = ({ className, children }) => (
  <div className={`gooten-card ${className || ''}`}>
    {children}
  </div>
);

Card.Header = CardHeader;
Card.Body = CardBody;

export default Card;