import axios from "axios";
import Config from "../config";
import SetupInterceptors from './axiosInterceptors';

class FilesServices {
    constructor() {
        this.api = axios.create({
            baseURL: Config.FilesMS.Url
        });
        SetupInterceptors(this.api);
    }

    getFile = async (fileId) => {
        try {
            const response = await this.api.get(`/v1/files/${fileId}`);
            return response.data;
        } catch (error) {
            throw new Error('Error fetching file data from API');
        }
    }

    getImageInfo = async (fileId) => {
        try {
            const response = await this.api.get(`/v1/files/${fileId}/imageInfo`);
            return response.data;
        } catch (error) {
            throw new Error('Error fetching image info from API');
        }
    }
}

export default new FilesServices();