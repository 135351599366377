import React, { useState, useMemo } from 'react';
import { Table, TableHeader, Card, Pagination } from 'app/components';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { formatCurrency } from 'app/utils';
import './index.scss';

const DetailsCard = props => {
  const { orderDetails } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showDetails, setShowDetails] = useState(orderDetails.items.length <= 5);

  // use this information to display the current range of items being displayed
  const displayedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return orderDetails.items.slice(startIndex, endIndex);
  }, [orderDetails.items, currentPage, pageSize]);

  // use this information to display the current range of items being displayed
  const totalItems = useMemo(() => orderDetails.items.length, [orderDetails.items]);
  
  return (
    <Card className="details-card">
      <Card.Header>
        Order Details
      </Card.Header>
      <Card.Body>
        <Table size="small" highlightRows={false}>
          <TableHeader
            options={[
              ...(totalItems > 5 ? [{ id: '', label: '' }] : []),
              { id: 'itemName', label: 'Item Name' },
              { id: 'unitPrice', label: 'Unit Price' },
              { id: 'quantity', label: 'Quantity' },
              { id: 'totalPrice', label: 'Total Item Price' },
            ]}
          />
          <tbody className="table-body">
            {totalItems > 5 && (
              <tr className="details-row all-items" onClick={() => setShowDetails(!showDetails)} style={{ cursor: 'pointer' }}>
                <td className="details-chevron">{showDetails ? <ChevronDown /> : <ChevronRight />}</td>
                <td>All Items</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            )}
            {showDetails && displayedItems.map(item => (
              <tr className="details-row" key={item.id}>
                {totalItems > 5 && <td></td>}
                <td className="details-sku">{item.sku}</td>
                <td>{item.customerPrice?.amount && item.customerPrice?.currency ? formatCurrency(item.customerPrice.currency, item.customerPrice.amount) : '-'}</td>
                <td>{item.quantity}</td>
                <td>{item.customerPrice?.amount && item.customerPrice?.currency && item.quantity ? formatCurrency(item.customerPrice.currency, item.customerPrice.amount * item.quantity) : '-'}</td>
              </tr>
            ))}
            <tr className="pagination-row">
              <td colSpan="5">
                {totalItems > 5 && showDetails && (
                  <Pagination
                    totalItems={totalItems || 0} 
                    pageSizes={[5, 10, 20, 50]}
                    hideBelow={10}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onChange={(page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    }}
                  />
                )}
              </td>
            </tr>
            <tr className="total-row">
              <td colSpan={totalItems > 5 ? '3' : '2'}></td>
              <td>Discounts</td>
              <td>
                {orderDetails.customerTotalDiscountAndPromotions?.amount != null && orderDetails.customerTotalDiscountAndPromotions?.currency
                  ? formatCurrency(orderDetails.customerTotalDiscountAndPromotions.currency, orderDetails.customerTotalDiscountAndPromotions.amount)                  
                  : '-'}
              </td>
            </tr>
            <tr className="total-row">
              <td colSpan={totalItems > 5 ? '3' : '2'}></td>
              <td>Total Shipping</td>
              <td>
                {orderDetails.customerTotalShipping?.amount != null && orderDetails.customerTotalShipping?.currency
                  ? formatCurrency(orderDetails.customerTotalShipping.currency, orderDetails.customerTotalShipping.amount)
                  : '-'}
              </td>
            </tr>
            <tr className="total-row extra-border">
              <td colSpan={totalItems > 5 ? '3' : '2'}></td>
              <td>Total Tax</td>
              <td>
                {orderDetails.customerTotalTaxes?.amount != null && orderDetails.customerTotalTaxes?.currency
                  ? formatCurrency(orderDetails.customerTotalTaxes.currency, orderDetails.customerTotalTaxes.amount)
                  : '-'}
              </td>
            </tr>
            <tr className="total-row">
              <td colSpan={totalItems > 5 ? '3' : '2'}></td>
              <td>Order Total</td>
              <td>
                {orderDetails.customerTotal?.amount != null && orderDetails.customerTotal?.currency
                  ? formatCurrency(orderDetails.customerTotal.currency, orderDetails.customerTotal.amount)  
                  : '-'}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default DetailsCard;
