import configJson from './config.json';
import overridesJson from './overrides.json';

const mergeObjects = (target, source) => {
  // iterate over each property in the source object
  for (const key in source) {
    // check if the property exists in both objects and is an object itself
    if (Object.prototype.hasOwnProperty.call(target, key) && typeof target[key] === 'object' && typeof source[key] === 'object') {
      // recursively merge nested objects
      target[key] = mergeObjects(target[key], source[key]);
    } else {
      // otherwise, override the property in the target object
      target[key] = source[key];
    }
  }
  return target;
}

const Config = mergeObjects(configJson, overridesJson);
export default Config;