import React from 'react';
import ProductCard from '../ProductCard';
import './index.scss';

const SelectedProducts = props => {
  const { products, currentlySelectedProducts, onRemoveSelectedProduct } = props;

  const filteredProducts = products.filter(product => currentlySelectedProducts.includes(product.id));

  return (
    <div className="selected-products">
      <div className="category-container">
        <div className="product-container">
          {filteredProducts.map(product => (
            <ProductCard
              key={product.id}
              productInfo={product}
              onProductSelected={() => onRemoveSelectedProduct(product.id)}
              isSelected={true}
            />
          ))}

          {filteredProducts.length === 0 ? (
            <div>No products currently selected.</div>
          ) : (
            <>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SelectedProducts;