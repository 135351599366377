import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PermissionDenied, PageNotFound } from 'app/components';
import IntegrationsView from './IntegrationsView';
import IntegrationDetails from './IntegrationDetails';
import IntegrationsManager from './IntegrationsManager';
import BasicDetails from './BasicDetails';
import ConnectVariables from './IntegrationsView/ConnectVariables';
import { usePermission } from 'app/permissions';

function Integrations() {
  // get user permissions
  const canCreateIntegrations = usePermission('notification', 'create');

  return (
    <Routes>
      <Route exact path="/" element={<IntegrationsView />} />
      <Route exact path="/my-integrations" element={<IntegrationsView />} />
      <Route exact path="/connect-variables/:pluginId/:pluginVersionId" element={<ConnectVariables />} />
      <Route exact path="/edit-variables/:merchantPluginId" element={<ConnectVariables />} />
      <Route exact path="/create-integration" element={canCreateIntegrations ? <BasicDetails /> : <PermissionDenied />} />
      <Route exact path="/manage-integrations" element={<IntegrationsManager />} />
      <Route path="/:pluginId/basic-settings" element={canCreateIntegrations ? <BasicDetails /> : <PermissionDenied />} />
      <Route path="/:pluginId/:pluginVersionId/*" element={<IntegrationDetails />} />
      <Route path="/:pluginId/*" element={<IntegrationDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Integrations;