import { createAction } from 'redux-actions';

const prefix = "HISTORY";

export const getHistory = createAction(`${prefix}/GET_HISTORY`);
export const getHistorySuccess = createAction(`${prefix}/GET_HISTORY_SUCCESS`);
export const getHistoryFailure = createAction(`${prefix}/GET_HISTORY_FAILURE`);

export const getLatestHistory = createAction(`${prefix}/GET_LATEST_HISTORY`);
export const getLatestHistorySuccess = createAction(`${prefix}/GET_LATEST_HISTORY_SUCCESS`);
export const getLatestHistoryFailure = createAction(`${prefix}/GET_LATEST_HISTORY_FAILURE`);

export const reset = createAction(`${prefix}/RESET`);
