import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Dropdown  } from 'app/components';
import { updateFacilityProduct } from 'app/store/actions/vendor';
import './index.scss';

const ChangeStatusModal = props => {
  const { product, searchParams, onClose } = props;
  const dispatch = useDispatch();

  const [status, setStatus] = useState(product.status);

  return (
    <Modal
      className="change-status-modal"
      hideButtons={true}
      onClose={onClose}
    >
      <div className="change-status-modal-title">Edit Product Status</div>
      <div className="change-status-modal-content">
        <Dropdown
          label="Select New Status"
          placeholder="Select New Status"
          name="newStatus"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          options={[
            {value: 'Available', label: `Available`},
            {value: 'OutOfStock', label: `OutOfStock`},
            {value: 'Archived', label: `Archived`},
          ]}
        />
      </div>
      <div className="action-buttons">
        <Button 
          variant="secondary"
          size="small"
          label="Cancel"
          onClick={() => onClose()}
        />
        <Button 
          variant="primary"
          size="small"
          label="Update Status"
          onClick={() => {
              const data = {
                "vendorSku": product.vendorSku,
                "vendorSkuDescription": product.vendorSkuDescription,
                "status": status,
                "productionTimeDays": product.productionTimeDays,
                "productCost": product.productCost,
                "decorations": product.decorations,
              }
              dispatch(updateFacilityProduct({ data, productId: product.id, facilityId: product.facilityId, searchParams }));
              onClose();
            }
          }
        />
      </div>
    </Modal>
  )
}

export default ChangeStatusModal;