import React from 'react';
import { Modal, Button, Link } from 'app/components';
import { copyToClipboard } from 'app/utils';
import { Copy } from 'react-bootstrap-icons';
import moment from 'moment';
import './index.scss';

const TrackingInfoModal = props => {
  const { trackingInfo, showTrackingInfoModal } = props;

  return (
    <Modal
      className="tracking-info-modal"
      title="Tracking Information"
      hideButtons={true}
      onClose={() => showTrackingInfoModal(null)}
    >
      <>
        <div className="tracking-info">
          <div className="details-row">
            <div className="details-label">Tracking Number</div>
            <div className="details-data">{trackingInfo.carrierTrackingNumber ? (
              <div className="tracking-number" onClick={() => copyToClipboard(trackingInfo.carrierTrackingNumber)}>
                <span>{trackingInfo.carrierTrackingNumber}</span>
                <Copy />
              </div>
            ) : '-'}</div>
          </div>
          <div className="details-row">
            <div className="details-label">Carrier</div>
            <div className="details-data">{trackingInfo.carrierName || '-'}</div>
          </div>
          <div className="details-row">
            <div className="details-label">Carrier Method</div>
            <div className="details-data">{trackingInfo.carrierMethodName || '-'}</div>
          </div>
          <div className="details-row">
            <div className="details-label">Ship Date</div>
            <div className="details-data">{trackingInfo.shippedDate ? moment(trackingInfo.shippedDate).local().format('MMM DD, YYYY h:mm a') : '-'}</div>
          </div>
          <div className="details-row">
            <div className="details-label">Filfilled Date</div>
            <div className="details-data">{trackingInfo.fulfilledDate ? moment(trackingInfo.fulfilledDate).local().format('MMM DD, YYYY h:mm a') : '-'}</div>
          </div>
          <div className="details-row">
            <div className="details-label">Tracking URL</div>
            <div className="details-data">
              {trackingInfo.carrierLink ? (
                <Link
                  label={trackingInfo.carrierLink}
                  isRoute={false}
                  url={trackingInfo.carrierLink}
                />
              ) : '-'}
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <Button
            variant="primary"
            size="small"
            label="Close"
            onClick={() => { showTrackingInfoModal(null) }}
          />
        </div>
      </>
    </Modal>
  );
}

export default TrackingInfoModal;
