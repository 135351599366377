import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import OrdersView from './OrdersView';
import OrderDetails from './OrderDetails';
import OrderRefund from './OrderRefund';

function Orders() {
  return (
    <Routes>
      <Route exact path="/" element={<OrdersView />} />
      <Route exact path="/:orderId" element={<Navigate to="summary" replace={true} />} />
      <Route exact path="/:orderId/refund/*" element={<OrderRefund />} />
      <Route path="/:orderId/*" element={<OrderDetails />} />
    </Routes>
  );
}

export default Orders;