import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ProductsList from './ProductsList';
import AddProducts from './AddProducts';

const ProductsView = () => {
  const location = useLocation();
  const { vendorId } = useParams();

  if (location.pathname.includes('/products')) {
    return <ProductsList vendorId={vendorId} />;
  }

  if (location.pathname.includes('/add-products')) {
    return <AddProducts vendorId={vendorId} />;
  }

  return <ProductsList vendorId={vendorId} />;
}

export default ProductsView;
