import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, ToggleSwitch, Dropdown, Input } from 'app/components';
import './index.scss';
import { getMerchantSchema, getConfiguration, updateMerchantConfiguration } from 'app/store/actions/merchant';
import {
  configurationSelector, configurationLoadingSelector, schemaSelector, schemaLoadingSelector,
  settingsSelector,
  merchantSelectedPackingSlipSelector, ConfigTypes, SchemaTypes,
  merchantSelectedReturnAddressType
} from 'app/store/selectors/merchant';
import { LoadingAnimation, Button } from 'app/components'
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

const SettingsView = props => {
  const { merchantId } = props;
  const dispatch = useDispatch();
  const configuration = useSelector(configurationSelector);
  const selectedPackingSlip = useSelector(merchantSelectedPackingSlipSelector);
  const selectedReturnAddressType = useSelector(merchantSelectedReturnAddressType);
  const schema = useSelector(schemaSelector);
  const settings = useSelector(settingsSelector);
  const configLoading = useSelector(configurationLoadingSelector);
  const schemaLoading = useSelector(schemaLoadingSelector)
  const navigate = useNavigate();
  const [showAddressForm, setShowAddressForm] = useState(false);

  useEffect(() => {
    if (merchantId) {
      dispatch(getMerchantSchema());
      dispatch(getConfiguration({ merchantId: merchantId }));
    }
  }, [merchantId]);

  useEffect(() => {
    setShowAddressForm(configuration.data.CustomReturnAddress.ReturnAddressType == "inputCustomAddress");
  }, [configuration]);

  // console.log("configuration", configuration);
  // console.log("schema", schema);
  // console.log("settings", settings);

  const handleConfigurationUpdate = () => {
    dispatch(getConfiguration({ merchantId: merchantId }));
  }

  const handleDropdownChange = (keysHolder, e) => {
    switch (e?.target?.value) {
      case ConfigTypes.CREATE_TEMPLATE:
        navigate(`templates/create-template/packing-slip`);
        break;
      case "inputCustomAddress":
        setShowAddressForm(true);
        break;
      default:
        {
          // perform a deep copy to avoid mutating the original state...
          const updatedConfig = JSON.parse(JSON.stringify(configuration));
          updatedConfig.schemaVersion = schema.version;
          updatedConfig.data[keysHolder.parentKey][keysHolder.key] = e?.target?.value;
          dispatch(updateMerchantConfiguration({ data: updatedConfig, cb: handleConfigurationUpdate }));
        }
        break;
    }
  }

  const handleToggleChange = (obj, value) => {
    const updatedConfig = JSON.parse(JSON.stringify(configuration));

    switch (obj.parentKey) {
      case ConfigTypes.CUSTOM_RETURN_ADDRESS:
        updatedConfig.data[obj.parentKey][obj.key] = value;
        break;
      default:
        updatedConfig.data[obj.key] = value;
        break;
    }
    updatedConfig.schemaVersion = schema.version;
    dispatch(updateMerchantConfiguration({ data: updatedConfig, cb: handleConfigurationUpdate }));
  }

  const addressValidationSchema = Yup.object().shape({
    AddressLine1: Yup.string().required('Address is required'),
    FirstName: Yup.string().required('First Name is required'),
    LastName: Yup.string().required('Last Name is required'),
    City: Yup.string().required('City is required'),
    State: Yup.string().required('State is required'),
    PostalCode: Yup.string().required('Postal code is required'),
    CountryCode: Yup.string().required('Country code is required'),
    Email: Yup.string().email('Invalid email').required('Email is required'),
    Phone: Yup.string().required('Phone is required')
});

  const renderAddressForm = () => {
    const addressHolder = configuration?.data?.CustomReturnAddress?.Address;
    selectedReturnAddressType == "inputCustomAddress"
    return (showAddressForm &&
      <Formik
        enableReinitialize
        initialValues={{
          FirstName: addressHolder.FirstName || '',
          LastName: addressHolder.LastName || '',
          AddressLine1: addressHolder.AddressLine1 || '',
          AddressLine2: addressHolder.AddressLine2 || '',
          City: addressHolder.City || '',
          State: addressHolder.State || '',
          PostalCode: addressHolder.PostalCode || '',
          CountryCode: addressHolder.CountryCode || '',
          Email: addressHolder.Email || '',
          Phone: addressHolder.Phone || ''
        }}
        validationSchema={() =>
          addressValidationSchema
        }
        onSubmit={async (values) => {
          addressValidationSchema.validate(configuration.data.CustomReturnAddress.Address)
          .then(()=>{
              // perform a deep copy to avoid mutating the original state...
              const updatedConfig = JSON.parse(JSON.stringify(configuration));
              updatedConfig.data.CustomReturnAddress.ReturnAddressType = "inputCustomAddress";
              updatedConfig.data.CustomReturnAddress.Address = values;
              updatedConfig.schemaVersion = schema.version;
              dispatch(updateMerchantConfiguration({ data: updatedConfig, cb: handleConfigurationUpdate }));
          });
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          submitCount,
        }) => (
          <form
            onSubmit={handleSubmit}
          >
            <div className='adress-section-holder'>
              <Input
                type="text"
                label="First Name"
                name="FirstName"
                value={values.FirstName}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.FirstName}
              />
              <Input
                type="text"
                label="Last Name"
                name="LastName"
                value={values.LastName}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.LastName}
              />
              <Input
                type="text"
                label="Address Line 1"
                name="AddressLine1"
                value={values.AddressLine1}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.AddressLine1}
              />
              <Input
                type="text"
                label="Address Line 2"
                name="AddressLine2"
                value={values.AddressLine2}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.AddressLine2}
              />
              <Input
                type="text"
                label="City"
                name="City"
                value={values.City}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.City}
              />
              <Input
                type="text"
                label="State"
                name="State"
                value={values.State}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.State}
              />
              <Input
                type="text"
                label="Postal Code"
                name="PostalCode"
                value={values.PostalCode}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.PostalCode}
              />
              <Input
                type="text"
                label="Country Code"
                name="CountryCode"
                value={values.CountryCode}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.CountryCode}
              />
              <Input
                type="email"
                label="Email"
                name="Email"
                value={values.Email}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.Email}
              />
              <Input
                type="text"
                label="Phone"
                name="Phone"
                value={values.Phone}
                onChange={handleChange}
                errorMessage={submitCount > 0 && errors.Phone}
              />
              <Button
                label='Save Address'
                className='save-address-button'
                onClick={handleSubmit}
                disabled={isSubmitting}
                size='small' />
            </div>
          </form>
        )}
      </Formik>
    )
  }


  const renderSettingsItem = (item, parentKey) => {
    const dataSource = parentKey == ConfigTypes.CUSTOM_RETURN_ADDRESS ?
      configuration?.data[parentKey] :
      configuration?.data;

    const toogleItem =
      <div className='settings-item-bool'>
        <ToggleSwitch
          isOn={dataSource[item?.key]}
          onToggle={handleToggleChange.bind(this, { key: item?.key, parentKey })}
        />
        <div className='settings-item-title'>{item?.title || item?.description}</div>
      </div>

    const stringItem =
      <div className='settings-item-string'>
        <div className='settings-item-title'>{item?.title || item?.description}</div>
        <Dropdown
          className='settings-item-dropdown'
          name="dropdownSettings"
          value={parentKey == ConfigTypes.CUSTOM_RETURN_ADDRESS ? selectedReturnAddressType: selectedPackingSlip}
          disabled={false}
          onChange={handleDropdownChange.bind(this, { parentKey, key: item?.key })}
          options={item?.options || []}
        />
      </div>

    const inputItem =
      <div className='settings-item-input'>
        <div className='settings-item-title'>{item?.title || item?.description}</div>
        <Input
          type="text"
          className={'settings-item-input-child'}
          value={null}
          onChange={null}
        />
      </div>

    switch (item?.type) {
      case SchemaTypes.BOOLEAN:
        return toogleItem;
      case SchemaTypes.STRING:
        return stringItem;
      case SchemaTypes.INPUT:
        return inputItem;
      default:
        return null;
    }
  }

  const renderSettings = item => {
    return (
      <div className='settings-holder'>
        {item.settings.map(m => renderSettingsItem(m, item.key))}
        {item.key === ConfigTypes.CUSTOM_RETURN_ADDRESS && renderAddressForm()}
      </div>
    )
  }

  return (
    <div className="merchant-settings-view">
      {(configLoading || schemaLoading) && <LoadingAnimation />}
      {settings.map(item => {
        return (
          <Card key={item.title}>
            <Card.Header>
              {item.title}
            </Card.Header>
            {renderSettings(item)}
          </Card>
        )
      })}
    </div>
  )
}

export default SettingsView;