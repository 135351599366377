import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { array, object, string } from 'yup';
import {
  userDetailsSelector,
  userDetailsLoadingSelector,
  userDetailsErrorsSelector,
  rolesWithPermissionsDataSelector,
  rolesWithPermissionsLoadingSelector,
  rolesWithPermissionsErrorsSelector,
  subscriberSelector
} from 'app/store/selectors/user';
import { getUserDetails, createUser, updateUser, getRolesWithPermissions, requestForgotPassword } from 'app/store/actions/user';
import { merchantsSelector, merchantsLoadingSelector, merchantsErrorsSelector } from 'app/store/selectors/merchant';
import { getMerchants } from 'app/store/actions/merchant';
import { vendorsDataSelector, vendorsLoadingSelector, vendorsErrorsSelector } from 'app/store/selectors/vendor';
import { getVendors } from 'app/store/actions/vendor';
import { Button, Card, LoadingAnimation, Input, Dropdown, ButtonIcon, Checkbox, MultiSelect, MessageBar, DataPoint, IconData } from 'app/components';
import { ArrowLeft, EyeFill, EyeSlashFill, Envelope } from 'react-bootstrap-icons';
import { formatUsersPermissionsName, formatUsersResourceName, formatUsersRolesName } from '../utils';
import { usePermission } from 'app/permissions';
import { Label } from 'semantic-ui-react';
import NotificationsCard from '../NotificationsCard';
import './index.scss';

const UserDetails = () => {
  const { userId } = useParams();

  const notificationsCardRef = useRef();
  const submitFormRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [entities, setEntities] = useState([]);
  const subscriber = useSelector(subscriberSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(userDetailsSelector);
  const userDetailsLoading = useSelector(userDetailsLoadingSelector);
  const userDetailsErrorsFound = useSelector(userDetailsErrorsSelector);

  const allMerchants = useSelector(merchantsSelector);
  const allMerchantsLoading = useSelector(merchantsLoadingSelector);
  const allMerchantsErrorsFound = useSelector(merchantsErrorsSelector);

  const allVendors = useSelector(vendorsDataSelector);
  const allVendorsLoading = useSelector(vendorsLoadingSelector);
  const allVendorsErrorsFound = useSelector(vendorsErrorsSelector);

  const allRolesWithPermissions = useSelector(rolesWithPermissionsDataSelector);
  const allRolesWithPermissionsLoading = useSelector(rolesWithPermissionsLoadingSelector);
  const allRolesWithPermissionsErrorsFound = useSelector(rolesWithPermissionsErrorsSelector);

  // get user permissions
  const canManageUsers = usePermission('users', 'users_create');

  useEffect(() => {
    if (userId && (!userDetails || userDetails.id !== userId)) {
      dispatch(getUserDetails(userId));
    }
    dispatch(getMerchants({ currentPage: 1, pageSize: 250 }));
    dispatch(getVendors({ currentPage: 1, pageSize: 250 }));

    dispatch(getRolesWithPermissions());
  }, []);

  useEffect(() => {
    if (subscriber && subscriber.role?.toLowerCase() === "admin") {
      const vendorsFormatted = allVendors?.vendors ? allVendors?.vendors.map(vendor => (
        { value: `v~${vendor.id}`, label: vendor.name }
      )) : [];

      const merchantsFormatted = allMerchants?.merchants ? allMerchants?.merchants.map(merchant => (
        { value: `m~${merchant.id}`, label: merchant.name }
      )) : [];

      // combine merchants and vendors and sort them
      const combinedEntities = [...merchantsFormatted, ...vendorsFormatted].sort((a, b) => a.label?.localeCompare(b.label));
      setEntities(combinedEntities);
    }
    else {
      if (subscriber && subscriber.role?.toLowerCase().includes("vendor")) {
        const vendorEntity = allVendors?.vendors?.find(vendor => vendor.id == subscriber.id);
        const facilities = vendorEntity?.facilities ? vendorEntity.facilities.map(facility => (
          { value: `f~${facility.id}`, label: facility.name }
        )) : [];
        if (facilities?.length > 0) {
          const combinedEntities = facilities.sort((a, b) => a.label?.localeCompare(b.label));
          setEntities(combinedEntities);
        }
        else {
          setEntities([]);
        }
      }
      else {
        if (subscriber && subscriber.role?.toLowerCase().includes("merchant")) {
          const merchantChilds = allMerchants?.merchants ? allMerchants.merchants?.map(merchant => (
            { value: `m~${merchant.id}`, label: merchant.name }
          )) : [];
          const combinedEntities = merchantChilds?.sort((a, b) => a.label?.localeCompare(b.label));
          setEntities(combinedEntities);
        }
      }
    }
  }, [allVendors, allMerchants, subscriber]);

  const onUserCreated = (newUserId) => {
    if (notificationsCardRef.current) {
      notificationsCardRef.current.saveChanges(newUserId);
    }
    navigate(`/admin/users`);
  }

  const onUserUpdated = () => {
    navigate(`/admin/users`);
  }
  const [showPassword, setShowPassword] = useState(false);

  const displayHeader = () => {
    if (userId) {
      return editMode ? 'Edit User' : (userDetails?.firstName || "") + " " + (userDetails?.lastName || "");
    } else {
      setEditMode(true);
      return 'Create User';
    }
  }

  const displayAccountDetails = () => {
    const roles = userDetails?.roles?.map(r => r?.name).join(', ');
    return <DataPoint title="Roles" data={roles || '-'} />
  }

  const displayPermissions = () => {
    let roleAndPermissions = userDetails?.roles[0];
    
    return (
      <>
        <div className="account-permissions-header" hidden={userDetails?.roles[0]?.id ? false : true}>
          <div>Selected Permissions</div>
        </div>
        <div className="account-permissions-details">
          {roleAndPermissions?.permissionSet?.map(permission => (
            <div key={roleAndPermissions?.id + permission.resourceId}>
              <div className="account-permissions-container">
                <div className="account-permissions-title">{formatUsersResourceName(permission.resourceName)} </div>
                <div className="account-permissions-checkbox">
                  <Checkbox disabled={true} size='medium' checked={permission.associatedPermissions.length == permission.availablePermissions.length}
                  />
                </div>
              </div>
              <div className="account-permissions">
                {permission?.availablePermissions?.map(available =>
                (
                  <div key={roleAndPermissions?.id + permission.resourceId + available.name} className="account-permissions-line" >
                    <div className="account-permissions-title">{available.description?.length ? available.description : formatUsersPermissionsName(available.name)}</div>
                    <div className="account-permissions-checkbox">
                      <Checkbox disabled={true} size='medium' checked={permission.associatedPermissions?.map(ap => ap.name).includes(available.name)} />
                    </div>
                  </div>
                )
                )}</div>
            </div>
          ))}
        </div>
      </>)
  }

  const mappedInitialSelectedOrganization = (userDetails) => {
    let vendors = [];
    let merchants = [];

    //filter out the viewAs entity from selected organization
    if (subscriber && subscriber.role?.toLowerCase().includes("vendor")) {
      vendors = userDetails?.attributes?.vendorIds ?
        // edit existing user flow...
        userDetails.attributes.vendorIds.map(vendor => ({ value: `v~${vendor}`, label: allVendors?.vendors?.find(entity => entity.id == vendor)?.name }))
        :
        // create new user flow... (make default selection, don't leave organization selection empty, if only ONE vendor is available)
        allVendors?.vendors?.length == 1 ?
          [{ value: `v~${allVendors?.vendors[0].id}`, label: allVendors?.vendors[0].name }]
          : [];
    } else {
      vendors = userDetails?.attributes?.vendorIds ? userDetails.attributes.vendorIds.map(vendor => (
        { value: `v~${vendor}`, label: allVendors?.vendors?.find(entity => entity.id == vendor)?.name })) : [];
    }

    if (subscriber && subscriber.role?.toLowerCase().includes("merchant")) {
      merchants = userDetails?.attributes?.merchantIds ?
        // edit existing user flow...
        userDetails.attributes.merchantIds.map(merchant => ({ value: `m~${merchant}`, label: allMerchants?.merchants?.find(entity => entity.id == merchant)?.name }))
        :
        // create new user flow... (make default selection, don't leave organization selection empty, if only ONE merchant is available)
        allMerchants?.merchants?.length == 1 ?
          [{ value: `m~${allMerchants?.merchants[0].id}`, label: allMerchants?.merchants[0].name }]
          : [];
    }
    else {
      merchants = userDetails?.attributes?.merchantIds ? userDetails.attributes.merchantIds.map(merchant => (
        { value: `m~${merchant}`, label: allMerchants?.merchants?.find(entity => entity.id == merchant)?.name })) : [];
    }

    let allFacilities = allVendors?.vendors?.map(vendors => vendors.facilities).flat();

    const facilities = userDetails?.attributes?.facilityIds ? userDetails.attributes.facilityIds.map(facility => (
      { value: `f~${facility}`, label: allFacilities?.find(entity => entity.id == facility)?.name })) : [];

    const combinedInitialEntities = [...vendors, ...merchants, ...facilities]?.filter(element => element.label).sort((a, b) => a.label?.localeCompare(b.label));
    return combinedInitialEntities;
  }

  return (
    <div className="user-details">
      {(
        <Formik
          innerRef={submitFormRef}
          enableReinitialize
          initialValues={{
            initialAttributes: userDetails?.attributes || {},
            firstName: userDetails?.firstName || '',
            lastName: userDetails?.lastName || '',
            email: userDetails?.email || '',
            password: userDetails?.password || '',
            // grantAccessToAllOrganizations: currentUser?.roles?.find(ap => ap.name == 'admin') || false,
            selectedOrganizations: mappedInitialSelectedOrganization(userDetails) || [],
            subscriber: subscriber,
            initialRole: userDetails?.roles[0]?.id || '',
            role: userDetails?.roles[0]?.id || '',
            roles: userDetails?.roles[0]?.permissionSet?.map(permission => ({
              "resourceId": permission.resourceId,
              "permissions": permission.associatedPermissions,
            })) || {},
            roleAndPermissions: userDetails?.roles[0] || allRolesWithPermissions?.filter(role => role.id == userDetails?.roles[0]?.id)[0],
          }}
          validationSchema={() =>
            object().shape({
              firstName: string().required('Enter a valid First Name'), // required
              lastName: string().required('Enter a valid Last Name'), // required
              email: string()
                .matches(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'Enter a valid Email'
                ).required('Enter a valid Email'),
              password: string().when([], () => {
                return userId ? string().notRequired() : string().required('Enter a Password');
              }),
              role: string().required('Enter a Role'), // required
              subAdminId: string().when('role', {
                is: role =>
                  ['Parent Merchant Admin', 'Merchant Admin', 'Vendor Admin'].includes(
                    role
                  ),
                then: () => string().required(
                  'Required Field'
                ),
                otherwise: () => string().notRequired(),
              }),
              selectedOrganizations: string().when('role', {
                is: role => {
                  const adminRole = allRolesWithPermissions?.find(role => role?.name?.toLowerCase() == 'admin');
                  return role != adminRole?.id;
                },
                then: () => array().min(1, 'Select at least one Organization'),
                otherwise: () => array().notRequired(),
              }),
            })
          }
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            if (userDetails) {
              // update existing user
              dispatch(updateUser({ userId: userDetails.id, values, cb: onUserUpdated }));
              if (notificationsCardRef.current) {
                notificationsCardRef.current.saveChanges();
              }
            } else {
              // create new user
              dispatch(createUser({ values, cb: onUserCreated }));
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            submitCount,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              {(userDetailsLoading || allMerchantsLoading || allVendorsLoading || allRolesWithPermissionsLoading) && <LoadingAnimation />}
              <Card className="header-card">
                <div>
                  <div className="user-name">
                    <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/users/')} />
                    <div>{displayHeader()} </div>
                  </div>
                  <div className="">
                    {userDetails && !editMode && (
                      <IconData label={userDetails.email} icon={<Envelope />} />
                    )}
                  </div>
                </div>
                <div className="action-buttons">
                  {!editMode && userDetails && canManageUsers ? (
                    <div className="d-flex">
                      <Button
                        className="mx-2"
                        variant="secondary"
                        size="medium"
                        label={'Reset Password'}
                        onClick={() => dispatch(requestForgotPassword({ email: userDetails.email, isAdmin: true }))}
                      />
                      <Button
                        variant="primary"
                        size="medium"
                        label={'Edit User'}
                        onClick={() => setEditMode(true)}
                      />
                    </div>
                  ) : canManageUsers ? (
                    <div className="d-flex">
                      {userDetails && (
                        <Button
                          className="mx-2"
                          variant="secondary"
                          size="medium"
                          label={'Cancel'}
                          onClick={() => { resetForm(); setEditMode(false); }}
                        />)}
                      <Button
                        variant="primary"
                        size="medium"
                        label={userId ? 'Save Changes' : "Create User"}
                        onClick={() => submitFormRef.current && submitFormRef.current.handleSubmit()}
                      />
                    </div>
                  ) : null}
                </div>
              </Card>

              <div>
                {(userDetailsErrorsFound) && (
                  <MessageBar color="yellow">
                    User Data failed to load.  Refresh the page to try again.
                  </MessageBar>
                )}
                {(allRolesWithPermissionsErrorsFound) && (
                  <MessageBar color="yellow">
                    Roles Data failed to load.  Refresh the page to try again.
                  </MessageBar>
                )}
                {(allVendorsErrorsFound) && (
                  <MessageBar color="yellow">
                    Vendor Data failed to load.  Refresh the page to try again.
                  </MessageBar>
                )}
                {(allMerchantsErrorsFound) && (
                  <MessageBar color="yellow">
                    Merchant Data failed to load.  Refresh the page to try again.
                  </MessageBar>
                )}
              </div>
              {(userDetails || !userId) && (
                <>
                  {!editMode && userDetails && (
                    <>
                      <Card className={`user-details-card ${editMode ? 'edit-mode' : ''}`}>
                        <Card.Header>
                          User Details
                        </Card.Header>
                        <Card.Body>
                          <div className="user-details-inputs view-mode">
                            <DataPoint title="Selected Organizations" data={mappedInitialSelectedOrganization(userDetails)?.map(item => item.label)?.join(', ')} />
                          </div>
                        </Card.Body>
                      </Card>

                      <NotificationsCard editMode={false} userId={userId} ref={notificationsCardRef} />

                      <Card className={`user-details-card account-details ${editMode ? 'edit-mode' : ''}`}>
                        <Card.Header>
                          Account Details
                        </Card.Header>
                        <Card.Body>

                          <div className="account-details-inputs">
                            {displayAccountDetails()}

                          </div>
                          <div>
                            {displayPermissions()}
                          </div>
                        </Card.Body>
                      </Card>
                    </>
                  )}

                  {editMode && (userDetails || !userId) && (
                    <Card className={`user-details-card ${editMode ? 'edit-mode' : ''}`}>
                      <Card.Header>
                        User Details
                      </Card.Header>
                      <Card.Body>
                        <div className="user-details-inputs">
                          <Input
                            label="First Name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            placeholder="First Name"
                            errorMessage={submitCount > 0 && errors.firstName}
                          />
                          <Input
                            label="Last Name"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            placeholder="Last Name"
                            errorMessage={submitCount > 0 && errors.lastName}
                          />
                          <Input
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                            errorMessage={submitCount > 0 && errors.email}
                          />
                          {!userId && (
                            <Input
                              label="Password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              placeholder="Password"
                              type={showPassword ? "text" : "password"}
                              icon={showPassword ? <EyeSlashFill /> : <EyeFill />}
                              onIconClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              errorMessage={submitCount > 0 && errors.password}
                            />
                          )}
                        </div>
                        <div className="user-details-inputs">
                          {false && // TODO - feature 'Grand access to all organizations' missing backend functionality 
                            (<div className='grant-access'>
                              <Checkbox size='small'
                                onChange={handleChange}
                                checked={true}
                                label='Grant Access to All Organizations' />
                              <Label className='grant-access-label'>Activate this option if you want to grant access to current and future organizations connected to your system</Label>
                            </div>)}
                          <MultiSelect
                            label="Select Organization"
                            name="organization"
                            searchable={true}
                            disabled={false}
                            value={values.selectedOrganizations}
                            onChange={(e) => {
                              const val = {
                                target: {
                                  name: e[0]?.name || `organizations`,
                                  id: e
                                }
                              };
                              setFieldValue('selectedOrganizations', e);
                              handleChange(val);
                            }}
                            options={entities}
                            placeholder='Select Organization'
                            errorMessage={submitCount > 0 && errors.selectedOrganizations}
                          />
                        </div>
                      </Card.Body>
                    </Card>)}

                  {editMode && <NotificationsCard editMode={true} userId={userId} ref={notificationsCardRef} />}

                  {editMode && (userDetails || !userId) && (
                    <Card className={`user-details-card account-details ${editMode ? 'edit-mode' : ''}`}>
                      <Card.Header className="account-details-header">
                        Account Details
                      </Card.Header>
                      <Card.Body>
                        <div className="account-details-inputs">
                          <Dropdown
                            label="Role"
                            name="role"
                            value={values.role}
                            onChange={(e) => {
                              let roleAndPermissions = allRolesWithPermissions?.filter(role => role.id == e.target.value.toString())[0];
                              setFieldValue('roleAndPermissions', roleAndPermissions);
                              setFieldValue('role', roleAndPermissions?.id);
                              let roles = roleAndPermissions?.permissionSet?.map(permission => ({
                                "resourceId": permission.resourceId,
                                "permissions": permission.associatedPermissions,
                              }));
                              setFieldValue('roles', roles);
                              handleChange(e);
                            }}
                            options={allRolesWithPermissions?.map(role => ({ value: role.id, label: formatUsersRolesName(role?.name) })) || []}
                            errorMessage={submitCount > 0 && errors.role}
                          />
                        </div>
                        <div className="account-permissions-header" hidden={values.role ? false : true}>
                          <div>Select Permissions</div>
                          <div>Add Permissions</div>
                        </div>
                        <div className="account-permissions-details">
                          {values.roleAndPermissions?.permissionSet?.map(permission => (
                            <div key={values.roleAndPermissions?.id + permission.resourceId}>
                              <div className="account-permissions-container">
                                <div className="account-permissions-title">{formatUsersResourceName(permission.resourceName)} </div>
                                <div className="account-permissions-checkbox">
                                  <Checkbox size='medium' checked={permission.associatedPermissions.length == permission.availablePermissions.length}
                                    onChange={(e) => {
                                      if (e) {
                                        permission.associatedPermissions = permission.availablePermissions;
                                      } else {
                                        permission.associatedPermissions = [];
                                      }
                                      let roles = values.roleAndPermissions?.permissionSet?.map(permission => ({
                                        "resourceId": permission.resourceId,
                                        "permissions": permission.associatedPermissions,
                                      }));
                                      setFieldValue('roles', roles);
                                    }} />
                                </div>
                              </div>
                              <div className="account-permissions">
                                {permission?.availablePermissions?.map(available =>
                                (
                                  <div key={values.roleAndPermissions?.id + permission.resourceId + available.name} className="account-permissions-line" >
                                    <div className="account-permissions-title">{available.description?.length ? available.description : formatUsersPermissionsName(available.name)}</div>
                                    <div className="account-permissions-checkbox">
                                      <Checkbox size='medium' checked={permission.associatedPermissions?.map(ap => ap.name).includes(available.name)} onChange={() => {
                                        if (permission.associatedPermissions?.map(ap => ap.name).includes(available.name)) {
                                          permission.associatedPermissions = permission.associatedPermissions.filter(q => q.name !== available.name);
                                        } else {
                                          permission.associatedPermissions.push(available);
                                        }
                                        let roles = values.roleAndPermissions?.permissionSet?.map(permission => ({
                                          "resourceId": permission.resourceId,
                                          "permissions": permission.associatedPermissions,
                                        }));
                                        setFieldValue('roles', roles);
                                      }} />
                                    </div>
                                  </div>
                                )
                                )}</div>
                            </div>))}
                        </div>
                      </Card.Body>
                    </Card>)}
                </>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default UserDetails;