import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as BSPagination } from 'react-bootstrap';
import { ButtonMenu } from 'app/components';
import { useSearchParams } from 'react-router-dom';
import './index.scss';

export const Pagination = ({
  totalItems,
  pageSizes = [25, 50, 100],  // Default page sizes
  between = 3,                // Number of pages to show between ellipses
  next = true,                // Show next button
  last = true,                // Show last button
  ellipsis = 0,               // Number of ellipsis points
  hideBelow = 25,             // Hide pagination if totalItems is below this number
  currentPage,                // Optional current page controlled by parent
  pageSize,                   // Optional page size controlled by parent
  onChange,                   // Optional change handler
  ...paginationProps          // Additional pagination properties
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  // determine the current page and page size from props or search parameters
  let _currentPage = currentPage || parseInt(searchParams.get('page') || '1', 10);
  let _pageSize = pageSize || parseInt(searchParams.get('pageSize') || pageSizes[1], 10);

  const totalPages = Math.ceil(totalItems / _pageSize);

  // ensure between and ellipsis values are within valid ranges
  between = Math.max(1, between);
  _currentPage = Math.min(Math.max(_currentPage, 1), totalPages);
  ellipsis = Math.max(0, Math.min(ellipsis, between - 2));

  // create an array for page positions
  const positions = Array.from({ length: totalPages }, (_, i) => i);
  const qtd_pages = between * 2 + 1;

  // determine the range of pages to display
  const range = totalPages <= qtd_pages
    ? positions
    : _currentPage - 1 <= between
      ? positions.slice(0, qtd_pages - (ellipsis > 0 ? ellipsis + 1 : 0))
      : _currentPage + between >= totalPages
        ? positions.slice(totalPages - qtd_pages + (ellipsis > 0 ? ellipsis + 1 : 0), totalPages)
        : positions.slice(_currentPage - 1 - (between - (ellipsis > 0 ? ellipsis + 1 : 0)), _currentPage + (between - (ellipsis > 0 ? ellipsis + 1 : 0)));

  // function to generate a message showing the range of currently displayed items
  const getShowingToFromMessage = () => {
    const from = (_currentPage - 1) * _pageSize + 1;
    const to = Math.min(_currentPage * _pageSize, totalItems);
    return `Showing ${from} - ${to} of ${totalItems}`;
  };

  // update pagination information based on the selected page and page size
  const updatePaginationInfo = (page, _pageSize) => {
    if (onChange) {
      onChange(page, _pageSize); // Call the parent's onChange handler if provided
    } else {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('page', page);
      updatedSearchParams.set('pageSize', _pageSize);
      setSearchParams(updatedSearchParams.toString()); // Update the URL search parameters
    }
  };

  // if there are less than X items, don't bother showing the pagination at all
  if (totalItems < hideBelow) return null;

  return (
    <>
      <div className="gooten-pagination">
        {totalItems > 0 && (
          <>
            <ButtonMenu
              label={`Show ${_pageSize}`}
              variant="secondary"
              direction="up"
              size="small"
              options={pageSizes.map((val) => ({
                value: val,
                label: val,
                onClick: () => updatePaginationInfo(1, val), // reset to page 1 when changing page size
              }))}
              width={140}
            />
            <div className="showing-count">{getShowingToFromMessage()}</div>

            {totalItems && totalItems > 0 && (
              <BSPagination {...paginationProps}>
                {
                  last && (
                    <BSPagination.First
                      className="pagination-arrow"
                      onClick={() => _currentPage > 1 && updatePaginationInfo(1, _pageSize)}
                      disabled={_currentPage <= 1} // disable if on the first page
                    />
                  )
                }
                {
                  next && (
                    <BSPagination.Prev
                      className="pagination-arrow"
                      onClick={() => _currentPage > 1 && updatePaginationInfo(_currentPage - 1, _pageSize)}
                      disabled={_currentPage <= 1} // disable if on the first page
                    />
                  )
                }
                {
                  // display ellipsis if there are more pages than can be shown
                  totalPages > qtd_pages && ellipsis > 0 && _currentPage - 1 > between && (
                    <BSPagination.Ellipsis disabled />
                  )
                }
                {range.map((value) => (
                  <BSPagination.Item
                    active={value === _currentPage - 1}
                    key={value}
                    onClick={() => value !== _currentPage - 1 && updatePaginationInfo(value + 1, _pageSize)}
                  >
                    {value + 1}
                  </BSPagination.Item>
                ))}
                {
                  // show ellipsis when the page is lower than between
                  totalPages > qtd_pages && ellipsis > 0 && _currentPage < totalPages - between && (
                    <BSPagination.Ellipsis disabled />
                  )
                }
                {
                  next && (
                    <BSPagination.Next
                      onClick={() => _currentPage < totalPages && updatePaginationInfo(_currentPage + 1, _pageSize)}
                      disabled={_currentPage >= totalPages} // disable if on the last page
                      className="pagination-arrow"
                    />
                  )
                }
                {
                  last && (
                    <BSPagination.Last
                      onClick={() => _currentPage < totalPages && updatePaginationInfo(totalPages, _pageSize)}
                      disabled={_currentPage >= totalPages} // disable if on the last page
                      className="pagination-arrow"
                    />
                  )
                }
              </BSPagination>
            )}
          </>
        )}
      </div>
    </>
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  between: PropTypes.number,
  next: PropTypes.bool,
  last: PropTypes.bool,
  ellipsis: PropTypes.number,
  hideBelow: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
