import React from 'react';
import { useSelector } from 'react-redux';
import { sidebarSelector } from 'app/store/selectors/config';
import './index.scss';

const MainContent = props => {
  const sidebar = useSelector(sidebarSelector);

  return (
    <div className={`main-content ${sidebar.open ? 'sidebar-visible' : ''}`}>
      { props.children }
    </div>
  )
}

export default MainContent;