import { createAction } from 'redux-actions';

const prefix = "NOTE";

export const createNote = createAction(`${prefix}/CREATE_NOTE`);
export const createNoteSuccess = createAction(`${prefix}/CREATE_NOTE_SUCCESS`);
export const createNoteFailure = createAction(`${prefix}/CREATE_NOTE_FAILURE`);

export const getNotes = createAction(`${prefix}/GET_NOTES`);
export const getNotesSuccess = createAction(`${prefix}/GET_NOTES_SUCCESS`);
export const getNotesFailure = createAction(`${prefix}/GET_NOTES_FAILURE`);

export const deleteNote = createAction(`${prefix}/DELETE_NOTE`);
export const deleteNoteSuccess = createAction(`${prefix}/DELETE_NOTE_SUCCESS`);
export const deleteNoteFailure = createAction(`${prefix}/DELETE_NOTE_FAILURE`);

export const toggleEditNote = createAction(`${prefix}/TOGGLE_EDIT_NOTE`);
export const editNoteSuccess = createAction(`${prefix}/EDIT_NOTE_SUCCESS`);
export const editNoteCancel = createAction(`${prefix}/EDIT_NOTE_CANCEL`);


export const updateNote = createAction(`${prefix}/UPDATE_NOTE`);
export const updateNoteSuccess = createAction(`${prefix}/UPDATE_SUCCESS`);
export const updateNoteFailure = createAction(`${prefix}/UPDATE_CANCEL`);

export const reset = createAction(`${prefix}/RESET`);
