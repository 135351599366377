import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { 
  vendorDetailsSelector,
  vendorProductDataSelector,
  vendorProductDataLoadingSelector,
  vendorProductDataErrorsSelector,
  allVendorProductDataLoadingSelector,
  addUpdateProductsLoadingSelector,
  addUpdateProductsErrorsSelector
} from 'app/store/selectors/vendor';
import { getProductsByVendor, getAllProductsByVendor, updateVendorProducts } from 'app/store/actions/vendor';
import { Button, ButtonIcon, Card, Table, TableHeader, Pagination, LoadingAnimation, StatusChip, SearchBar, MessageBar } from 'app/components';
import { ThreeDotsVertical, ExclamationCircle } from 'react-bootstrap-icons';
import { convertJsonDataToApiFormat, downloadAllProductsXLSX, parseAxiosError }  from '../../../utils';
import ChangeStatusModal from '../../../Modals/ChangeStatusModal';
import { usePermission } from 'app/permissions';
import { formatCurrency } from 'app/utils';
import * as XLSX from 'xlsx';
import './index.scss';

const ProductsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [failedProductUpdates, setFailedProductUpdates] = useState(null);
  const [originalJsonData, setOriginalJsonData] = useState(null);
  const [editStatus, setEditStatus] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { vendorId } = useParams();
  const loading = useSelector(vendorProductDataLoadingSelector);
  const productData = useSelector(vendorProductDataSelector);
  const errorsFound = useSelector(vendorProductDataErrorsSelector);
  const vendorDetails = useSelector(vendorDetailsSelector);
  const allProductsLoading = useSelector(allVendorProductDataLoadingSelector);
  const addUpdateProductsLoading = useSelector(addUpdateProductsLoadingSelector);
  const addUpdateProductsErrors = useSelector(addUpdateProductsErrorsSelector);

  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '25', 10);
  const sortBy = searchParams.get('sortBy') || 'sku';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const searchString = searchParams.get('searchString') || null;

  // get user permissions
  const canManageVendor = usePermission('vendor', 'create');

  useEffect(() => {
    dispatch(getProductsByVendor({vendorId, searchString, sortBy, sortDir, currentPage, pageSize}));
  }, [vendorId, searchString, sortBy, sortDir, currentPage, pageSize, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  }; 

  const productOptions = [
    ...(canManageVendor ? [{ value: 'Manage Inventory', label: 'Manage Inventory', onClick: () => { navigate(`/admin/inventory`); }}] : []),
    ...(canManageVendor ? [{ value: 'Add Products', label: 'Add Products', onClick: () => { navigate(`add-products`); }}] : []),
    ...(canManageVendor ? [{ value: 'Update Products', label: 'Update Products', onClick: () => { onUpdateProductsButtonPressed(); }}] : []),
  ];

  const facilityNameLookup = (facilityId) => vendorDetails?.facilities?.find(facility => facility.id === facilityId)?.name || null;
  
  const products = [];

  productData?.vendorProducts?.forEach(product => {
      const variantId = product.variantId;
      const orderMeshSku = product.sku;
      product.facilities.forEach(facility => {
          const newObject = {
            id: product.id,
            facilityId: facility.id,
            facilityName: facilityNameLookup(facility.id),
            decorations: facility.decorations,
            vendorSku: facility.vendorSku,
            vendorSkuDescription: facility.vendorSkuDescription,
            variantId: variantId,
            orderMeshSku: orderMeshSku,
            productCost: facility.productCost,
            productionTimeDays: facility.productionTimeDays,
            status: facility.status
          };
          products.push(newObject);
      });
  });

  const onUpdateProductsButtonPressed = () => {
    setFailedProductUpdates(null);

    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = '.xls,.xlsx';
    inputElement.onchange = handleFileUpload;
    inputElement.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetNames = workbook.SheetNames;
      const jsonData = {};

      sheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        jsonData[sheetName] = sheetData;
      });

      const apiFormat = convertJsonDataToApiFormat(jsonData, vendorDetails);

      // store the original JSON data
      setOriginalJsonData(apiFormat);

      dispatch(updateVendorProducts({ data: convertJsonDataToApiFormat(jsonData, vendorDetails), searchParams: {vendorId, searchString, currentPage, pageSize}, cb: onProductsSuccessfullyUpdated}));
    };

    reader.readAsArrayBuffer(file);
  };

  const onProductsSuccessfullyUpdated = (resp) => {
    let successCount = 0;
    let failureCount = 0;

    if (resp.bulk && Array.isArray(resp.bulk)) {
      resp.bulk.forEach(item => {
        if (item.status === 200) {
          successCount++;
        } else {
          failureCount++;
        }
      });
    }

    const data = {
      successCount,
      failureCount,
      total: resp.bulk.length,
      resp,
    };

    if (failureCount > 0) {
      setFailedProductUpdates(data);
    }
  }

  return (
    <div className="products-view">
      {failedProductUpdates && (
        <MessageBar
          className="upload-products-error"
          color="red"
          onClick={() => {
            // make a copy of the original JSON data
            const updatedFailedProducts = JSON.parse(JSON.stringify(originalJsonData));

            for (let i = 0; i < failedProductUpdates.resp.bulk.length; i++) {
              const item = failedProductUpdates.resp.bulk[i];
              if (item.status !== 200) {
                updatedFailedProducts.bulk[i].failedReason = item.response.errors[0].errorMessage;
              } else{
                updatedFailedProducts.bulk[i] = null;
              }
            }

            // now remove all the null entries
            updatedFailedProducts.bulk = updatedFailedProducts.bulk.filter(item => item !== null);

            downloadAllProductsXLSX(updatedFailedProducts.bulk);
          }}
        >
          <div>{`${failedProductUpdates.total - failedProductUpdates.failureCount} / ${failedProductUpdates.total} Products Successfully Updated`}</div>
          <div className="additional-message">Click to Download Failed Products</div>
        </MessageBar>
      )}
      {addUpdateProductsErrors && <MessageBar color="red" className="upload-products-error">{parseAxiosError(addUpdateProductsErrors)}</MessageBar>}
      <Card>
        {(loading || allProductsLoading || addUpdateProductsLoading) && <LoadingAnimation />}
        <Card.Header hideDivider>
          Vendor Products
          {productData && !errorsFound && (
            <div className="product-options">
              <Button
                variant="primary"
                size="small"
                label="Export to XLSX"
                onClick={() => dispatch(getAllProductsByVendor({vendorId, searchString, cb: (data) => downloadAllProductsXLSX(data)}))}
              />
              {productOptions.length > 0 && (
                <ButtonIcon
                  icon={<ThreeDotsVertical />}
                  options={productOptions}
                />
              )}
            </div>
          )}
        </Card.Header>
        {!errorsFound && (
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for a Product"
              onSearchStringUpdated={onSearchStringUpdated}
              disabled={errorsFound}
              debounceDelay={500}
            />
            {/* <VendorProductsFilterMenu /> */}
          </div>
        )}
        <Table size="medium">
          <TableHeader
            sortBy={sortBy}
            sortDir={sortDir}
            options={[
              { id: "facility", label: "Vendor Facility" },
              { id: "vendorSku", label: "Vendor SKU", orderable: true },
              { id: "variantId", label: "Variant ID", orderable: true },
              { id: "sku", label: "SKU", orderable: true },
              { id: "prodTime", label: "Production Time" },
              { id: "cost", label: "Cost" },
              { id: "status", label: "Status", align: 'center' },
              { id: "actions", label: "Actions", align: 'center' },
            ]}
          />
          <tbody className="table-body">
            {products?.map((product, index) => (
              <tr className="products-row" key={`${product.id}-${product.vendorSku}-${product.variantId}-${index}`}>
                <td>{product.facilityName || product.facilityId}</td>
                <td>{product.vendorSku}</td>
                <td>{product.variantId}</td>
                <td>{product.orderMeshSku}</td>
                <td>{product.productionTimeDays !== null && product.productionTimeDays !== undefined ? `${product.productionTimeDays} Days` : ''}</td>
                <td>{formatCurrency(product.productCost.currency, product.productCost.amount)}</td>
                <td className="product-status-chip"><StatusChip status={product.status || 'Not Set'} type="product" /></td>
                <td className="action-icons">
                  <ButtonIcon icon={<ThreeDotsVertical />} options={[
                      { value: 'Update Status', label: 'Update Status', onClick: () => setEditStatus(product) },
                    ]} 
                  />
                </td>
              </tr>
            ))}
            {products?.length === 0 && !loading && !errorsFound && (
              <tr>
                <td colSpan="8" className="no-results">{searchString ? 'No Matching Results Found' : 'No Products Found'}</td>
              </tr>
            )}
            {errorsFound && !loading && (
              <tr>
                <td colSpan="8" className="error-message"><ExclamationCircle />Error fetching vendor data</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination totalItems={productData?.total || 0} />
      </Card>
      {editStatus && (
        <ChangeStatusModal
          product={editStatus}
          searchParams={{vendorId, searchString, currentPage, pageSize}}
          onClose={() => setEditStatus(null)}
        />
      )}
    </div>
  )
}

export default ProductsList;