import { createSelector } from 'reselect';

const shipmentSelector = state => state.shipment;

export const shipmentsSelector = createSelector([shipmentSelector], shipmentStore => shipmentStore.shipments.data);
export const shipmentsLoadingSelector = createSelector([shipmentSelector], shipmentStore => shipmentStore.shipments.loading);
export const shipmentsErrorsSelector = createSelector([shipmentSelector], shipmentStore => shipmentStore.shipments.error);

export const auditInfoSelector = createSelector([shipmentSelector], shipmentStore => shipmentStore.auditInfo.data);
export const auditInfoLoadingSelector = createSelector([shipmentSelector], shipmentStore => shipmentStore.auditInfo.loading);
export const auditInfoErrorsSelector = createSelector([shipmentSelector], shipmentStore => shipmentStore.auditInfo.error);