import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentUserSelector } from "app/store/selectors/user";
import { Button, Card, ButtonIcon, IconData } from "app/components";
import { ArrowLeft, Envelope } from "react-bootstrap-icons";
import NotificationsCard from "../NotificationsCard";

import "./index.scss";

const UserProfile = () => {
  const notificationsCardRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const currentUser = useSelector(currentUserSelector);
  const navigate = useNavigate();

  const saveChanges = () => {
    if (notificationsCardRef.current) {
      notificationsCardRef.current.saveChanges();
    }
    setEditMode(false);
  };

  const discardChanges = () => {
    if (notificationsCardRef.current) {
      notificationsCardRef.current.discardChanges();
    }
  };

  return (
    <div className="user-profile">
      {/* header */}
      <Card className="header-card">
        <div>
          <div className="user-name">
            <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(-1)} />
            <div>
              {!editMode
                ? `${currentUser?.firstName} ${currentUser?.lastName}`
                : "Edit user"}
            </div>
          </div>
          {!editMode && (
            <IconData label={currentUser.email} icon={<Envelope />} />
          )}
        </div>
        <div className="action-buttons">
          {!editMode ? (
            <div className="d-flex">
              <Button
                variant="primary"
                size="medium"
                label="Edit User"
                onClick={() => setEditMode(true)}
              />
            </div>
          ) : (
            <div className="d-flex">
              <Button
                className="mx-2"
                variant="secondary"
                size="medium"
                label="Cancel"
                onClick={() => {
                  discardChanges();
                  setEditMode(false);
                }}
              />
              <Button
                variant="primary"
                size="medium"
                label="Save Changes"
                onClick={() => saveChanges()}
              />
            </div>
          )}
        </div>
      </Card>

      {/* notifications card */}
      <NotificationsCard
        userId={currentUser.id}
        editMode={editMode}
        ref={notificationsCardRef}
      />
    </div>
  );
};

export default UserProfile;
