import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toggleSidebar } from "app/store/actions/config";
import { setViewAs } from "app/store/actions/user";
import { sidebarSelector } from "app/store/selectors/config";
import { currentUserSelector, viewAsSelector } from "app/store/selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { OrderSearchBar, Button, ButtonMenu } from "app/components";
import { ReactComponent as Logo } from "app/assets/images/logos/main-logo.svg";
import { ReactComponent as HamburgerIconCollapse } from "app/assets/images/icons/hamburger-collapse.svg";
import { ReactComponent as HamburgerIconExpand } from "app/assets/images/icons/hamburger-expand.svg";
import { ReactComponent as ProfileIcon } from "app/assets/images/icons/profile-icon.svg";
// import { ChevronDown } from 'react-bootstrap-icons';
import "./index.scss";
import { ChevronDown } from "react-bootstrap-icons";

const Topbar = ({ showOrderSearchBar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebar = useSelector(sidebarSelector);
  const currentUser = useSelector(currentUserSelector);
  const viewAs = useSelector(viewAsSelector);
  const [showViewAs, setShowViewAs] = useState(false);
  const [prevViewAsName, setPrevViewAsName] = useState(null);

  useEffect(() => {
    if (viewAs) {
      setShowViewAs(true);
      setPrevViewAsName(viewAs.name);
    } else {
      handleCloseViewAs();
    }
  }, [viewAs]);

  const handleCloseViewAs = () => {
    const viewAsContainer = document.querySelector(".view-as-container");
    if (viewAsContainer) {
      viewAsContainer.classList.add("slide-out");
      setTimeout(() => {
        dispatch(setViewAs({ data: null }));
        setShowViewAs(false);
      }, 500);
    }
  };

  return (
    <div className="topbar">
      {showViewAs && (
        <div
          className={`view-as-container ${sidebar.open ? "" : "sidebar-closed"
            }`}
          onClick={handleCloseViewAs}
        >
          <div className="top-line" />
          <div className="view-as-message">
            <span className="message-text">{`Viewing as ${viewAs?.name || prevViewAsName
              }`}</span>
            <Button
              className="close-button"
              size="small"
              label="Cancel"
              onClick={() => { }}
            />
          </div>
        </div>
      )}
      <div className="main-bar">
        <div className="logo-container">
          {sidebar.open ? (
            <HamburgerIconCollapse
              onClick={() => dispatch(toggleSidebar())}
              className="hamburber-icon-collapse"
            />
          ) : (
            <HamburgerIconExpand
              onClick={() => dispatch(toggleSidebar())}
              className="hamburber-icon-expand"
            />
          )}
          <div className="order-mesh-logo">
            <Logo onClick={() => navigate("/")} />
          </div>
        </div>
        {showOrderSearchBar && <OrderSearchBar />}

        <ButtonMenu
          labelHTML={
            <div className="profile-container">
              <div className="profile-name">{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
              <div className="profile-avatar">
                <ProfileIcon />
              </div>
              <div className="profile-caret">
                <ChevronDown />
              </div>
            </div>
          }
          align="end"
          variant="primary"
          size="medium"
          options={[
            {
              value: "userprofile",
              label: "User Profile",
              onClick: () => navigate("/admin/users/profile"),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Topbar;
