import HistoryView from "./HistoryView";
import NotesView from "./NotesView";
import { useSelector } from 'react-redux';
import { orderDetailsSelector } from 'app/store/selectors/order';
import PropTypes from 'prop-types';
import "./index.scss";

const HistoryAndNotesView = props => {
  const orderDetails = useSelector(orderDetailsSelector);

  return (
    <div className="history-and-notes-view">
      <div className="history-and-notes-cards">
        <HistoryView  {...props} />
        <NotesView entityType="Order" entityId={props?.orderId} merchantId={orderDetails?.merchantId} vendorId={null} />
      </div>
    </div>
  );
};

HistoryAndNotesView.propTypes = {
  orderId: PropTypes.string.isRequired
};
export default HistoryAndNotesView;
