import axios from "axios";
import Config from "../config";
import SetupInterceptors from "./axiosInterceptors";

class InventoryServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.InventoryMS.Url,
    });

    SetupInterceptors(this.api);
  }

  bulkUpload = async (data) => {
    try {
      const response = await this.api.post(`/v1/vendor/bulk/upload`, data);
      return response.data;
    } catch (error) {
      throw new Error("Error while making the API call. Please try again.");
    }
  };

  searchInventory = async (page, pageSize, vendorSkuLocationList) => {
    try {
      const data = {
        page: page,
        pageSize: pageSize,
        search:{
          vendorSkuLocationList
        }
      };
      const response = await this.api.post(`/v1/search`, data);
      return response.data;
    } catch (error) {
      throw new Error("Error while making the API call. Please try again.");
    }
  };
}

export default new InventoryServices();
