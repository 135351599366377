import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonIcon, Card, IconData, TabbedMenu } from 'app/components';
import { merchantDetailsSelector, merchantDetailsErrorsSelector, configurationSelector, merchantTemplatesSelector } from 'app/store/selectors/merchant';
import DetailsView from './DetailsView';
import SettingsView from './SettingsView';
import { ArrowLeft, Upc } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import TemplatesView from './TemplatesView';
import { getConfiguration, getTemplates } from 'app/store/actions/merchant';
import './index.scss';

const detailsTab = {
  path: 'details',
  label: 'Details',
  content: DetailsView,
}

const settingsTab = {
  path: 'settings',
  label: 'Settings',
  content: SettingsView,
}

const templatesTab = {
  path: 'templates',
  label: 'Templates',
  content: TemplatesView,
}

const MerchantDetails = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const merchantDetails = useSelector(merchantDetailsSelector);
  const merchantDetailsErrorsFound = useSelector(merchantDetailsErrorsSelector);
  const configuration = useSelector(configurationSelector)
  const templates = useSelector(merchantTemplatesSelector)

  const displayHeader = () => {
    if (merchantDetailsErrorsFound) {
      return 'Merchant Details';
    }
    if (merchantId) {
      if (merchantDetails && merchantDetails.name) {
        return `${merchantDetails.name}`;
      } else {
        return (
          <span>&nbsp;</span>
        );
      }
    } else {
      return 'Create New Merchant';
    }
  }

  useEffect(() => {
    if (merchantId) {
      dispatch(getConfiguration({ merchantId }));
      dispatch(getTemplates(merchantId));
    }
  }, [merchantId]);

  const tabs = () => {
    let tabs = [detailsTab];

    if (configuration?.data) {
      tabs.push(settingsTab);
    }

    if (templates && Object.keys(templates).length > 0) {
      tabs.push(templatesTab);
    }

    return tabs;
  }

  return (
    <div className="merchant-details">
      <Card className="header-card">
        <div className="merchant-name">
          <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/merchants')} />
          {displayHeader()}
        </div>
        {merchantDetails && (
          <div className="merchant-ids">
            <IconData label={merchantDetails.id} icon={<Upc />} />
            <div className="short-id">({merchantDetails.shortId})</div>
          </div>
        )}
      </Card>
      <TabbedMenu tabs={tabs()} showTabs={Boolean(merchantDetails)} merchantId={merchantId} />
    </div>
  )
}

export default MerchantDetails;