import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, MessageBar } from 'app/components';
import { useSelector } from 'react-redux';
import { getIntegrationCustomizations, getEventTypes } from 'app/store/actions/integration';
import {
  integrationDetailsSelector,
  integrationDetailsLoadingSelector,
  eventTypesSelector,
  eventTypesLoadingSelector,
  eventTypesErrorsSelector
} from 'app/store/selectors/integration';
import ActionsAndTrigger from './ActionsAndTrigger';
import './index.scss';

const ActionsAndTriggers = (props) => {
  const { pluginId, pluginVersionId, currentVersion, editMode } = props;
  const dispatch = useDispatch();
  const [newActionsAndTriggers, setNewActionsAndTriggers] = useState([]);
  const [editModeId, setEditModeId] = useState(null);

  const integrationDetails = useSelector(integrationDetailsSelector);
  const integrationDetailsLoading = useSelector(integrationDetailsLoadingSelector);
  const eventTypes = useSelector(eventTypesSelector);
  const eventTypesLoading = useSelector(eventTypesLoadingSelector);
  const eventTypesErrors = useSelector(eventTypesErrorsSelector);

  useEffect(() => {
    // if a transformation id was passed in, then we are viewing an existing transformation
    dispatch(getIntegrationCustomizations({ integrationDetails }));
    if (!eventTypes) {
      dispatch(getEventTypes());
    }
  }, [editMode]);

  return (
    <Card className="actions-and-triggers">
      <Card.Header hideDivider>
        Actions and Triggers
        {!integrationDetailsLoading && !eventTypesLoading && editMode && !editModeId && (
          <Button
            variant="primary"
            size="small"
            label="Add Action"
            onClick={() => {
              setNewActionsAndTriggers([...newActionsAndTriggers, { eventId: -1 }])
              setEditModeId(-1);
            }}
          />
        )}
      </Card.Header>
      {integrationDetails && eventTypes && !eventTypesErrors && (
        <div className="actions-and-triggers-container">
          {newActionsAndTriggers.map((event, index) => (
            <ActionsAndTrigger
              key={index}
              details={event}
              eventTypes={eventTypes}
              integrationDetails={integrationDetails}
              editMode={editMode}
              pluginId={pluginId}
              pluginVersionId={pluginVersionId}
              currentVersion={currentVersion}
              setEditModeId={setEditModeId}
              editModeId={editModeId}
              setNewActionsAndTriggers={setNewActionsAndTriggers}
            />
          ))}
          {integrationDetails.details?.events?.map((event, index) => (
            <ActionsAndTrigger
              key={index}
              details={event}
              eventTypes={eventTypes}
              integrationDetails={integrationDetails}
              editMode={editMode}
              pluginId={pluginId}
              pluginVersionId={pluginVersionId}
              currentVersion={currentVersion}
              setEditModeId={setEditModeId}
              editModeId={editModeId}
            />
          ))}
          {(!integrationDetails.details?.events || integrationDetails.details.events.length === 0) && newActionsAndTriggers.length === 0 && (
            <div className="no-data">No Actions Added</div>
          )}
        </div>
      )}
      {eventTypesErrors && (
        <MessageBar color="yellow">
          An error occurred while fetching data
        </MessageBar>
      )}
    </Card>
  )
}

export default ActionsAndTriggers;