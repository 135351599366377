import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ButtonMenu } from 'app/components';
import { deleteRoutingStrategy } from 'app/store/actions/routing';
import { useDispatch } from 'react-redux';
import { usePermission } from 'app/permissions';
import './index.scss';

const AdditionalStrategyOptions = (props) => {
  const { strategyId, isActive, onEditStrategy, onRoutingStrategyDeleted } = props;
  const [deleteStrategyModal, showDeleteStrategyModal] = useState(false);
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  // Get user permissions
  const canManageStrategies = usePermission('routing', 'create');

  // define the options array 
  const menuOptions = [
    ...(canManageStrategies && !isActive ? [{ value: 'Activate Strategy', label: 'Activate Strategy', onClick: () => console.log('Activate Strategy') }] : []),
    ...(canManageStrategies ? [{ value: 'Edit Strategy', label: 'Edit Strategy', onClick: onEditStrategy }] : []),
    ...(canManageStrategies ? [{ value: 'Delete Strategy', label: 'Delete Strategy', destructive: true, onClick: () => showDeleteStrategyModal(true) }] : [])
  ];

  return (
    <div className="additional-strategy-options">
      {menuOptions.length > 0 && (
        <ButtonMenu
          label="Strategy Options"
          variant="primary"
          size="small"
          options={menuOptions}
          width={168}
        />
      )}
      {deleteStrategyModal && (
        <Modal
          secondaryButtonLabel="No, Keep It"
          secondaryButtonOnClick={() => showDeleteStrategyModal(false)}
          primaryButtonLabel="Yes, Delete It"
          primaryButtonVariant="primary"
          primaryButtonDestructive
          primaryButtonOnClick={() => {
            dispatch(deleteRoutingStrategy({ merchantId, strategyId, cb: onRoutingStrategyDeleted }));
            showDeleteStrategyModal(false);
          }}
          onClose={() => showDeleteStrategyModal(false)}
        >
          <div>Are you sure you want to delete this strategy?</div>
          <div>This action is not reversible.</div>
        </Modal>
      )}
    </div>
  );
};

export default AdditionalStrategyOptions;
