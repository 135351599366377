import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { vendorProductDataSelector, vendorProductDataLoadingSelector, vendorProductDataErrorsSelector } from 'app/store/selectors/vendor';
import { getProductsByVendor } from 'app/store/actions/vendor';
import { ButtonIcon, SearchBar, StatusChip, Card, Table, TableHeader, LoadingAnimation } from 'app/components';
import { ExclamationCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import ChangeStatusModal from '../../Modals/ChangeStatusModal';
import { formatCurrency } from 'app/utils';
import './index.scss';

const FacilitiesView = props => {
  const { vendorId, facilityId } = props;

  const dispatch = useDispatch();
  const [editStatus, setEditStatus] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const loading = useSelector(vendorProductDataLoadingSelector);
  const productData = useSelector(vendorProductDataSelector);
  const errorsFound = useSelector(vendorProductDataErrorsSelector);

  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '25', 10);
  const searchString = searchParams.get('searchString') || null;

  useEffect(() => {
    dispatch(getProductsByVendor({ vendorId, facilityId, searchString, currentPage, pageSize }));
  }, [vendorId, searchString, currentPage, pageSize, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  }; 

  // const productOptions = () => [
  //   // { value: 'Manage Inventory', label: 'Manage Inventory', onClick: () => { console.log("Not implemented yet") }},
  //   { value: 'Add Products', label: 'Add Products', onClick: () => { console.log("Not implemented yet") } },
  //   { value: 'Update Products', label: 'Update Products', onClick: () => console.log("Not implemented yet") },
  // ];

  const products = [];

  productData?.vendorProducts?.forEach(product => {
    const variantId = product.variantId;
    const orderMeshSku = product.sku;
    product.facilities.forEach(facility => {
      if (facility.id !== facilityId) return;
      const newObject = {
        id: product.id,
        facilityId: facility.id,
        decorations: facility.decorations,
        vendorSku: facility.vendorSku,
        vendorSkuDescription: facility.vendorSkuDescription,
        variantId: variantId,
        orderMeshSku: orderMeshSku,
        productCost: facility.productCost,
        productionTimeDays: facility.productionTimeDays,
        status: facility.status
      };
      products.push(newObject);
    });
  });

  return (
    <div className="facility-products-view">
      {loading && <LoadingAnimation />}
      {(productData || errorsFound) && (
        <Card className="vendor-products-card">
          <Card.Header hideDivider>
            Facility Products
            {/* {productData && !errorsFound && (
              <div className="product-options">
                <Button
                  variant="primary"
                  size="small"
                  label="Export to XLSX"
                  onClick={() => console.log('Create Vendor')}
                />
                <ButtonIcon
                  icon={<ThreeDotsVertical />}
                  options={productOptions()}
                />
              </div>
            )} */}
          </Card.Header>
          {!errorsFound && (
            <div className="search-bar-and-filters">
              <SearchBar
                searchPlaceholderText="Search for a Product"
                onSearchStringUpdated={onSearchStringUpdated}
                debounceDelay={500}
              />
            </div>
          )}
          <Card.Body>
            <Table size="medium">
              <TableHeader
                options={[
                  { id: "vendorSku", label: "Vendor SKU" },
                  { id: "variantId", label: "Variant ID" },
                  { id: "orderMeshSku", label: "OrderMesh SKU" },
                  { id: "prodTime", label: "Production Time" },
                  { id: "cost", label: "Cost" },
                  { id: "status", label: "Status", align: 'center' },
                  { id: "actions", label: "Actions", align: 'center' },
                ]}
              />
              <tbody className="table-body">
                {products?.map((product) => (
                  <tr className="products-row" key={`${product.id}-${product.vendorSKU}-${product.variantId}`}>
                    <td>{product.vendorSku}</td>
                    <td>{product.variantId}</td>
                    <td>{product.orderMeshSku}</td>
                    <td>{product.productionTimeDays ? `${product.productionTimeDays} Days` : ''}</td>
                    <td>{formatCurrency(product.productCost.currency, product.productCost.amount)}</td>
                    <td className="product-status-chip"><StatusChip status={product.status || 'Not Set'} type="product" /></td>
                    <td className="action-icons">
                      <ButtonIcon icon={<ThreeDotsVertical />} options={[
                          { value: 'Update Status', label: 'Update Status', onClick: () => setEditStatus(product) },
                        ]} 
                      />
                    </td>
                  </tr>
                ))}
                {!errorsFound && products?.length === 0 && (
                  <tr>
                    <td colSpan="7" className="no-product-data">{searchString ? 'No Matching Results Found' : 'No Products Found'}</td>
                  </tr>
                )}
                {errorsFound && (
                  <tr>
                    <td colSpan="7" className="no-product-data"><ExclamationCircle />Error fetching facility products</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
      {editStatus && (
        <ChangeStatusModal
          product={editStatus}
          searchParams={{vendorId, searchString, currentPage, pageSize}}
          onClose={() => setEditStatus(null)}
        />
      )}
    </div>
  )
}

export default FacilitiesView;