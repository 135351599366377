import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import { latestHistoryDataSelector, latestHistoryErrorsSelector } from 'app/store/selectors/history';
import { Card, ButtonIcon, Link } from 'app/components';
import { ChevronDown, ChevronLeft, SignTurnSlightRight, Truck } from 'react-bootstrap-icons';
import moment from 'moment';
import './index.scss';

const HistoryCard = () => {
  const historyData = useSelector(latestHistoryDataSelector);
  const historyDataError = useSelector(latestHistoryErrorsSelector);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const displayIcon = (eventType) => {
    switch (eventType) {
      case 'Create':
        return <Truck />;
      default:
        return <SignTurnSlightRight />;
    }
  }

  return (
    <form>
      <Card className="history-card">
        <Card.Header className={!isMenuOpen ? 'collapsed' : ''}>
          History Log
          <ButtonIcon
            icon={isMenuOpen ? <ChevronDown /> : <ChevronLeft />}
            onClick={() => toggleMenu()}
          />
        </Card.Header>
        {historyData && isMenuOpen && (
          <Card.Body>
            <div className="history-card-data">
              {historyData.historyLogs.map((history) => (
                <div className="history-row" key={history.id}>
                  <div className="history-row-left">
                    <div className="history-icon">{displayIcon(history.event)}</div>
                    <div className="vertical-line" />
                  </div>
                  <div className="history-row-right">
                    <div className="history-row-date">{moment(history.DateCreated).local().format('MMM DD, YYYY h:mm:ss a')}</div>
                    <div className="history-row-description">{history.description}</div>
                  </div>
                </div>
              ))}
            </div>
            <Link
              label="View Older Logs"
              url="history" 
              className="view-all-logs"
            />
          </Card.Body>
        )}
        {historyData && historyData.historyLogs.length === 0 && (
          <Card.Body>
            <div className="history-data-empty">
              No history data found.
            </div>
          </Card.Body>
        )}
        {historyDataError && (
          <Card.Body>
            <div className="history-data-failed">
              History Logs failed to load.
            </div>
          </Card.Body>
        )}
      </Card>
    </form>
  );
};

export default HistoryCard;
