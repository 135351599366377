import React, { useState } from 'react';
import { ChevronLeft, ChevronDown, ThreeDotsVertical } from 'react-bootstrap-icons';
import { StatusChip, ButtonIcon } from 'app/components';
import AdditionalItemDetails from './AdditionalItemDetails';
import { formatCurrency } from 'app/utils';
import './index.scss';

const OrderItem = (props) => {
  const { orderId, itemInfo, shipmentId, showAll, showChangeVendorModal, showCancelOrderItemModal, showUnassignItemsModal } = props;
  const [additionalItemDetails, showAdditionalItemDetails] = useState(showAll || false);

  const orderItemOptions = (shipmentId, itemInfo) => [
    ...(shipmentId ? [{ value: 'Reroute', label: 'Reroute', onClick: () => { showChangeVendorModal({ shipmentId, itemInfo }); }}] : []),
    { value: 'Download Image', label: 'Download Image', onClick: () => { handleDownload(itemInfo) } },
    ...(showUnassignItemsModal ? [{ value: 'Unassign Item', label: 'Unassign Item', onClick: () => showUnassignItemsModal({ shipmentId, itemIds: [itemInfo.id], singleItemShortId: itemInfo.shortId, isAllItems: false }) }] : []),
    { value: 'Cancel Item', label: 'Cancel Item', destructive: true, onClick: () => showCancelOrderItemModal({ itemInfo }) },
  ];

  const handleDownload = (item) => {
    const imageSrc = item?.images?.[0]?.ourUrl || item?.images?.[0]?.thumbnailUrl || item?.images?.[0]?.url;
    let filename = imageSrc.substring(imageSrc.lastIndexOf('/') + 1);

    if (!filename.match(/\.(jpg|jpeg|png|gif)$/i)) {
      filename += '.jpg';
    }

    fetch(imageSrc)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading image:', error);
      });
  };

  return (
    <div className="order-item">
      <div className="item-header">
        <div className="id-status">
          <div className="item-name">{`Item ${itemInfo.shortId}`}</div>
          {itemInfo.status !== 'open' && <StatusChip type="shipment" size="slim" status={itemInfo.status} />}
        </div>
        <div className="shipment-options">
          <ButtonIcon
            icon={<ThreeDotsVertical />}
            options={orderItemOptions(shipmentId, itemInfo)}
          />
          <ButtonIcon
            icon={additionalItemDetails ? <ChevronDown /> : <ChevronLeft />}
            onClick={() => showAdditionalItemDetails(!additionalItemDetails)}
          />
        </div>
      </div>
      <div className="item-body">
        <div className="item-details">
          <div className="item-info-and-pricing">
            <div className="item-info"> 
              <div className="item-row">
                <div className="item-label">External Item ID</div>
                <div className="item-value">{itemInfo.externalId || '-'}</div>
              </div>
              <div className="item-row">
                <div className="item-label">OM SKU</div>
                <div className="item-value">{itemInfo.sku || '-'}</div>
              </div>
            </div>
            <div className="item-pricing">
              <div className="pricing-header">
                <div className="price-label">Qty</div>
                <div className="price-label">Unit Price</div>
                <div className="price-label">Total Item Price</div>
              </div>
              <div className="pricing-row">
                <div className="price-value">{itemInfo.quantity || '-'}</div>
                <div className="price-value">{itemInfo.customerPrice?.amount && itemInfo.customerPrice?.currency
                  ? formatCurrency(itemInfo.customerPrice.currency, itemInfo.customerPrice.amount)
                  : '-'}
                </div>
                <div className="price-value">{itemInfo.customerPrice?.amount && itemInfo.customerPrice?.currency
                  ? formatCurrency(itemInfo.customerPrice.currency, itemInfo.customerPrice?.amount * itemInfo.quantity)
                  : '-'}
                </div>
              </div>
            </div>
          </div>
          {additionalItemDetails && <AdditionalItemDetails orderId={orderId} itemInfo={itemInfo} />}
        </div>
      </div>
    </div>
  );
};

export default OrderItem;