import React, { useState } from 'react';
import MissingImage from 'app/assets/images/image-missing.png';

const Image = ({ src, alt }) => {
  const [isError, setIsError] = useState(false);

  const handleImageError = () => {
    setIsError(true);
  };

  return (
    <img 
      src={!src || isError ? MissingImage : src} 
      onError={handleImageError} 
      alt={alt} 
    />
  );
};

export default Image;
