import React, { useMemo } from 'react';
import { Card, MessageBar } from 'app/components';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';

const AverageFulfillmentTimeByVendor = ({ queryData = {} }) => {
  const { data, loading, error } = queryData;

  // sort the data by avg_fulfillment_time_in_hours and keep only the top 15 items
  const sortedData = useMemo(() => {
    if (!data || data.length === 0) return [];
    return data.sort((a, b) => b.avg_fulfillment_time_in_hours - a.avg_fulfillment_time_in_hours).slice(0, 15);
  }, [data]);

  const minValue = useMemo(() => {
    if (!sortedData || sortedData.length === 0) return 0;
    const min = Math.min(...sortedData.map(item => item.avg_fulfillment_time_in_hours));
    return Math.max(0, min - min * 0.1);
  }, [sortedData]);

  const maxValue = useMemo(() => {
    if (!sortedData || sortedData.length === 0) return 10;
    const max = Math.max(...sortedData.map(item => item.avg_fulfillment_time_in_hours));
    return max + max * 0.02;
  }, [sortedData]);

  const chartData = useMemo(() => ({
    series: [{
      data: sortedData ? sortedData.map((item, index) => ({
        x: item.vendor_name || 'Unknown Vendor ' + index,
        y: item.avg_fulfillment_time_in_hours,
      })) : []
    }],
    options: {
      title: {
        text: 'Average Fulfillment Time by Vendor (in hours)',
        align: 'left',
        style: {
          fontFamily: "Source Sans Pro",
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '26px',
          color: '#2E2E2E'
        }
      },
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e)
          }
        }
      },
      colors: ['#9CB4E2', '#F8B05B', '#E8D953', '#6DCB5D', '#9AA4FF'],
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '12px',
          colors: ['#ffffff'],
        }
      },
      xaxis: {
        min: Math.floor(minValue),
        max: Math.ceil(maxValue),
        tickAmount: Math.ceil(maxValue) - Math.floor(minValue),
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          },
          formatter: function (val) {
            return Math.round(val);
          }
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          },
        },
      },
      legend: {
        show: false
      },
    },
  }), [sortedData, minValue, maxValue]);

  return (
    <Card className={`average-fulfillment-time-by-vendor gooten-dashboard-chart ${sortedData && sortedData.length > 0 ? 'has-data' : ''}`}>
      {!loading && (
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      )}
      {error && (
        <div className="loading">
          <MessageBar color="yellow">An error occurred while fetching data</MessageBar>
        </div>
      )}
      {loading && (
        <div className="loading">
          <div className="loading-header">
            {chartData.options.title.text}
          </div>
          <Skeleton height={150} count={2} borderRadius={15} />
        </div>
      )}
    </Card>
  )
}

export default AverageFulfillmentTimeByVendor;
