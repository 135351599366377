import React from 'react';
import { Card, MessageBar } from 'app/components';
import ReactApexChart from 'react-apexcharts';

const EmptyChart = ({ title, showError = false }) => {
  const chartData = {
    series: [{
      data: [0, 0, 0, 0, 0, 0]
    }],
    options: {
      title: {
        text: title,
        align: 'left',
        style: {
          fontFamily: "Source Sans Pro",
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '26px',
          color: '#2E2E2E'
        }
      },
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
      },
      colors: ['#D3D3D3'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      xaxis: {
        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        min: 0,
        max: 10,
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          },
          formatter: function (val) {
            return Math.floor(val);
          }
        }
      },
      legend: {
        show: false
      },
    },
  };

  return (
    <Card className="gooten-dashboard-chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      {showError && (
        <MessageBar color="yellow">An error occurred while fetching data</MessageBar>
      )}
    </Card>
  );
};

export default EmptyChart;
