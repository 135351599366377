import React from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { object, string, array } from 'yup';
import { Card, Button, Input, Dropdown, MultiSelect } from 'app/components';
import { updateFacility } from 'app/store/actions/vendor';
import { vendorDetailsLoadingSelector } from 'app/store/selectors/vendor';
import { CountryList } from 'app/constants';
import './index.scss';

const AddEditShippingMapping = ({ vendorId, facilityId, shippingMapping, shippingMethodIndex, serviceMethodIndex, facilityDetails, backToShippingMapping }) => {
  const dispatch = useDispatch();

  const loading = useSelector(vendorDetailsLoadingSelector);

  const shipmentMethods = [{ "Name": "Standard", "Value": "Standard" }, { "Name": "Expedited", "Value": "Expedited" }, { "Name": "Overnight", "Value": "Overnight" }];

  return (
    <div className="add-edit-shipping-mapping-view">
      <Formik
        enableReinitialize
        initialValues={{
          carrierName: shippingMapping?.carrierName || '',
          shipmentMethod: shippingMapping?.shippingMethods?.[shippingMethodIndex]?.shipmentMethod || '',
          serviceMethodsAllowed: shippingMapping?.shippingMethods?.[shippingMethodIndex]?.carrierShippingServiceMethods?.[serviceMethodIndex]?.serviceMethodsAllowed[0] || '',
          carrierCode: shippingMapping?.shippingMethods?.[shippingMethodIndex]?.carrierShippingServiceMethods?.[serviceMethodIndex]?.vendorMapping?.carrierCode || '',
          serviceCode: shippingMapping?.shippingMethods?.[shippingMethodIndex]?.carrierShippingServiceMethods?.[serviceMethodIndex]?.vendorMapping?.serviceCode || '',
          countryCodesToApply: shippingMapping?.shippingMethods?.[shippingMethodIndex]?.carrierShippingServiceMethods?.[serviceMethodIndex]?.countryCodesToApply || [],
        }}
        validationSchema={object().shape({
          carrierName: string().required('Enter a Carrier Name'),
          shipmentMethod: string().required('Enter a Shipment Method'),
          serviceMethodsAllowed: string().required('Enter Services Method'),
          carrierCode: string(),
          serviceCode: string(),
          countryCodesToApply: array().of(string()).test(
            'is-valid-array',
            'Invalid country codes selection',
            value => !value || (Array.isArray(value) && value.every(code => typeof code === 'string'))
          ),
        })}
        onSubmit={(values) => {
          const newCarrierMethod = {
            serviceMethodsAllowed: [values.serviceMethodsAllowed],
            countryCodesToApply: values.countryCodesToApply || [],
            vendorMapping: {
              carrierCode: values.carrierCode || '',
              serviceCode: values.serviceCode || '',
            },
          };

          const newShippingMethod = {
            shipmentMethod: values.shipmentMethod,
            carrierShippingServiceMethods: [newCarrierMethod],
          };

          // Clone the shippingMappings array to update immutably
          let updatedShippingMappings = facilityDetails.shippingMappings.map((mapping) => ({
            ...mapping,
            shippingMethods: mapping.shippingMethods.map((method) => ({
              ...method,
              carrierShippingServiceMethods: (method.carrierShippingServiceMethods || []).map((carrierMethod) => ({ ...carrierMethod })),
            })),
          }));

          // Find the original entry to remove it if necessary
          const removeOldEntry = () => {
            const oldCarrierIndex = updatedShippingMappings.findIndex(
              mapping => mapping.carrierName === shippingMapping?.carrierName
            );

            if (oldCarrierIndex >= 0) {
              const oldShipmentMethodIndex = updatedShippingMappings[oldCarrierIndex].shippingMethods.findIndex(
                method => method.shipmentMethod === shippingMapping?.shippingMethods?.[shippingMethodIndex]?.shipmentMethod
              );

              if (oldShipmentMethodIndex >= 0) {
                const oldServiceIndex = updatedShippingMappings[oldCarrierIndex].shippingMethods[oldShipmentMethodIndex].carrierShippingServiceMethods.findIndex(
                  method => method.serviceMethodsAllowed[0] === shippingMapping?.shippingMethods?.[shippingMethodIndex]?.carrierShippingServiceMethods?.[serviceMethodIndex]?.serviceMethodsAllowed?.[0]
                );

                if (oldServiceIndex >= 0) {
                  // Remove the old service method
                  updatedShippingMappings[oldCarrierIndex].shippingMethods[oldShipmentMethodIndex].carrierShippingServiceMethods.splice(oldServiceIndex, 1);

                  // Remove the entire shipment method if no carrier methods exist
                  if (updatedShippingMappings[oldCarrierIndex].shippingMethods[oldShipmentMethodIndex].carrierShippingServiceMethods.length === 0) {
                    updatedShippingMappings[oldCarrierIndex].shippingMethods.splice(oldShipmentMethodIndex, 1);
                  }

                  // Remove the entire carrier if no shipping methods exist
                  if (updatedShippingMappings[oldCarrierIndex].shippingMethods.length === 0) {
                    updatedShippingMappings.splice(oldCarrierIndex, 1);
                  }
                }
              }
            }
          };

          // If the shipment method or carrier name has changed, remove the old entry
          if (shippingMapping) {
            removeOldEntry();
          }

          // Add or update the new carrier entry
          const existingCarrierIndex = updatedShippingMappings.findIndex(
            mapping => mapping.carrierName === values.carrierName
          );

          if (existingCarrierIndex >= 0) {
            const existingCarrier = updatedShippingMappings[existingCarrierIndex];

            // Check if shipment method exists
            const existingShipmentMethodIndex = existingCarrier.shippingMethods.findIndex(
              method => method.shipmentMethod === values.shipmentMethod
            );

            if (existingShipmentMethodIndex >= 0) {
              const existingMethod = existingCarrier.shippingMethods[existingShipmentMethodIndex];

              // Ensure only one serviceMethodsAllowed per carrierMethods entry
              const carrierMethodExists = existingMethod.carrierShippingServiceMethods.find(
                method => method.serviceMethodsAllowed[0] === values.serviceMethodsAllowed
              );

              if (!carrierMethodExists) {
                // Add new carrierMethod if it doesn't already exist
                existingMethod.carrierShippingServiceMethods.push(newCarrierMethod);
              } else {
                // Update the existing carrierMethod
                carrierMethodExists.countryCodesToApply = newCarrierMethod.countryCodesToApply;
                carrierMethodExists.vendorMapping = newCarrierMethod.vendorMapping;
              }
            } else {
              // Add new shipment method
              existingCarrier.shippingMethods.push(newShippingMethod);
            }
          } else {
            // Add a new carrier with the new shipping method
            const newMapping = {
              carrierName: values.carrierName,
              shippingMethods: [newShippingMethod],
            };
            updatedShippingMappings.push(newMapping);
          }

          const updatedFacilityDetails = {
            ...facilityDetails,
            shippingMappings: updatedShippingMappings,
          };

          // Dispatch the update action
          dispatch(updateFacility({ vendorId, facilityId, data: updatedFacilityDetails, cb: backToShippingMapping }));
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          submitCount,
          setFieldValue,
        }) => (
          <Card className={`shipping-mapping-card ${shippingMapping ? 'edit-mode' : ''}`}>
            <Card.Header>
              {Object.keys(shippingMapping).length > 0 && shippingMapping.carrierName ? 'Edit Shipping Mapping' : 'Add Shipping Mapping'}
              <div className="action-buttons">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={backToShippingMapping}
                  label="Cancel"
                />
                <Button
                  variant="primary"
                  size="small"
                  label="Save"
                  disabled={loading}
                  onClick={() => (loading ? null : handleSubmit())}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <div className="shipping-mapping-information">
                  <Input
                    label="Carrier Name"
                    name="carrierName"
                    value={values.carrierName}
                    onChange={handleChange}
                    placeholder="Carrier Name"
                    errorMessage={submitCount > 0 && errors.carrierName}
                  />
                  <Dropdown
                    label="Shipment Method"
                    name="shipmentMethod"
                    value={values.shipmentMethod}
                    onChange={handleChange}
                    options={shipmentMethods.map((shipmentMethod) => ({ value: shipmentMethod.Value, label: shipmentMethod.Name }))}
                    errorMessage={submitCount > 0 && errors.shipmentMethod}
                  />
                  <Input
                    label="Shipping Carrier Services"
                    name="serviceMethodsAllowed"
                    value={values.serviceMethodsAllowed}
                    onChange={handleChange}
                    placeholder="Shipping Carrier Services"
                    errorMessage={submitCount > 0 && errors.serviceMethodsAllowed}
                  />
                  <Input
                    label="Carrier Code"
                    name="carrierCode"
                    value={values.carrierCode}
                    onChange={handleChange}
                    placeholder="Carrier Code"
                    errorMessage={submitCount > 0 && errors.carrierCode}
                  />
                  <Input
                    label="Service Code"
                    name="serviceCode"
                    value={values.serviceCode}
                    onChange={handleChange}
                    placeholder="Service Code"
                    errorMessage={submitCount > 0 && errors.serviceCode}
                  />
                  <MultiSelect
                    label="Country Codes to Apply"
                    name="countryCodesToApply"
                    options={CountryList.map(country => (
                      { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}` }
                    ))}
                    value={values.countryCodesToApply.map(code => ({
                      value: code,
                      label: `${code} - ${CountryList.find(c => c.CountryCode === code)?.Name}`,
                    }))}
                    onChange={selectedOptions => {
                      const selectedValues = selectedOptions.map(option => option.value); // Extract just the values
                      setFieldValue('countryCodesToApply', selectedValues); // Use setFieldValue to update Formik
                    }}
                    errorMessage={submitCount > 0 && errors.countryCodesToApply}
                  />

                </div>
              </form>
            </Card.Body>
          </Card>
        )}
      </Formik>
    </div>
  );
};

export default AddEditShippingMapping;
