import { handleActions } from "redux-actions";
import {
  bulkUpload,
  bulkUploadFailure,
  bulkUploadSuccess,
  reset,
  searchInventories,
  searchInventoriesFailure,
  searchInventoriesSuccess,
} from "../actions/inventory";

const initialState = {
  bulkUpload: {
    loading: false,
    success: null,
    error: null,
  },
  search: {
    loading: false,
    data: null,
    error: null,
  },
};

const inventoryReducer = handleActions(
  {
    [bulkUpload]: (state) => ({
      ...state,
      bulkUpload: {
        ...state.bulkUpload,
        loading: true,
        success: null,
        error: null,
      },
    }),

    [bulkUploadSuccess]: (state, data) => ({
      ...state,
      bulkUpload: {
        ...state.bulkUpload,
        loading: false,
        success: data.payload.response,
        error: null,
      },
    }),

    [bulkUploadFailure]: (state, data) => ({
      ...state,
      bulkUpload: {
        ...state.bulkUpload,
        loading: false,
        success: null,
        error: data.payload,
      },
    }),

    [searchInventories]: (state) => ({ 
      ...state,
      search: {
        ...state.search,
        loading: true,
        error: null,
      }
    }),

    [searchInventoriesSuccess]: (state, data) => ({
      ...state,
      search: {
        ...state.search,
        loading: false,
        data: data.payload,
      }
    }),

    [searchInventoriesFailure]: (state, data) => ({ 
      ...state,
      search: {
        ...state.search,
        loading: false,
        error: data.payload,
      }
    }),

    [reset]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default inventoryReducer;
