import { put, takeEvery, call, all } from 'redux-saga/effects';
import {
  getAllQueries,
  getAllQueriesSuccess,
  getAllQueriesFailure,
  runQueryById,
  runQueryByIdSuccess,
  runQueryByIdFailure,
} from "app/store/actions/insight"
import InsightServices from 'app/services/insightServices';

function* fetchAllQueries() {
  try {
    const resp = yield call([InsightServices, InsightServices.getAllQueries]);
    yield put(getAllQueriesSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllQueriesFailure(error));
  }
}

function* doRunQueryById(action) {
  const { query: { queryId, runAs }, dateRange } = action.payload;

  try {
    const resp = yield call([InsightServices, InsightServices.runQueryById], queryId, runAs, dateRange);
    const refreshedAt = queryId === 'orders-count-by-status' ? new Date(resp.refreshedAt).toLocaleString() : null;
    yield put(runQueryByIdSuccess({queryId, dateRange, resp, refreshedAt}));
  } catch (error) {
    console.error('error', error);
    yield put(runQueryByIdFailure({queryId, dateRange, error}));
  }
}

function* watchData() {
  yield takeEvery(getAllQueries.toString(), fetchAllQueries);
  yield takeEvery(runQueryById.toString(), doRunQueryById);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}