import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from 'app/utils';
import { Clipboard, EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import './index.scss';

const DataPoint = ({
  title,
  data = '',                     
  onClick = null,                
  enableClipboardCopy = false,   
  icon = <Clipboard />,          
  maxWidth = null,               
  className = '',                
  maskData = false               
}) => {
  const [isMasked, setIsMasked] = useState(maskData);

  const toggleMask = () => {
    setIsMasked(!isMasked);
  };

  return (
    <div
      className={`data-point ${className} ${maskData ? 'mask-data' : ''}`}
      style={{ maxWidth }}
      onClick={() => (maskData ? toggleMask() : null)}
    >
      <div className="data-header">{title}</div>
      <div className={`data-value ${enableClipboardCopy ? 'allow-copy' : ''}`}>
        <span
          className="text-content"
          onClick={() =>
            onClick
              ? onClick()
              : enableClipboardCopy && !maskData
              ? copyToClipboard(data)
              : null
          }
        >
          {maskData && isMasked ? (
            <span className="masked-data">*******</span>
          ) : (
            data
          )}
        </span>
        {maskData && (
          <div className="data-point-mask-icon" style={{ cursor: 'pointer' }} onClick={toggleMask}>
            {isMasked ? <EyeFill className="eye-fill" /> : <EyeSlashFill className="eye-slash-fill" />}
          </div>
        )}
        {enableClipboardCopy && !maskData && (
          <div className="data-point-clipboard-icon">
            {React.cloneElement(icon)}
          </div>
        )}
      </div>
    </div>
  );
};

DataPoint.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  enableClipboardCopy: PropTypes.bool,
  icon: PropTypes.element,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  maskData: PropTypes.bool,
};

export default DataPoint;
