import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PermissionDenied, PageNotFound } from 'app/components';
import WebhooksView from './WebhooksView';
import WebhooksDetails from './WebhooksDetails';
import Customize from './WebhooksDetails/Customize';
import { usePermission } from 'app/permissions';

function Webhooks() {
  // get user permissions
  const canManageWebhooks = usePermission('notification', 'create');

  return (
    <Routes>
      <Route path="/" element={<WebhooksView />} />
      <Route path="/action-history" element={<WebhooksView />} />
      <Route path="/create-webhook" element={canManageWebhooks ? <WebhooksDetails /> : <PermissionDenied />} />
      <Route path="/:webhookId/customize" element={<Customize />} />
      <Route path="/:webhookId/edit" element={<WebhooksDetails startEdit={true} />} />
      <Route path="/:webhookId/*" element={<WebhooksDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Webhooks;