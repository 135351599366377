import { createSelector } from 'reselect';

const orderSelector = state => state.order;

export const globalOrderSearchDataSelector = createSelector([orderSelector], orderStore => orderStore.globalSearchResults.data);
export const globalOrderSearchLoadingSelector = createSelector([orderSelector], orderStore => orderStore.globalSearchResults.loading);
export const globalOrderSearchErrorsSelector = createSelector([orderSelector], orderStore => orderStore.globalSearchResults.error);

export const createOrderDataSelector = createSelector([orderSelector], orderStore => orderStore.createOrder.data);
export const createOrderLoadingSelector = createSelector([orderSelector], orderStore => orderStore.createOrder.loading);
export const createOrderErrorsSelector = createSelector([orderSelector], orderStore => orderStore.createOrder.error);

export const ordersDataSelector = createSelector([orderSelector], orderStore => orderStore.orders.data);
export const ordersDataLoadingSelector = createSelector([orderSelector], orderStore => orderStore.orders.loading);
export const ordersDataErrorsSelector = createSelector([orderSelector], orderStore => orderStore.orders.error);

export const orderDetailsSelector = createSelector([orderSelector], orderStore => orderStore.order.data);
export const orderDetailsLoadingSelector = createSelector([orderSelector], orderStore => orderStore.order.loading);
export const orderDetailsErrorsSelector = createSelector([orderSelector], orderStore => orderStore.order.error);

export const itemsFilterSelector = createSelector([orderSelector], orderStore => orderStore.order.data?.itemsFilter);

export const placeAnOrderProductsSelector = createSelector([orderSelector], orderStore => orderStore.placeAnOrder.selectedProducts);
export const placeAnOrderLoadingSelector = createSelector([orderSelector], orderStore => orderStore.placeAnOrder.loading);
export const placeAnOrderErrorsSelector = createSelector([orderSelector], orderStore => orderStore.placeAnOrder.error);

export const refundAnOrderDataSelector = createSelector([orderSelector], orderStore => orderStore.refundAnOrder.data);
export const refundAnOrderLoadingSelector = createSelector([orderSelector], orderStore => orderStore.refundAnOrder.loading);
export const refundAnOrderErrorsSelector = createSelector([orderSelector], orderStore => orderStore.refundAnOrder.error);

export const auditInfoSelector = createSelector([orderSelector], orderStore => orderStore.auditInfo.data);
export const auditInfoLoadingSelector = createSelector([orderSelector], orderStore => orderStore.auditInfo.loading);
export const auditInfoErrorsSelector = createSelector([orderSelector], orderStore => orderStore.auditInfo.error);