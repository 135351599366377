import { createAction } from 'redux-actions';

const prefix = "INTEGRATION";

export const getIntegrations = createAction(`${prefix}/GET_INTEGRATIONS`);
export const getIntegrationsSuccess = createAction(`${prefix}/GET_INTEGRATIONS_SUCCESS`);
export const getIntegrationsFailure = createAction(`${prefix}/GET_INTEGRATIONS_FAILURE`);

export const getMerchantIntegrations = createAction(`${prefix}/GET_MERCHANT_INTEGRATIONS`);
export const getMerchantIntegrationsSuccess = createAction(`${prefix}/GET_MERCHANT_INTEGRATIONS_SUCCESS`);
export const getMerchantIntegrationsFailure = createAction(`${prefix}/GET_MERCHANT_INTEGRATIONS_FAILURE`);

export const getIntegrationBasicInfo = createAction(`${prefix}/GET_INTEGRATION_BASIC_INFO`);
export const getIntegrationBasicInfoSuccess = createAction(`${prefix}/GET_INTEGRATION_BASIC_INFO_SUCCESS`);
export const getIntegrationBasicInfoFailure = createAction(`${prefix}/GET_INTEGRATION_BASIC_INFO_FAILURE`);

export const getIntegrationVariables = createAction(`${prefix}/GET_INTEGRATION_VARIABLES`);
export const getIntegrationVariablesSuccess = createAction(`${prefix}/GET_INTEGRATION_VARIABLES_SUCCESS`);
export const getIntegrationVariablesFailure = createAction(`${prefix}/GET_INTEGRATION_VARIABLES_FAILURE`);

export const getMerchantIntegrationVariables = createAction(`${prefix}/GET_MERCHANT_INTEGRATION_VARIABLES`);
export const getMerchantIntegrationVariablesSuccess = createAction(`${prefix}/GET_MERCHANT_INTEGRATION_VARIABLES_SUCCESS`);
export const getMerchantIntegrationVariablesFailure = createAction(`${prefix}/GET_MERCHANT_INTEGRATION_VARIABLES_FAILURE`);

export const updateIntegrationBasicInfo = createAction(`${prefix}/UPDATE_INTEGRATION_BASIC_INFO`);
export const updateIntegrationBasicInfoSuccess = createAction(`${prefix}/UPDATE_INTEGRATION_BASIC_INFO_SUCCESS`);
export const updateIntegrationBasicInfoFailure = createAction(`${prefix}/UPDATE_INTEGRATION_BASIC_INFO_FAILURE`);

export const createIntegration = createAction(`${prefix}/CREATE_INTEGRATION`);
export const createIntegrationSuccess = createAction(`${prefix}/CREATE_INTEGRATION_SUCCESS`);
export const createIntegrationFailure = createAction(`${prefix}/CREATE_INTEGRATION_FAILURE`);

export const getIntegrationVersions = createAction(`${prefix}/GET_INTEGRATION_VERSIONS`);
export const getIntegrationVersionsSuccess = createAction(`${prefix}/GET_INTEGRATION_VERSIONS_SUCCESS`);
export const getIntegrationVersionsFailure = createAction(`${prefix}/GET_INTEGRATION_VERSIONS_FAILURE`);

export const getIntegrationDetails = createAction(`${prefix}/GET_INTEGRATION_DETAILS`);
export const getIntegrationDetailsSuccess = createAction(`${prefix}/GET_INTEGRATION_DETAILS_SUCCESS`);
export const getIntegrationDetailsFailure = createAction(`${prefix}/GET_INTEGRATION_DETAILS_FAILURE`);

export const getIntegrationCustomizations = createAction(`${prefix}/GET_INTEGRATION_CUSTOMIZATIONS`);
export const getIntegrationCustomizationsSuccess = createAction(`${prefix}/GET_INTEGRATION_CUSTOMIZATIONS_SUCCESS`);
export const getIntegrationCustomizationsFailure = createAction(`${prefix}/GET_INTEGRATION_CUSTOMIZATIONS_FAILURE`);

export const updateIntegration = createAction(`${prefix}/UPDATE_INTEGRATION`);
export const updateIntegrationSuccess = createAction(`${prefix}/UPDATE_INTEGRATION_SUCCESS`);
export const updateIntegrationFailure = createAction(`${prefix}/UPDATE_INTEGRATION_FAILURE`);

export const updateMerchantVariables = createAction(`${prefix}/UPDATE_MERCHANT_VARIABLES`);
export const updateMerchantVariablesSuccess = createAction(`${prefix}/UPDATE_MERCHANT_VARIABLES_SUCCESS`);
export const updateMerchantVariablesFailure = createAction(`${prefix}/UPDATE_MERCHANT_VARIABLES_FAILURE`);

export const publishIntegrationVersion = createAction(`${prefix}/PUBLISH_INTEGRATION_VERSION`);
export const publishIntegrationVersionSuccess = createAction(`${prefix}/PUBLISH_INTEGRATION_VERSION_SUCCESS`);
export const publishIntegrationVersionFailure = createAction(`${prefix}/PUBLISH_INTEGRATION_VERSION_FAILURE`);

export const cloneIntegrationVersion = createAction(`${prefix}/CLONE_INTEGRATION_VERSION`);
export const cloneIntegrationVersionSuccess = createAction(`${prefix}/CLONE_INTEGRATION_VERSION_SUCCESS`);
export const cloneIntegrationVersionFailure = createAction(`${prefix}/CLONE_INTEGRATION_VERSION_FAILURE`);

export const deleteIntegration = createAction(`${prefix}/DELETE_INTEGRATION`);
export const deleteIntegrationSuccess = createAction(`${prefix}/DELETE_INTEGRATION_SUCCESS`);
export const deleteIntegrationFailure = createAction(`${prefix}/DELETE_INTEGRATION_FAILURE`);

export const getEventTypes = createAction(`${prefix}/GET_EVENT_TYPES`);
export const getEventTypesSuccess = createAction(`${prefix}/GET_EVENT_TYPES_SUCCESS`);
export const getEventTypesFailure = createAction(`${prefix}/GET_EVENT_TYPES_FAILURE`);

export const connectToIntegration = createAction(`${prefix}/CONNECT_TO_INTEGRATION`);
export const connectToIntegrationSuccess = createAction(`${prefix}/CONNECT_TO_INTEGRATION_SUCCESS`);
export const connectToIntegrationFailure = createAction(`${prefix}/CONNECT_TO_INTEGRATION_FAILURE`);

export const disconnectIntegration = createAction(`${prefix}/DISCONNECT_INTEGRATION`);
export const disconnectIntegrationSuccess = createAction(`${prefix}/DISCONNECT_INTEGRATION_SUCCESS`);
export const disconnectIntegrationFailure = createAction(`${prefix}/DISCONNECT_INTEGRATION_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);