import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { createTransform } from 'redux-persist';

// create a transform to remove `loading` from `auth`
const authTransform = createTransform(
  (inboundState, key) => {
    if (key === 'user') {
      const { auth, ...rest } = inboundState;
      // eslint-disable-next-line no-unused-vars
      const { loading, ...authWithoutLoading } = auth || {};
      return {
        ...rest,
        auth: authWithoutLoading,
      };
    }
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState) => {
    return outboundState; // no changes on rehydration
  },
  // define which reducers this transform gets called for.
  { whitelist: ['user'] }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  transforms: [authTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
