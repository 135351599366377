import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateItemImageUrl } from 'app/store/actions/order';
import { Modal, Button, Input } from 'app/components';
import './index.scss';

const ReplaceImageModal = ({ orderId, itemId, setShowReplaceImageModal }) => {
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState('');
  // const [fileName, setFileName] = useState('');
  // const [isFileUploaded, setIsFileUploaded] = useState(false);

  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0]; // Get the first selected file
  //   if (file) {
  //     setFileName(file.name); // Set the file name to display
  //     setIsFileUploaded(true); // Enable the upload button
  //   }
  // };

  return (
    <Modal
      className="replace-image-modal"
      title="Replace Image"
      onClose={() => setShowReplaceImageModal(null)}
      hideButtons={true}
    >
      <div className="replace-image-content">
        <Input
          name="imageUrl"
          label='Upload Image from URL'
          placeholder="Image URL"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
        />
        {/* <div className="divider">Or Upload image from your Files</div>
        <Button
          variant="primary"
          size="small"
          className="choose-file-btn"
          label="Choose a File"
          onClick={() => document.getElementById('fileInput').click()}
        />
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />

        {fileName && (
          <div className="file-name">
            Selected file: <strong>{fileName}</strong>
          </div>
        )} */}

        <div className="action-buttons">
          <Button
            variant="secondary"
            label="Cancel"
            onClick={() => setShowReplaceImageModal(null)}
          />
          <Button
            variant="primary"
            label="Replace Image"
            disabled={!imageUrl} // && !isFileUploaded}
            onClick={() => {
              const data ={
                images: [
                  { url: imageUrl.trim() }
                ]
              }
              dispatch(updateItemImageUrl({orderId, itemId, data }));
              setShowReplaceImageModal(null);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReplaceImageModal;
