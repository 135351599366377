import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { PermissionDenied, PageNotFound } from 'app/components';
import MerchantsView from './MerchantsView';
import MerchantDetails from './MerchantDetails';
import CreateTemplateView from './MerchantDetails/TemplatesView/CreateTemplateVIew';
import { usePermission } from 'app/permissions';

function Merchants() {
  // get user permissions
  const canCreateMerchants = usePermission('merchant', 'create');

  return (
    <Routes>
      <Route exact path="/" element={<MerchantsView />} />
      <Route exact path="/create-merchant" element={<Navigate to="details" replace={true} />} />
      <Route path="/create-merchant/*" element={canCreateMerchants ? <MerchantDetails /> : <PermissionDenied />} />
      <Route exact path="/:merchantId" element={<Navigate to="details" replace={true} />} />
      <Route path="/:merchantId/*" element={<MerchantDetails />} />
      <Route path="/:merchantId/templates/create-template/:category" element={<CreateTemplateView />} />
      <Route path="/:merchantId/templates/create-template/:category/:templateId" element={<CreateTemplateView />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Merchants;