import React from 'react';
import { Link, MessageBar } from 'app/components';
import './index.scss';

const OrderMessageCard = ({ orderDetails }) => {
  if (!orderDetails || !orderDetails?.status) {
    return null;
  }

  if (orderDetails.status === 'hold') {
    return (
      <MessageBar color="yellow" className="order-message-card">
        {`We put this order on Customer Care Hold. For more details `}
        <Link
          label="View History Logs"
          url="history"
        />.
      </MessageBar>
    )
  }

  if (orderDetails.status === 'imageerror') {
    return (
      <MessageBar color="red" className="order-message-card">
        We encountered an item with an image issue
      </MessageBar>
    )
  }

  if (orderDetails.status === 'fulfillmenterror') {
    return (
      <MessageBar color="red" className="order-message-card">
        A shipment failed submission to the vendor
      </MessageBar>
    )
  }
}

export default OrderMessageCard;