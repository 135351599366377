import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getIntegrations,
  getIntegrationsSuccess,
  getIntegrationsFailure,
  getIntegrationCustomizations,
  getIntegrationCustomizationsSuccess,
  getIntegrationCustomizationsFailure,
  getIntegrationBasicInfo,
  getIntegrationBasicInfoSuccess,
  getIntegrationBasicInfoFailure,
  getIntegrationVariables,
  getIntegrationVariablesSuccess,
  getIntegrationVariablesFailure,
  getMerchantIntegrationVariables,
  getMerchantIntegrationVariablesSuccess,
  getMerchantIntegrationVariablesFailure,
  updateIntegrationBasicInfo,
  updateIntegrationBasicInfoSuccess,
  updateIntegrationBasicInfoFailure,
  createIntegration,
  createIntegrationSuccess,
  createIntegrationFailure,
  getIntegrationVersions,
  getIntegrationVersionsSuccess,
  getIntegrationVersionsFailure,
  getIntegrationDetails,
  getIntegrationDetailsSuccess,
  getIntegrationDetailsFailure,
  updateIntegration,
  updateIntegrationSuccess,
  updateIntegrationFailure,
  updateMerchantVariables,
  updateMerchantVariablesSuccess,
  updateMerchantVariablesFailure,
  publishIntegrationVersion,
  publishIntegrationVersionSuccess,
  publishIntegrationVersionFailure,
  deleteIntegration,
  deleteIntegrationSuccess,
  deleteIntegrationFailure,
  getEventTypes,
  getEventTypesSuccess,
  getEventTypesFailure,
  connectToIntegration,
  connectToIntegrationSuccess,
  connectToIntegrationFailure,
  disconnectIntegration,
  disconnectIntegrationSuccess,
  disconnectIntegrationFailure,
  getMerchantIntegrations,
  getMerchantIntegrationsSuccess,
  getMerchantIntegrationsFailure,
} from "app/store/actions/integration"
import IntegrationServices from 'app/services/integrationServices';
import VendorServices from 'app/services/vendorServices';
import TransformationServices from 'app/services/transformationServices';

function* fetchIntegrations(action) {
  const data = action.payload;

  try {
    const response = yield call([IntegrationServices, IntegrationServices.getIntegrations], data);
    const plugins = response.plugins; // Normalize to common structure
    const vendorIds = plugins.map(obj => obj.integratorId);

    let vendorMap = {};

    // fetch vendor details in bulk if there are vendorIds
    if (vendorIds.length > 0) {
      const response2 = yield call([VendorServices, VendorServices.getVendorsBulk], vendorIds);

      // create a mapping of vendorId to vendor name
      vendorMap = response2.bulk.reduce((acc, item) => {
        if (item.status === 200 && item.response.vendor) {
          acc[item.response.vendor.vendorId] = item.response.vendor.name.trim();
        }
        return acc;
      }, {});
    }

    // update the plugins with vendor names
    const updatedPlugins = plugins.map(plugin => {
      const vendorName = vendorMap[plugin.integratorId] || 'Unknown Vendor'; 
      return {
        ...plugin,
        vendorName: vendorName
      };
    });

    // update response with the new plugins array
    const updatedResponse = {
      ...response,
      plugins: updatedPlugins
    };
    delete updatedResponse.merchantPlugins;

    // dispatch success actions with the updated data
    yield put(getIntegrationsSuccess(updatedResponse));
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationsFailure(error));
  }
}

function* fetchMerchantIntegrations(action) {
  const data = action.payload;

  try {
    const response = yield call([IntegrationServices, IntegrationServices.getMerchantIntegrations], data.merchantId);
    const plugins = response.merchantPlugins; // Normalize to common structure
    const vendorIds = plugins.map(obj => obj.integratorId);

    let vendorMap = {};

    // fetch vendor details in bulk if there are vendorIds
    if (vendorIds.length > 0) {
      const response2 = yield call([VendorServices, VendorServices.getVendorsBulk], vendorIds);

      // create a mapping of vendorId to vendor name
      vendorMap = response2.bulk.reduce((acc, item) => {
        if (item.status === 200 && item.response.vendor) {
          acc[item.response.vendor.vendorId] = item.response.vendor.name.trim();
        }
        return acc;
      }, {});
    }

    // update the plugins with vendor names
    const updatedPlugins = plugins.map(plugin => {
      const vendorName = vendorMap[plugin.integratorId] || 'Unknown Vendor'; 
      return {
        ...plugin,
        vendorName: vendorName
      };
    });

    // update response with the new plugins array
    const updatedResponse = {
      ...response,
      plugins: updatedPlugins
    };
    delete updatedResponse.merchantPlugins;

    // dispatch success actions with the updated data
    yield put(getMerchantIntegrationsSuccess(updatedResponse));
  } catch (error) {
    console.error('error', error);
    yield put(getMerchantIntegrationsFailure(error));
  }
}


function* fetchIntegrationCustomizations(action) {
  const { integrationDetails, cb } = action.payload;

  try {
    const events = integrationDetails?.details?.events;
    if (events) {
      const updatedEvents = yield all(
        events.map(function* (event) {
          const updatedEvent = { ...event };

          if (event.eventId) {
            const response = yield call(
              [TransformationServices, TransformationServices.getTransformationDetails],
              event.transformationId
            );
            updatedEvent.transformationData = response;
          }

          return updatedEvent;
        })
      );

      const updatedIntegrationDetails = {
        ...integrationDetails,
        details: {
          ...integrationDetails.details,
          events: updatedEvents,
        },
      };

      yield put(getIntegrationCustomizationsSuccess(updatedIntegrationDetails));
    } else {
      yield put(getIntegrationCustomizationsSuccess(integrationDetails));
    }

    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationCustomizationsFailure(error));
  }
}

function* fetchIntegrationBasicInfo(action) {
  const { pluginId } = action.payload;

  try {
    const resp = yield call([IntegrationServices, IntegrationServices.getIntegrationBasicInfo], pluginId);
    yield put(getIntegrationBasicInfoSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationBasicInfoFailure(error));
  }
}

function* doUpdateIntegrationBasicInfo(action) {
  const { pluginId, data, cb } = action.payload;

  try {
    const resp = yield call([IntegrationServices, IntegrationServices.updateIntegrationBasicInfo], pluginId, data);
    yield put(updateIntegrationBasicInfoSuccess(resp));
    toast.success("Integration Basic Info Updated Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateIntegrationBasicInfoFailure(error));
    toast.error("Integration Basic Info Update Failed", {
      theme: 'colored',
    });
  }
}

function* doCreateIntegration(action) {
  const { data, cb } = action.payload;

  try {
    const resp = yield call([IntegrationServices, IntegrationServices.createIntegration], data);
    yield put(createIntegrationSuccess(resp));
    toast.success("Integration Created Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb(resp?.plugin?.id);
  } catch (error) {
    console.error('error', error);
    yield put(createIntegrationFailure(error));
    toast.error("Integration Creation Failed", {
      theme: 'colored',
    });
  }
}

function* fetchIntegrationVersions(action) {
  const { pluginId, cb } = action.payload;

  function getHighestVersionId(data) {
    let majorVersions = [];
    let minorVersions = [];

    data.forEach(item => {
      if (item.isDraft) {
        minorVersions.push(item)
      } else {
        majorVersions.push(item);
      }
    });

    if (majorVersions.length > 0) {
      return majorVersions.sort((a, b) => b.name - a.name)[0].id;
    } else {
      return minorVersions.sort((a, b) => b.name - a.name)[0].id;
    }
  }

  try {
    // first get all the versions of the plugin
    const response = yield call([IntegrationServices, IntegrationServices.getIntegrationVersions], pluginId);

    // then get the basic info of the plugin
    const response2 = yield call([IntegrationServices, IntegrationServices.getIntegrationBasicInfo], pluginId);

    // find the highest major version.  If no major version is found, get the highest minor version
    const highestVersionId = response?.pluginVersions?.length > 0 ? getHighestVersionId(response.pluginVersions) : null;

    // if there is a highest major version, get the details of that version, otherwise just set the integration details to null
    if (!highestVersionId) {
      yield put(getIntegrationDetailsSuccess({ pluginVersion: {} }));
    } else {
      // make an api call to get the details of the highest major version
      const response3 = yield call([IntegrationServices, IntegrationServices.getIntegrationDetails], pluginId, highestVersionId);
      yield put(getIntegrationDetailsSuccess(response3));
    }
    yield put(getIntegrationVersionsSuccess(response));
    yield put(getIntegrationBasicInfoSuccess(response2));
    if (cb) cb(highestVersionId);
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationVersionsFailure(error));
  }
}

function* fetchIntegrationDetails(action) {
  const { pluginId, pluginVersionId, cb } = action.payload;

  try {
    const response = yield call([IntegrationServices, IntegrationServices.getIntegrationDetails], pluginId, pluginVersionId);
    yield put(getIntegrationDetailsSuccess(response));
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationDetailsFailure(error));
  }
}

function* fetchIntegrationVariables(action) {
  const { pluginId, pluginVersionId } = action.payload;

  try {
    const response = yield call([IntegrationServices, IntegrationServices.getIntegrationVariables], pluginId, pluginVersionId);
    yield put(getIntegrationVariablesSuccess(response));
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationVariablesFailure(error));
  }
}

function* fetchMerchantIntegrationsVariables(action) {
  const { merchantPluginId } = action.payload;

  try {
    const response = yield call([IntegrationServices, IntegrationServices.getMerchantIntegrationVariables], merchantPluginId);
    yield put(getMerchantIntegrationVariablesSuccess(response));
  } catch (error) {
    console.error('error', error);
    yield put(getMerchantIntegrationVariablesFailure(error));
  }
}

function* doUpdateIntegration(action) {
  const {
    transformationChanged,
    transformationId,
    snippetId,
    pluginId,
    pluginVersionId,
    currentVersion,
    data,
    codeSnippet,
    integratorId,
    creatorType,
    topic,
    eventIndex,
    cb
  } = action.payload;

  try {
    let updatedPluginVersionId = pluginVersionId ? { id: pluginVersionId } : null;

    // if there is an eventIndex, then we are updating an event.  We need to create new transformation versions for both the request and response transformations
    if (eventIndex >= 0) {
      // initally set the transformation ids to the existing transformation ids
      data.events[eventIndex].transformationId = transformationId;
      data.events[eventIndex].snippetId = snippetId;

      // if the request transformation has changed, then we need to create a new transformation (or update an existing one)
      if (transformationChanged) {
        const requestData = {
          "code": codeSnippet,
          "creatorId": integratorId,
          "creatorType": creatorType,
          "topic": topic,
          "transformationType": "outbound",
          "entityId": integratorId,
        }
        
        let request = null;
        if (transformationId) {  
          // this updates an existing transformation by adding a new version
          request = yield call([TransformationServices, TransformationServices.createSnippet], transformationId, requestData);
        } else {
          // this creates a new transformation (version 0.1)
          request = yield call([TransformationServices, TransformationServices.createTransformation], requestData); 
        }
        // update the event with the new transformation id
        data.events[eventIndex].transformationId = request.transformationId;
        data.events[eventIndex].snippetId = request.snippetId;
      }
    }

    let response = null;

    // if we have a pluginVersionId, then we are updating an existing version, otherwise we are creating a new version  
    if (pluginVersionId) {
      // if this plugin version is a major version, then we need to create a new version
      if (currentVersion?.name?.includes('.0')) {
        const resp = yield call([IntegrationServices, IntegrationServices.cloneIntegrationVersion], pluginId, pluginVersionId);
        response = yield call([IntegrationServices, IntegrationServices.updateExistingIntegrationVersion], pluginId, resp.pluginVersion.id, data);
        updatedPluginVersionId = response.pluginVersion.id;
      } else {
        response = yield call([IntegrationServices, IntegrationServices.updateExistingIntegrationVersion], pluginId, pluginVersionId, data);
        updatedPluginVersionId = response.pluginVersion.id
      }
    } else {
      response = yield call([IntegrationServices, IntegrationServices.createInitialIntegrationVersion], pluginId, data);
      updatedPluginVersionId = response.pluginVersion.id;
    }
    
    // we also need to get the versions of the plugin
    const versions = yield call([IntegrationServices, IntegrationServices.getIntegrationVersions], pluginId);
    yield put(updateIntegrationSuccess(response));
    yield put(getIntegrationVersionsSuccess(versions));

    toast.success("Integration Updated Successfully", {
      theme: 'colored',
      autoClose: 1500,
    });
    if (cb) cb(updatedPluginVersionId);
  } catch (error) {
    console.error('error', error);
    yield put(updateIntegrationFailure(error));
    toast.error("Integration Update Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateMerchantVariables(action) {
  const { merchantPluginId, data, cb } = action.payload;

  try {
    yield call([IntegrationServices, IntegrationServices.updateMerchantVariables], merchantPluginId, data);
    yield put(updateMerchantVariablesSuccess());
    toast.success("Merchant Variables Updated Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateMerchantVariablesFailure(error));
    toast.error("Merchant Variables Update Failed", {
      theme: 'colored',
    });
  }
}

function* doPublishIntegrationVersion(action) {
  const { pluginId, pluginVersionId, cb } = action.payload;

  try {
    yield call([IntegrationServices, IntegrationServices.publishIntegrationVersion], pluginId, pluginVersionId);
    // get all the versions of the plugin
    const response = yield call([IntegrationServices, IntegrationServices.getIntegrationVersions], pluginId);
    // then load the details of the highest major version
    const highestVersionId = response?.pluginVersions?.length > 0 ? response.pluginVersions.sort((a, b) => b.name - a.name)[0].id : null;
    const response2 = yield call([IntegrationServices, IntegrationServices.getIntegrationDetails], pluginId, highestVersionId);
    yield put(getIntegrationDetailsSuccess(response2));

    yield put(publishIntegrationVersionSuccess());
    toast.success("Integration Version Published Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb(highestVersionId);
  } catch (error) {
    console.error('error', error);
    yield put(publishIntegrationVersionFailure(error));
    toast.error("Integration Version Publish Failed", {
      theme: 'colored',
    });
  }
}

function* doDeleteIntegration(action) {
  const { pluginId, cb } = action.payload;

  try {
    yield call([IntegrationServices, IntegrationServices.deleteIntegration], pluginId);
    yield put(deleteIntegrationSuccess());

    const response = yield call([IntegrationServices, IntegrationServices.getIntegrations]);
    yield put(getIntegrationsSuccess(response));

    toast.success("Integration Deleted Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(deleteIntegrationFailure(error));
    toast.error("Integration Deletion Failed", {
      theme: 'colored',
    });
  }
}

function* fetchEventTypes() {
  try {
    const response = yield call([IntegrationServices, IntegrationServices.getEventTypes]);
    yield put(getEventTypesSuccess(response));
  } catch (error) {
    console.error('error', error);
    yield put(getEventTypesFailure(error));
  }
}

function* doConnectToIntegration(action) {
  const { data, cb } = action.payload;

  try {
    yield call([IntegrationServices, IntegrationServices.connectToIntegration], data);
    yield put(connectToIntegrationSuccess());
    toast.success("Integration Connected Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(connectToIntegrationFailure(error));
    toast.error("Integration Connection Failed", {
      theme: 'colored',
    });
  }
}

function* doDisconnectIntegration(action) {
  const { merchantId, merchantPluginId, cb } = action.payload;

  try {
    yield call([IntegrationServices, IntegrationServices.disconnectIntegration], merchantPluginId);
    // next we need to update the integrations list for tyhat merchant
    const response = yield call([IntegrationServices, IntegrationServices.getMerchantIntegrations], merchantId);

    // the response has a merchantPlugins object.  Rename it to plugins
    response.plugins = response.merchantPlugins;
    delete response.merchantPlugins;
    
    yield put(getMerchantIntegrationsSuccess(response));
    yield put(disconnectIntegrationSuccess());
    toast.success("Integration Disconnected Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(disconnectIntegrationFailure(error));
    toast.error("Integration Disconnection Failed", {
      theme: 'colored',
    });
  }
}

function* watchData() {
  yield takeEvery(getIntegrations.toString(), fetchIntegrations);
  yield takeEvery(getMerchantIntegrations.toString(), fetchMerchantIntegrations);
  yield takeEvery(getIntegrationCustomizations.toString(), fetchIntegrationCustomizations);
  yield takeEvery(getIntegrationBasicInfo.toString(), fetchIntegrationBasicInfo);
  yield takeEvery(updateIntegrationBasicInfo.toString(), doUpdateIntegrationBasicInfo);
  yield takeEvery(createIntegration.toString(), doCreateIntegration);
  yield takeEvery(getIntegrationVersions.toString(), fetchIntegrationVersions);
  yield takeEvery(getIntegrationDetails.toString(), fetchIntegrationDetails);
  yield takeEvery(getIntegrationVariables.toString(), fetchIntegrationVariables);
  yield takeEvery(getMerchantIntegrationVariables.toString(), fetchMerchantIntegrationsVariables);
  yield takeEvery(updateIntegration.toString(), doUpdateIntegration);
  yield takeEvery(updateMerchantVariables.toString(), doUpdateMerchantVariables);
  yield takeEvery(publishIntegrationVersion.toString(), doPublishIntegrationVersion);
  yield takeEvery(deleteIntegration.toString(), doDeleteIntegration);
  yield takeEvery(getEventTypes.toString(), fetchEventTypes);
  yield takeEvery(connectToIntegration.toString(), doConnectToIntegration);
  yield takeEvery(disconnectIntegration.toString(), doDisconnectIntegration);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}