import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Check, Dash } from 'react-bootstrap-icons';
import './index.scss';

const Checkbox = ({
  size = 'small',        
  checked = false,       
  indeterminate = false, 
  disabled = false,      
  onChange = null,       
  label = ''             
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [isIndeterminate, setIsIndeterminate] = useState(indeterminate);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    setIsIndeterminate(indeterminate);
  }, [indeterminate]);

  const handleCheckboxChange = () => {
    if (!disabled) {
      if (isIndeterminate) {
        setIsChecked(true);
        setIsIndeterminate(false);
      } else {
        setIsChecked(!isChecked);
      }
      onChange && onChange(!isChecked);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !disabled) {
      handleCheckboxChange();
    }
  };

  return (
    <div className="gooten-checkbox-wrapper">
      <div
        className={`gooten-checkbox size-${size} ${
          isChecked || isIndeterminate ? 'checked' : 'unchecked'
        } ${disabled ? 'disabled' : ''}`}
        tabIndex={disabled ? -1 : 0}
        onClick={handleCheckboxChange}
        onKeyDown={handleKeyDown}
      >
        {isIndeterminate ? (
          <Dash className="checkbox-icon" />
        ) : (
          isChecked && <Check className="checkbox-icon" />
        )}
      </div>
      {label && <span className="gooten-checkbox-label">{label}</span>}
    </div>
  );
};

Checkbox.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Checkbox;
