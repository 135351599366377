import { handleActions } from 'redux-actions';
import {
  getIntegrations,
  getIntegrationsSuccess,
  getIntegrationsFailure,
  getMerchantIntegrations,
  getMerchantIntegrationsSuccess,
  getMerchantIntegrationsFailure,
  getIntegrationCustomizations,
  getIntegrationCustomizationsSuccess,
  getIntegrationCustomizationsFailure,
  getIntegrationBasicInfo,
  getIntegrationBasicInfoSuccess,
  getIntegrationBasicInfoFailure,
  updateIntegrationBasicInfo,
  updateIntegrationBasicInfoSuccess,
  updateIntegrationBasicInfoFailure,
  createIntegration,
  createIntegrationSuccess,
  createIntegrationFailure,
  getIntegrationVersions,
  getIntegrationVersionsSuccess,
  getIntegrationVersionsFailure,
  getIntegrationDetails,
  getIntegrationDetailsSuccess,
  getIntegrationDetailsFailure,
  getIntegrationVariables,
  getIntegrationVariablesSuccess,
  getIntegrationVariablesFailure,
  getMerchantIntegrationVariables,
  getMerchantIntegrationVariablesSuccess,
  getMerchantIntegrationVariablesFailure,
  updateIntegration,
  updateIntegrationSuccess,
  updateIntegrationFailure,
  updateMerchantVariables,
  updateMerchantVariablesSuccess,
  updateMerchantVariablesFailure,
  publishIntegrationVersion,
  publishIntegrationVersionSuccess,
  publishIntegrationVersionFailure,
  cloneIntegrationVersion,
  cloneIntegrationVersionSuccess,
  cloneIntegrationVersionFailure,
  deleteIntegration,
  deleteIntegrationSuccess,
  deleteIntegrationFailure,
  getEventTypes,
  getEventTypesSuccess,
  getEventTypesFailure,
  disconnectIntegration,
  disconnectIntegrationSuccess,
  disconnectIntegrationFailure,
  connectToIntegration,
  connectToIntegrationSuccess,
  connectToIntegrationFailure,
  clearErrors,
  reset,
} from "app/store/actions/integration";

const initialState = {
  // main integrations listing (public)
  integrations: {
    loading: false,
    data: null,
    error: null,
  },
  // integrations for a specific merchant
  merchantIntegrations: {
    loading: false,
    data: null,
    error: null,
  },
  // specific integration details
  integration: {
    loading: false,
    data: null,
    error: null,
  },
  eventTypes: {
    loading: false,
    data: null,
    error: null,
  },
}

const integrationReducer = handleActions(
  {
    [getIntegrations]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationsSuccess]: (state, data) => ({
      ...state,
      integrations: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getIntegrationsFailure]: (state, data) => ({
      ...state,
      integrations: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [getIntegrationCustomizations]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getMerchantIntegrations]: (state) => ({
      ...state,
      merchantIntegrations: {
        ...state.merchantIntegrations,
        loading: true,
        error: null,
      },
    }),

    [getMerchantIntegrationsSuccess]: (state, data) => ({
      ...state,
      merchantIntegrations: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getMerchantIntegrationsFailure]: (state, data) => ({
      ...state,
      merchantIntegrations: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [getIntegrationCustomizationsSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getIntegrationCustomizationsFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [getIntegrationBasicInfo]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationBasicInfoSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: {
          ...state.integration.data,
          basicInfo: data.payload.pluginData,
        },
        error: null,
      },
    }),

    [getIntegrationBasicInfoFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [updateIntegrationBasicInfo]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [updateIntegrationBasicInfoSuccess]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        data: {
          ...state.integration.data,
          basicInfo: data.payload.pluginData,
        },
        loading: false,
        error: null,
      },
    }),

    [updateIntegrationBasicInfoFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [createIntegration]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: true,
        error: null,
      },
    }),

    [createIntegrationSuccess]: (state, data) => ({
      ...state,
      integrations: {
        ...state.integrations,
        data: {
          ...state.integration.data,
          details: data.payload.plugin,
        },
        loading: false,
        error: null,
      },
    }),

    [createIntegrationFailure]: (state, data) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: false,
        error: data,
      },
    }),

    [getIntegrationVersions]: (state) => ({
      ...state,
      integration: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getIntegrationVersionsSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: {
          ...state.integration.data,
          versions: data.payload.pluginVersions,
        },
        error: null,
      },
    }),

    [getIntegrationVersionsFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [getIntegrationDetails]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationDetailsSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: {
          ...state.integration.data,
          details: data.payload?.pluginVersion,
        },
        error: null,
      },
    }),

    [getIntegrationDetailsFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [getIntegrationVariables]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationVariablesSuccess]: (state, data) => ({
      ...state,
      integration: {
        data: data.payload,
        loading: false,
        error: null,
      },
    }),

    [getIntegrationVariablesFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [getMerchantIntegrationVariables]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getMerchantIntegrationVariablesSuccess]: (state, data) => ({
      ...state,
      integration: {
        data: data.payload,
        loading: false,
        error: null,
      },
    }),

    [getMerchantIntegrationVariablesFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [updateIntegration]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [updateIntegrationSuccess]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        data: {
          ...state.integration.data,
          details: data.payload.pluginVersion,
        },
        loading: false,
        error: null,
      },
    }),

    [updateIntegrationFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [updateMerchantVariables]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [updateMerchantVariablesSuccess]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: null,
      },
    }),

    [updateMerchantVariablesFailure]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
      },
    }),

    [publishIntegrationVersion]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [publishIntegrationVersionSuccess]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: null,
      },
    }),

    [publishIntegrationVersionFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [cloneIntegrationVersion]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [cloneIntegrationVersionSuccess]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: null,
      },
    }),

    [cloneIntegrationVersionFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [deleteIntegration]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: true,
        error: null,
      },
    }),

    [deleteIntegrationSuccess]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: false,
        error: null,
      },
    }),

    [deleteIntegrationFailure]: (state, data) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: false,
        error: data,
      },
    }),

    [getEventTypes]: (state) => ({
      ...state,
      eventTypes: {
        ...state.eventTypes,
        loading: true,
        error: null,
      },
    }),

    [getEventTypesSuccess]: (state, data) => ({
      ...state,
      eventTypes: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getEventTypesFailure]: (state, data) => ({
      ...state,
      eventTypes: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [disconnectIntegration]: (state) => ({
      ...state,
      merchantIntegrations: {
        ...state.merchantIntegrations,
        loading: true,
        error: null,
      },
    }),

    [disconnectIntegrationSuccess]: (state) => ({
      ...state,
      merchantIntegrations: {
        ...state.merchantIntegrations,
        loading: false,
        error: null,
      },
    }),

    [disconnectIntegrationFailure]: (state) => ({
      ...state,
      merchantIntegrations: {
        ...state.merchantIntegrations,
        loading: false,
      },
    }),

    [connectToIntegration]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [connectToIntegrationSuccess]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: null,
      },
    }),

    [connectToIntegrationFailure]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
      },
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      webhooks: {
        ...state.webhooks,
        error: null,
      },
      webhook: {
        ...state.webhook,
        error: null,
      },
      webhookTest: {
        ...state.webhookTest,
        error: null,
      },
    }),

    [reset]: () => ({ 
      ...initialState
     })
  },
  initialState,
)

export default integrationReducer;