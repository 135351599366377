import axios from "axios";
import Config from "../config";
import SetupInterceptors from './axiosInterceptors';

class TemplateServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.TemplateMS.Url
    });
    SetupInterceptors(this.api);
  }

  createTemplate = async (data) => {
    try {
      const response = await this.api.post('/v1/templates', data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating template');
    }
  }

  getTemplates = async (merchantId) => {
    try {
      const response = await this.api.get(`/v1/templates/merchant/${merchantId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching templates data from API');
    }
  }

  getTemplate = async (templateId) => {
    try {
      const response = await this.api.get(`/v1/templates/${templateId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching template data from API');
    }
  }

  deleteTemplate = async (templateId) => {
    try {
      const response = await this.api.delete(`/v1/templates/${templateId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting template');
    }
  }

  updateTemplate = async (templateId, data) => {
    try {
      const response = await this.api.put(`/v1/templates/${templateId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating template');
    }
  }

  renderTemplate = async (templateId, samplePayload) => {
    try {
      const response = await this.api.post(`/v1/render?templateId=${templateId}&orderId=template&paperFormat=A4&format=pdf`, samplePayload);
      return response.data;
    } catch (error) {
      throw new Error('Error rendering template');
    }
  }
}

export default new TemplateServices();