import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { integrationDetailsSelector } from 'app/store/selectors/integration';
import { updateIntegration } from 'app/store/actions/integration';
import { Card, Input, ButtonIcon, Button, DataPoint } from 'app/components';
import { Formik } from 'formik';
import { object, string, array, lazy } from 'yup';
import { Trash3 } from 'react-bootstrap-icons';
import './index.scss';

const VariablesView = (props) => {
  const { pluginId, pluginVersionId, currentVersion, editMode } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const integrationDetails = useSelector(integrationDetailsSelector);

  const [initialValues, setInitialValues] = useState({
    variables: integrationDetails?.details?.variables 
      ? [...Object.entries(integrationDetails?.details?.variables).map(([key, value]) => ({ key, value })), { key: '', value: '' }] 
      : [{ key: '', value: '' }],
  });

  useEffect(() => {
    setInitialValues({
      variables: integrationDetails?.details?.variables 
        ? [...Object.entries(integrationDetails?.details?.variables).map(([key, value]) => ({ key, value })), { key: '', value: '' }] 
        : [{ key: '', value: '' }],
    });
  }, [integrationDetails]);

  const handleVariableChange = (index, field, value, values, setFieldValue) => {
    const headers = values.variables;
    const lastIndex = headers.length - 1;

    const updatedHeaders = headers.map((header, idx) =>
      idx === index ? { ...header, [field]: value } : header
    );

    if (index === lastIndex && (updatedHeaders[lastIndex].key || updatedHeaders[lastIndex].value)) {
      if (updatedHeaders[lastIndex].key !== '' || updatedHeaders[lastIndex].value !== '') {
        updatedHeaders.push({ key: '', value: '' });
      }
    }

    setFieldValue('variables', updatedHeaders);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={() =>
        object().shape({
          variables: array().of(
            lazy(header =>
              object().shape({
                key: header.key || header.value ? string().required("Name is required if value is provided") : string().notRequired(),
                value: header.key || header.value ? string().required("Value is required if name is provided") : string().notRequired(),
              })
            )
          ),
        })
      }
      onSubmit={async (values, { setSubmitting }) => {
        const data = {
          variables: {
            ...values.variables.reduce((acc, { key, value }) => {
              if (key && value) {
                acc[key] = value;
              }
              return acc;
            }, {})
          }
        };

        dispatch(updateIntegration({
          pluginId,
          pluginVersionId: pluginVersionId && pluginVersionId !== '0' ? pluginVersionId : null,
          currentVersion,
          data,
          cb: (updatedPluginVersionId) => {
            navigate(`/admin/integrations/${pluginId}/${updatedPluginVersionId}`);
            setInitialValues(values); // Update initial values after save
            setSubmitting(false);
          }
        }));
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        submitCount,
        resetForm,
        dirty,
      }) => (
        <Card className={`variables-view ${editMode ? 'edit-mode' : ''}`}>
          <Card.Header>
            Variables
            {dirty && (
              <div className="action-buttons">
                <Button
                  variant="secondary"
                  size="small"
                  label="Cancel"
                  onClick={() => {
                    resetForm({ values: initialValues });
                  }}
                />
                <Button
                  variant="primary"
                  size="small"
                  label="Save Changes"
                  onClick={handleSubmit}
                />
              </div>
            )}
          </Card.Header>
          <Card.Body>
            {editMode ? (
              <form onSubmit={handleSubmit}>
                {values.variables.map((variable, index) => (
                  <div className="variable-container" key={index}>
                    <Input
                      label="Name"
                      name={variable.key}
                      value={variable.key}
                      readonly={!editMode}
                      onChange={e => handleVariableChange(index, 'key', e.target.value, values, setFieldValue)}
                      placeholder="Enter a Key"
                      errorMessage={submitCount > 0 && touched.variables?.[index]?.key && errors.variables?.[index]?.key}
                    />
                    <Input
                      label="Value"
                      name={variable.value}
                      value={variable.value}
                      readonly={!editMode}
                      onChange={e => handleVariableChange(index, 'value', e.target.value, values, setFieldValue)}
                      placeholder="Enter a Value"
                      errorMessage={submitCount > 0 && touched.variables?.[index]?.value && errors.variables?.[index]?.value}
                    />
                    {editMode && values.variables.length > 1 && index !== values.variables.length - 1 && (
                      <ButtonIcon
                        icon={<Trash3 />}
                        destructive={true}
                        onClick={() => {
                          const newHeaders = values.variables.filter((_, i) => i !== index);
                          setFieldValue('variables', newHeaders.length ? newHeaders : [{ key: '', value: '' }]);
                        }}
                      />
                    )}
                  </div>
                ))}
              </form>
            ) : (
              <div className="view-mode-container">
                {initialValues.variables.map((variable, index) => {
                  if (!variable.key && !variable.value) return null;
                  return (
                  <div className="view-mode-data" key={index}>
                    <DataPoint
                      title="Name"
                      data={variable.key}
                    />
                    <DataPoint
                      title="Value"
                      data={variable.value}
                      maskData
                    />
                  </div>
                )})}
                {initialValues.variables.length <= 1 && (
                  <div className="no-data">No Variables Added</div>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
}

export default VariablesView;
