import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { updateShippingAddress } from 'app/store/actions/order';
import { PencilSquare } from 'react-bootstrap-icons';
import { Button, ButtonIcon, Input, Dropdown, Card } from 'app/components'
import { CountryList } from 'app/constants';
import { usePermission } from 'app/permissions';
import './index.scss';

const ShippingAddress = props => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const { orderId, shippingAddress } = props;

  useEffect(() => {
    setEditMode(false);
  }, [shippingAddress]);

  // get user permissions
  const canUpdateShippingAddress = usePermission('order', 'update');

  return (
    <>
      <Card className="shipping-address-card">
        <Card.Header>
          Shipping Address
          {!editMode && canUpdateShippingAddress && (
            <ButtonIcon
              icon={<PencilSquare />}
              onClick={() => setEditMode(true)}
            />
          )}
        </Card.Header>
        <Card.Body>
          {!editMode && (
            <div>
              <div className="data-value">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</div>
              <div className="data-value">{`${shippingAddress.line1} ${shippingAddress.line2} ${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.zip}, ${shippingAddress.countryCode}`}</div>
            </div>
          )}
          {editMode && (
            <Formik
              initialValues={{
                firstName: shippingAddress.firstName || '',
                lastName: shippingAddress.lastName || '',
                address1: shippingAddress.line1 || '',
                address2: shippingAddress.line2 || '',
                city: shippingAddress.city || '',
                state: shippingAddress.state || '',
                zipPostalCode: shippingAddress.zip || '',
                country: shippingAddress.countryCode || 'US',
                phone: shippingAddress.phone || '',
                email: shippingAddress.email || '',
              }}
              validationSchema={() =>
                object().shape({
                  address1: string()
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
                    .matches(/[\x20-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
                    .max(35, 'Please limit to 35 characters')
                    .required('Enter a valid Address 1'), // required
                  address2: string().when(['address1', 'country'], (address1, country) => {
                    if (country !== 'US') {
                      if (address1) {
                        return string()
                          .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                          .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                          .max(
                            35 - address1.length,
                            'Please limit to 35 characters for Address 1 + Address 2'
                          );
                      } else {
                        return string()
                          .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                          .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                          .max(35, 'Please limit to 35 characters for Address 1 + Address 2');
                      }
                    } else {
                      return string()
                        .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                        .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                        .max(35, 'Please limit to 35 characters');
                    }
                  }),
                  city: string()
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
                    .matches(/[\x20-\x7F]+/, 'Enter a valid City') // onlyEnglish
                    .required('Enter a valid City'), // required
                  state: string()
                    .when('country', {
                      is: (country) => ['CA', 'US'].includes(country),
                      then: () => string().required('Enter a valid State/Province'),
                      otherwise: () => string()
                    })
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Province')
                    .matches(/[\x20-\x7F]+/, 'Enter a valid State/Province'),
                  zipPostalCode: string().when('country', {
                    is: country => country === 'US',
                    then: () => string()
                      .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                      .required('Enter a valid zip code'),
                    otherwise: () => string().when('country', {
                      is: country => country === 'CA',
                      then: () => string()
                        .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                        .required('Enter a valid postal code'),
                      otherwise: () => string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                    })
                  }),
                  country: string().required('Enter a Country'), // required
                })
              }
              onSubmit={async (values) => {
                const newShippingAddress = {
                  "shippingAddress": {
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "line1": values.address1,
                    "line2": values.address2,
                    "city": values.city,
                    "state": values.state,
                    "zip": values.zipPostalCode,
                    "countryCode": values.country,
                    "phone": values.phone,
                    "email": values.email,
                  }
                };

                dispatch(updateShippingAddress({ orderId, data: newShippingAddress }));
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                submitCount,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-col">
                      <Input
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        placeholder="firstName"
                        errorMessage={submitCount > 0 && errors.firstName}
                      />
                    </div>
                    <div className="form-col">
                      <Input
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        placeholder="lastName"
                        errorMessage={submitCount > 0 && errors.lastName}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-col">
                      <Input
                        label="Address Line 1"
                        name="address1"
                        value={values.address1}
                        onChange={handleChange}
                        placeholder="Address Line 1"
                        errorMessage={submitCount > 0 && errors.address1}
                      />
                    </div>
                    <div className="form-col">
                      <Input
                        label="Address Line 2"
                        name="address2"
                        value={values.address2}
                        onChange={handleChange}
                        placeholder="Address Line 2"
                        errorMessage={submitCount > 0 && errors.address2}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-col">
                      <Input
                        label="City"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        placeholder="City"
                        errorMessage={submitCount > 0 && errors.city}
                      />
                    </div>
                    <div className="form-col">
                      <Input
                        label="State / Province"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        placeholder="State / Province"
                        errorMessage={submitCount > 0 && errors.state}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-col">
                      <Input
                        label="Zip / Postal Code"
                        name="zipPostalCode"
                        value={values.zipPostalCode}
                        onChange={handleChange}
                        placeholder="Zip / Postal Code"
                        errorMessage={submitCount > 0 && errors.zipPostalCode}
                      />
                    </div>
                    <div className="form-col">
                      <Dropdown
                        label="Country"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        options={CountryList.map(country => (
                          { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}` }
                        ))}
                        errorMessage={submitCount > 0 && errors.country}
                      />
                    </div>
                  </div>
                  <div className="action-buttons">
                    <Button
                      variant="secondary"
                      size='small'
                      label='Cancel'
                      onClick={() => setEditMode(false)}
                    />
                    <Button
                      variant="primary"
                      size='small'
                      label='Update'
                      onClick={() => (isSubmitting ? null : handleSubmit())}
                    />
                  </div>
                </form>
              )}
            </Formik>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default ShippingAddress;