import {
  getHistory,
  getHistorySuccess,
  getHistoryFailure,
  getLatestHistory,
  getLatestHistorySuccess,
  getLatestHistoryFailure,
  reset,
} from "app/store/actions/history"
import { handleActions } from 'redux-actions';

const initialState = {
  // full order history (order history tab)
  fullHistory: {
    loading: false,
    data: null,
    error: null,
  },
  // latest order history (order details page history log)
  latestHistory: {
    loading: false,
    data: null,
    error: null,
  },
}

const historyReducer = handleActions(
  {
    [getHistory]: (state) => ({ 
      ...state,
      fullHistory: {
        ...state.fullHistory,
        loading: true,
        error: null,
      },
    }),

    [getHistorySuccess]: (state, data) => ({
      ...state,
      fullHistory: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getHistoryFailure]: (state, data) => ({ 
      ...state,
      fullHistory: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [getLatestHistory]: (state) => ({ 
      ...state,
      latestHistory: {
        ...state.latestHistory,
        loading: true,
        error: null,
      },
    }),

    [getLatestHistorySuccess]: (state, data) => ({
      ...state,
      latestHistory: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getLatestHistoryFailure]: (state, data) => ({ 
      ...state,
      latestHistory: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [reset]: () => ({ 
     ...initialState
    })
  },
  initialState,
)

export default historyReducer;