import { createAction } from 'redux-actions';

const prefix = "INVENTORY";

export const bulkUpload = createAction(`${prefix}/BULK_UPLOAD`);
export const bulkUploadSuccess = createAction(`${prefix}/BULK_UPLOAD_SUCCESS`);
export const bulkUploadFailure = createAction(`${prefix}/BULK_UPLOAD_FAILURE`);

export const searchInventories = createAction(`${prefix}/SEARCH_INVENTORIES`);
export const searchInventoriesSuccess = createAction(`${prefix}/SEARCH_INVENTORIES_SUCCESS`);
export const searchInventoriesFailure = createAction(`${prefix}/SEARCH_INVENTORIES_FAILURE`);

export const reset = createAction(`${prefix}/RESET`);