import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getFacilityDetails, getAllValueAddedServices, updateFacility } from 'app/store/actions/vendor';
import {
  facilityDetailsSelector,
  facilityDetailsLoadingSelector,
  facilityDetailsErrorsSelector,
  vasSelector,
  vasLoadingSelector,
  vasErrorsSelector,
} from 'app/store/selectors/vendor';
import { LoadingAnimation, Card, Button, Checkbox, MessageBar } from 'app/components';
import './index.scss';

const VasView = props => {
  const { vendorId, facilityId } = props;
  const dispatch = useDispatch();

  const loading = useSelector(facilityDetailsLoadingSelector);
  const facilityDetails = useSelector(facilityDetailsSelector);
  const facilityDetailsErrorsFound = useSelector(facilityDetailsErrorsSelector);
  const vas = useSelector(vasSelector);
  const vasLoading = useSelector(vasLoadingSelector);
  const vasErrors = useSelector(vasErrorsSelector);

  useEffect(() => {
    if (facilityId && (!facilityDetails || facilityDetails.id !== facilityId)) {
      dispatch(getFacilityDetails({ vendorId, facilityId }));
    }
    if (!vas) {
      dispatch(getAllValueAddedServices());
    }
  }, []);

  const initialValues = {};
  if (vas && Array.isArray(vas)) {
    vas.forEach(service => {
      if (service.id) {
        // we need to check to see if the facility has this vas option
        if (facilityDetails?.vasOptions?.includes(service.id)) {
          initialValues[service.id] = true;
        } else {
          initialValues[service.id] = false;
        }
      }
    });
  }

  return (
    <div className="vas-view">
      {(loading || vasLoading) && <LoadingAnimation />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          const data = { ...facilityDetails };
          // loop over every vas option and add it to the array if it is checked
          data.vasOptions = [];
          vas.forEach(service => {
            if (service.id && values[service.id]) {
              data.vasOptions.push(service.id);
            }
          });
          dispatch(updateFacility({ vendorId, facilityId, data }));
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          dirty,
        }) => (
          <Card className="facility-details-card">
            <Card.Header>
              Value Added Services
              {facilityDetails && !facilityDetailsErrorsFound && (
                <Button
                  variant="primary"
                  size="small"
                  label="Save Changes"
                  disabled={loading || vasLoading || vasErrors || !dirty}
                  onClick={handleSubmit}
                />
              )}
            </Card.Header>
            <Card.Body>
              {!loading && !vasLoading && (
                <>
                  {vas && Array.isArray(vas) && vas.length > 0 ? (
                    <div className="vas-items">
                      {vas.map(service => (
                        service.id ? (
                          <Checkbox
                            key={service.id}
                            size='medium'
                            checked={values[service.id]}
                            onChange={() => setFieldValue(service.id, !values[service.id])}
                            name={service.id}
                            label={service.type}
                          />
                        ) : null
                      ))}
                    </div>
                  ) : (
                    <MessageBar color="blue">
                      No value-added services available
                    </MessageBar>
                  )}
                  {vasErrors && (
                    <MessageBar color="yellow">
                      An error occurred while fetching data
                    </MessageBar>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        )}
      </Formik>
    </div>
  );
}

export default VasView;
