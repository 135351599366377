import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class HistoryServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.HistoryMS.Url
    });

    SetupInterceptors(this.api);
  }

  getHistory = async (parentType, parentId, entityType, page, pageSize, sortAscending) => {
    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('parentType', parentType);
    addParam('parentId', parentId);
    addParam('entityType', entityType);
    addParam('page', page);
    addParam('pageSize', pageSize);
    addParam('sortAscending', sortAscending === 'asc');

    try {
      const response = await this.api.get(`/v1/search?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };
}

export default new HistoryServices();