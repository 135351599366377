import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, LoadingAnimation, ButtonIcon } from "app/components";
import {
  ArrowLeft,
  Check2,
  ExclamationTriangle,
  Upload,
} from "react-bootstrap-icons";
import "./index.scss";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bulkUpload, reset } from "app/store/actions/inventory";
import {
  bulkUploadErrorSelector,
  bulkUploadLoadingSelector,
  bulkUploadSuccessSelector,
} from "app/store/selectors/inventory";

const InventoryBulkUploadView = () => {
  const maxFileSize = 10;
  const csvExampleUrl =
    "https://printmeeappassets.blob.core.windows.net/ordermesh/Inventory%20bulk%20update%20example.csv";
  const csvExampleName = "Inventory bulk update example.csv";
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(bulkUploadLoadingSelector);
  const success = useSelector(bulkUploadSuccessSelector);
  const error = useSelector(bulkUploadErrorSelector);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    setErrorMessage(null);
    dispatch(reset());

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const items = parseCSV(text);
        if (validate(items)) {
          const mappedArray = items.map((item) => {
            const isInfinite = item["is-infinite"] !== undefined && item["is-infinite"] !== null ?
              item["is-infinite"].toLowerCase() === "true" ?
                true :
                false :
              null;

            const status = item["status"] !== undefined && item["status"] !== null ?
              item["status"].toLowerCase() === "available" ?
                "Available" :
                "OutOfStock" :
              null;

            return {
              vendorId: item["vendor-id"],
              vendorFacilityId: item["vendor-facility-id"],
              sku: item["sku"],
              vendorSku: item["vendor-sku"],
              quantity: item["quantity"],
              status,
              isInfinite
            };
          });
          dispatch(bulkUpload({ data: mappedArray }));
          fileInputRef.current.value = "";
        } else {
          fileInputRef.current.value = "";
        }
      };
      reader.readAsText(file);
    }
  };

  const parseCSV = (csvText) => {
    const lines = csvText.trim().split("\n");
    const headers = lines[0].split(",").map((header) => header.trim());
    const result = [];
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      const values = line.split(",").map((value) => value.trim());
      const obj = {};
      headers.forEach((header, index) => {
        obj[header] = values[index] || null;
      });
      result.push(obj);
    }
    return result;
  };

  const validate = (inputArray) => {
    // validate length
    if (!inputArray.length) {
      setErrorMessage(
        "We couldn't parse any data. Please try again with another file"
      );
      return false;
    }

    // validate mandatory properties
    for (let i = 0; i < inputArray.length; i++) {
      const item = inputArray[i];
      if (
        !item["vendor-id"] ||
        !item["vendor-facility-id"] ||
        !item["sku"] ||
        !item["vendor-sku"]
      ) {
        setErrorMessage(
          `Required properties are missing. Please check the line ${i + 1
          } and try again.`
        );
        return false;
      }
    }

    // validate status value
    for (let i = 0; i < inputArray.length; i++) {
      const item = inputArray[i];
      if (
        item["status"] &&
        item.status.toLowerCase() !== "outofstock" &&
        item.status.toLowerCase() !== "available"
      ) {
        setErrorMessage(
          `Wrong status was used on line ${i + 1
          }. The following statuses are allowed: "OutOfStock" and "Available"`
        );
        return false;
      }
    }

    return true;
  };

  return (
    <div className="inventory-view">
      {loading && <LoadingAnimation />}
      <Card>
        <div className="inventory-header">
          <div className="inventory-title">
            <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(-1)} />
            Inventory Bulk Updates
          </div>
          <Button
            variant="primary"
            size="medium"
            label="Download Template CSV"
            onClick={() => {
              const link = document.createElement("a");
              link.href = csvExampleUrl;
              link.download = csvExampleName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          />
        </div>

        <div>
          {(errorMessage || error) && (
            <Alert variant="danger" className="message">
              <ExclamationTriangle />
              <div>{error ? error : errorMessage}</div>
            </Alert>
          )}
          {success && success.errors && success.errors.length > 0 && (
            <Alert variant="danger" className="message">
              <ExclamationTriangle />
              <div>
                {success.errors.map((e, index) => (
                  <div key={index}>{e}</div>
                ))}
              </div>
            </Alert>
          )}
          {success && !success.errors.length && (
            <Alert variant="success" className="message">
              <Check2 />
              <div>We successfully uploaded your file.</div>
            </Alert>
          )}
        </div>

        <div className="d-flex justify-content-center">
          <div
            className="upload-container"
            onClick={handleDivClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv"
              onChange={handleFileChange}
            />
            <Upload className="upload-icon"></Upload>
            <div className="drag-text">Drag and Drop your File to Upload</div>
            <div className="gray-text">File supported:CSV</div>
            <Button label="Choose a file" onClick={() => 0}></Button>
            <div className="gray-text">Maximum file size {maxFileSize} MB</div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default InventoryBulkUploadView;
