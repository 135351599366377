const ShipmentStatusDetails = {
  'open': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    darkModeColor: '#1DB148', // dark green
    darkModeBgColor: 'rgba(29, 177, 72, 0.30)', // light green
    darkModeBorderColor: 'rgba(29, 177, 72, 0.30)', // light green
    displayName: 'Open',
    description: 'Order and items were created and ready for next steps.'
  },
  'inproduction': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    darkModeColor: '#1DB148', // dark green
    darkModeBgColor: 'rgba(29, 177, 72, 0.30)', // light green
    darkModeBorderColor: 'rgba(29, 177, 72, 0.30)', // light green
    displayName: 'In Production',
    description: ''
  },
  'readyforfulfillment': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    darkModeColor: '#1DB148', // dark green
    darkModeBgColor: 'rgba(29, 177, 72, 0.30)', // light green
    darkModeBorderColor: 'rgba(29, 177, 72, 0.30)', // light green
    displayName: 'Ready For Fulfillment',
    description: ''
  },
  'imageerror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    darkModeColor: '#F2A1A5', // dark red
    darkModeBgColor: 'rgba(218, 30, 40, 0.30)', // light red
    darkModeBorderColor: 'rgba(218, 30, 40, 0.30)', // light red
    displayName: 'Image Error',
    description: 'Image Error is raised when the image associated with an item fails validation (similar to Image Issue).'
  },
  'fulfillmenterror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    darkModeColor: '#F2A1A5', // dark red
    darkModeBgColor: 'rgba(218, 30, 40, 0.30)', // light red
    darkModeBorderColor: 'rgba(218, 30, 40, 0.30)', // light red
    displayName: 'Fulfillment Error',
    description: ''
  },
  'canceled': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    darkModeColor: '#F2A1A5', // dark red
    darkModeBgColor: 'rgba(218, 30, 40, 0.30)', // light red
    darkModeBorderColor: 'rgba(218, 30, 40, 0.30)', // light red
    displayName: 'Canceled',
    description: 'Order, shipment, or item was canceled.'
  },
  'fulfilled': {
    color: '#0E50B3', // dark blue
    bgColor: 'rgba(25, 153, 255, 0.10)', // light blue
    borderColor: 'rgba(25, 153, 255, 0.10)', // light blue
    darkModeColor: '#9CC0F7', // dark blue
    darkModeBgColor: 'rgba(25, 153, 255, 0.30)', // light blue
    darkModeBorderColor: 'rgba(25, 153, 255, 0.30)', // light blue
    displayName: 'Fulfilled',
    description: ''
  },
};

export default ShipmentStatusDetails;
