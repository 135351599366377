import React from 'react';
import { Card } from 'app/components';
import './index.scss';

const MetaDetailsCard = (props) => {
  const { metaDetails } = props;

  // Render nothing if there are no properties
  if (!metaDetails || Object.keys(metaDetails).length === 0) {
    return null; 
  }

  return (
    <Card className="meta-details-card">
      <Card.Header>
        Meta Information
      </Card.Header>
      <Card.Body>
        {Object.entries(metaDetails).map(([key, value]) => (
          <div key={key} className="meta-property">
            <div className="data-key">{key}</div>
            <div className="data-value">{value}</div>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default MetaDetailsCard;
