import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const ToggleSwitch = ({
  size = 'small',          
  isOn = false,            
  disabled = false,        
  readOnly = false,        
  onToggle,
}) => {
  const handleToggle = () => {
    if (!disabled && !readOnly) {
      onToggle && onToggle(!isOn);
    }
  };

  return (
    <div
      className={`gooten-toggle-switch size-${size} ${isOn ? 'on' : 'off'} ${
        disabled ? 'disabled' : ''
      } ${readOnly ? 'readOnly' : ''}`}
      onClick={handleToggle}
    >
      <div className="toggle-slider" />
    </div>
  );
};

ToggleSwitch.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isOn: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default ToggleSwitch;
