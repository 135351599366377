import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  vendorDetailsSelector,
  vendorDetailsLoadingSelector,
  vendorDetailsErrorsSelector,
  facilityDetailsLoadingSelector,
  facilityDetailsErrorsSelector
} from 'app/store/selectors/vendor';
import { updateFacility, reset } from 'app/store/actions/vendor';
import { Link, StatusChip, ButtonIcon, Button, Card, Table, TableHeader, LoadingAnimation } from 'app/components';
import { ThreeDotsVertical, ExclamationCircle } from 'react-bootstrap-icons';
import { usePermission } from 'app/permissions';
import './index.scss';

const FacilitiesView = props => {
  const { vendorId } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(vendorDetailsLoadingSelector);
  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorDetailsErrorsFound = useSelector(vendorDetailsErrorsSelector);
  const facilityDetailsLoading = useSelector(facilityDetailsLoadingSelector);
  const facilityDetailsErrorsFound = useSelector(facilityDetailsErrorsSelector);

  // get user permissions
  const canManageVendor = usePermission('vendor', 'create');

  const facilityOptions = (facility) => {
    const options = [
      { value: 'Activate', label: 'Activate', onClick: () => {
        const updatedFacility = { ...facility, disabled: false };
        dispatch(updateFacility({vendorId, facilityId: facility.id, data: updatedFacility}));
      }},
      { value: 'Deactivate', label: 'Deactivate', onClick: () => {
        const updatedFacility = { ...facility, disabled: true };
        dispatch(updateFacility({vendorId, facilityId: facility.id, data: updatedFacility}));
      }},
    ];
  
    // filter out the current status from the options
    return options.filter(option => {
      if (facility.disabled) {
        return option.value === 'Activate';
      } else {
        return option.value === 'Deactivate';
      }
    });
  }

  return (
    <div className="vendor-facilities-view">
      {(loading || facilityDetailsLoading) && <LoadingAnimation />}
      <Card className="vendor-facilities-card">
        <Card.Header>
          Vendor Facilities
          {!vendorDetailsErrorsFound && !facilityDetailsErrorsFound && canManageVendor && (
            <Button
              variant="primary"
              size="small"
              label="Add Vendor Facility"
              onClick={() => {
                dispatch(reset());
                navigate(`/admin/vendors/${vendorId}/add-facility`)}
              }
            />
          )}
        </Card.Header>
        <Card.Body>
          <Table size="medium">
            <TableHeader
              options={[
                { id: 'vendorFacilityId', label: 'Vendor Facility ID' },
                { id: 'vendorFacilityName', label: 'Vendor Facility Name' },
                { id: 'state', label: 'State' },
                { id: 'country', label: 'Country' },
                { id: 'status', label: 'Status', align: 'center' },
                ...(canManageVendor ? [{ id: 'actions', label: 'Actions', align: 'center' }] : [])
              ]}
            />
            <tbody className="table-body">
              {vendorDetails?.facilities?.map(facility => (
                <tr className="details-row" key={facility.id}>
                  <td>
                    <Link
                      label={facility.id} 
                      url={`/admin/vendors/${vendorId}/facility/${facility.id}/facilities`}
                      onClick={() => {
                        dispatch(reset());
                        navigate(`/admin/vendors/${vendorId}/facility/${facility.id}/facilities`)
                      }}
                    />
                  </td>
                  <td>{facility.name || '-'}</td>
                  <td>{facility.address?.state || '-'}</td>
                  <td>{facility.address?.countryCode || '-'}</td>
                  <td className="facility-status"><StatusChip status={facility.disabled ? 'Inactive' : 'Active'} /></td>
                  {canManageVendor && <td className="facility-options"><ButtonIcon icon={<ThreeDotsVertical />} options={facilityOptions(facility)} /></td>}
                </tr>
              ))}
              {vendorDetails?.facilities?.length === 0 && (
                <tr>
                  <td colSpan="6" className="no-facility-data">No vendor facilities found</td>
                </tr>
              )}
                {vendorDetailsErrorsFound && (
                <tr>
                  <td colSpan="6" className="no-facility-data"><ExclamationCircle />Error fetching vendor facilities</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  )
}

export default FacilitiesView;