import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import './index.scss';

const MessageBar = ({
  icon = <ExclamationTriangleFill />,
  children,
  color = '',
  className = '',
  darkMode = false,
  onClick = () => {}
}) => {
  let bgColor = '';
  let borderColor = '';
  let textColor = '';

  if (darkMode) {
    switch (color) {
      case 'red':
        bgColor = 'rgba(218, 30, 40, 0.30)';
        borderColor = 'rgba(218, 30, 40, 0.30)';
        textColor = '#F2A1A5';
        break;
      case 'green':
        bgColor = 'rgba(29, 177, 72, 0.30)';
        borderColor = 'rgba(29, 177, 72, 0.30)';
        textColor = '#33D160';
        break;
      case 'yellow':
        bgColor = 'rgba(241, 194, 27, 0.35)';
        borderColor = 'rgba(241, 194, 27, 0.35)';
        textColor = '#9CC0F7';
        break;
      case 'blue':
        bgColor = 'rgba(25, 153, 255, 0.30)';
        borderColor = 'rgba(25, 153, 255, 0.30)';
        textColor = '#0E50B3';
        break;
      default: // gray
        bgColor = 'rgba(135, 135, 135, 0.50)';
        borderColor = 'rgba(135, 135, 135, 0.50)';
        textColor = '#E8E8E8';
        break;
    }
  } else {
    switch (color) {
      case 'red':
        bgColor = 'rgba(218, 30, 40, 0.10)';
        borderColor = 'rgba(218, 30, 40, 0.10)';
        textColor = '#821218';
        break;
      case 'green':
        bgColor = 'rgba(29, 177, 72, 0.10)';
        borderColor = 'rgba(29, 177, 72, 0.10)';
        textColor = '#15602B';
        break;
      case 'yellow':
        bgColor = 'rgba(241, 194, 27, 0.15)';
        borderColor = 'rgba(241, 194, 27, 0.15)';
        textColor = '#655006';
        break;
      case 'blue':
        bgColor = 'rgba(25, 153, 255, 0.10)';
        borderColor = 'rgba(25, 153, 255, 0.10)';
        textColor = '#0E50B3';
        break;
      default: // gray
        bgColor = 'rgba(135, 135, 135, 0.10)';
        borderColor = 'rgba(135, 135, 135, 0.10)';
        textColor = '#595959';
        break;
    }
  }

  return (
    <div
      className={`gooten-error-message ${className}`}
      style={{ color: textColor, backgroundColor: bgColor, border: `2px solid ${borderColor}` }}
      onClick={onClick}
    >
      {React.cloneElement(icon)}
      <span className="message">
        {children}
      </span>
    </div>
  );
};

MessageBar.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['red', 'green', 'yellow', 'blue']),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default MessageBar;
