import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import './index.scss';

const ButtonIcon = ({
  icon,
  size = 'medium',     
  onClick = null,        
  iconOffset = 0,         
  className = '',         
  disabled = false,       
  destructive = false,   
  options = null,
  darkMode = false,         
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // conditionally render the dropdown if options are provided
  if (options && options.length > 0) {
    return (
      <div
        className={`gooten-button-icon size-${size} ${className}`}
        ref={ref}
        onClick={(e) => e.stopPropagation()}
      >
        <Dropdown show={isOpen}>
          <Dropdown.Toggle
            className={`gooten-button-icon-toggle ${destructive ? 'destructive' : ''} ${darkMode ? 'dark-mode' : ''}`}
            disabled={disabled}
            onClick={toggleDropdown}
          >
            {React.cloneElement(icon, {
              className: 'button-icon',
              style: { marginTop: `${iconOffset}px` },
            })}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options.map((option) => (
              <Dropdown.Item
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                className={option.destructive ? 'destructive' : ''}
                onClick={(e) => {
                  e.stopPropagation();
                  option.onClick && option.onClick();
                  setIsOpen(false);
                }}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={`gooten-button-icon size-${size} ${className}`}>
      <button
        type="button"
        className={`gooten-button-icon-toggle ${destructive ? 'destructive' : ''} ${darkMode ? 'dark-mode' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
        disabled={disabled}
      >
        {React.cloneElement(icon, {
          className: 'button-icon',
          style: { marginTop: `${iconOffset}px` },
        })}
      </button>
    </div>
  );
};

ButtonIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  icon: PropTypes.object.isRequired,
  iconOffset: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  destructive: PropTypes.bool,
  options: PropTypes.array,
};

export default ButtonIcon;
