import React from 'react';
import './index.scss';

const ImageModal = ({ imageUrl, showCarrierLabelModal }) => {
  return (
    <div className="modal-overlay" onClick={() => showCarrierLabelModal(null)}>
      <img src={imageUrl} alt="Enlarged" />
    </div>
  );
};

export default ImageModal;