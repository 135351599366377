import { createSelector } from 'reselect';

const inventorySelector = state => state.inventory;

export const bulkUploadLoadingSelector = createSelector([inventorySelector], inventoryStore => inventoryStore.bulkUpload.loading);
export const bulkUploadSuccessSelector = createSelector([inventorySelector], inventoryStore => inventoryStore.bulkUpload.success);
export const bulkUploadErrorSelector = createSelector([inventorySelector], inventoryStore => inventoryStore.bulkUpload.error);

export const searchInventoriesDataSelector = createSelector([inventorySelector], inventoryStore => inventoryStore.search.data?.response);
export const searchInventoriesLoadingSelector = createSelector([inventorySelector], inventoryStore => inventoryStore.search.loading);
export const searchInventoriesErrorSelector = createSelector([inventorySelector], inventoryStore => inventoryStore.search.error);