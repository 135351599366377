import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getFacilityDetails } from 'app/store/actions/vendor';
import { Card, ButtonIcon, StatusChip, IconData, Button, MessageBar, LoadingAnimation, Link } from 'app/components';
import { ThreeDotsVertical, ChevronDown, ChevronLeft, Truck, XCircleFill, Person, SuitcaseLg, Telephone, Envelope, GeoAlt } from 'react-bootstrap-icons';
import { usePermission } from 'app/permissions';
import PackageCard from './PackageCard';
import './index.scss';

const ShipmentCard = props => {
  const {
    orderId,
    shipment,
    menuState,
    toggleMenu,
    showEditTrackingModal,
    showCancelShipmentModal,
    showCancelOrderItemModal,
    showCarrierLabelModal,
    showChangeVendorModal,
    showRejectShipmentModal,
    showUnassignItemsModal,
    showFacilityInfoModal,
    showChangeShipmentStatusModal,
    showTrackingInfoModal,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [facilityDetails, setFacilityDetails] = useState(null);

  // get user permissions
  const canUpdateShipments = usePermission('order', 'update');

  const shipmentOptions = () => [
    { value: 'Update Status', label: 'Update Status', onClick: () => { showChangeShipmentStatusModal(shipment) } },
    { value: 'Edit Tracking', label: 'Edit Tracking', onClick: () => { showEditTrackingModal({ shipment }) } },
    { value: 'Reroute All Items', label: 'Reroute All Items', onClick: () => showChangeVendorModal({ shipmentId: shipment.id, shipmentShortId: shipment.shortId }) },
    { value: 'Unassign All Items', label: 'Unassign All Items', onClick: () => showUnassignItemsModal({ shipmentId: shipment.id, itemIds: shipment.orderItemIDs, isAllItems: true }) },
    { value: 'Facility Information', label: 'Facility Information', onClick: () => { showFacilityInfoModal({ vendorId: shipment.vendorId }) } },
    { value: 'Reject Shipment', label: 'Reject Shipment', destructive: true, onClick: () => { showRejectShipmentModal(shipment) } },
    ...(shipment.shipmentStatus !== 'Canceled' ? [{ value: 'Cancel Shipment', label: 'Cancel Shipment', destructive: true, onClick: () => showCancelShipmentModal(shipment) }] : []),
  ];

  const numItems = shipment.packages.reduce((total, pkg) => total + pkg.orderItemIDs.length, 0);

  const facilityDetailsCallback = (facilityDetails) => {
    console.log('facilityDetails', facilityDetails);
    setFacilityDetails(facilityDetails?.facility);
  };

  const facilityDetailsErrorCallback = () => {
    setFacilityDetails('failed');
  };

  const toggleAdditionalInfo = () => {
    if (showAdditionalInfo) {
      // closing additional info.  If the failure message is showing, reset it
      if (facilityDetails === 'failed') {
        setFacilityDetails(null);
      }
    } else {
      if (shipment.vendorId && shipment.facilityId && !facilityDetails) {
        dispatch(getFacilityDetails({ vendorId: shipment.vendorId, facilityId: shipment.facilityId, cb: facilityDetailsCallback, cbError: facilityDetailsErrorCallback}));
      }
    }
    setShowAdditionalInfo(!showAdditionalInfo);
  };

  const address = facilityDetails ? [
    facilityDetails.address?.line1,
    facilityDetails.address?.line2,
    facilityDetails.address?.city,
    facilityDetails.address?.state ? `${facilityDetails.address?.state},` : '',
    facilityDetails.address?.zip,
    facilityDetails.address?.countryCode
  ].filter(Boolean).join(' ') : null;

  return (
    <Card className="shipment-card">
      <Card.Header>
        <div className={`shipment-header ${menuState[shipment.shortId] && 'collapsed'}`}>
          <div className="shipment-header-body">
            <div className="id-status">
              <div>{`Shipment ${shipment.shortId}`}</div>
              <StatusChip type="shipment" size="slim" status={shipment.shipmentStatus} darkMode={true} />
            </div>
            <div className="shipment-data-and-options">
              {shipment.vendorName && (
                <IconData
                  label={shipment.vendorName}
                  icon={<Truck />}
                  onClick={shipment.vendorId ? () => navigate("/admin/vendors/" + shipment.vendorId) : undefined}
                  darkMode={true}
                />
              )}
              {shipment.packages.length === 1 && shipment.packages[0].carrierTrackingNumber && (
                <IconData
                  label="View Tracking Info"
                  icon={<GeoAlt />}
                  onClick={() => showTrackingInfoModal(shipment.packages[0])}
                  darkMode={true}
                />
              )}
              <div className="shipment-options">
                {canUpdateShipments && (
                  <ButtonIcon
                    icon={<ThreeDotsVertical />}
                    options={shipmentOptions()}
                    darkMode={true}
                  />
                )}
                {numItems > 0 && (
                  <ButtonIcon
                    className="collapse-menu"
                    icon={menuState[shipment.shortId] ? <ChevronLeft /> : <ChevronDown />}
                    onClick={() => toggleMenu(shipment.shortId)}
                    darkMode={true}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="additional-shipment-info" onClick={toggleAdditionalInfo}>
            {shipment.orderExternalId && (
              <div className="info-field">
                <div>External Shipment ID</div>
                <div className="facility-name">{shipment.orderExternalId}</div>
              </div>
            )}
            <div className="info-field">
              <div>Facility Name</div>
              <div className="facility-name">{shipment.facilityName}</div>
              {showAdditionalInfo ? <ChevronDown /> : <ChevronLeft />}
            </div>
            <div className={`vendor-facility-stats ${showAdditionalInfo ? 'show' : 'hide'}`}>
              {facilityDetails && facilityDetails !== 'failed' && (
                <>
                  {facilityDetails.contacts?.[0]?.name && (
                    <div className="vendor-facility-contact-item">
                      <Person />
                      <span className="contact-detail">{facilityDetails.contacts[0].name}</span>
                    </div>
                  )}
                  {facilityDetails.contacts?.[0]?.role && (
                    <div className="vendor-facility-contact-item">
                      <SuitcaseLg />
                      <span className="contact-detail">{facilityDetails.contacts[0].role}</span>
                    </div>
                  )}
                  {facilityDetails.contacts?.[0]?.phone && (
                    <div className="vendor-facility-contact-item">
                      <Telephone />
                      <span className="contact-detail">{facilityDetails.contacts[0].phone}</span>
                    </div>
                  )}
                  {facilityDetails.contacts?.[0]?.email && (
                    <div className="vendor-facility-contact-item">
                      <Envelope />
                      <span className="contact-detail">{facilityDetails.contacts[0].email}</span>
                    </div>
                  )}
                  {address && (
                    <div className="vendor-facility-contact-item">
                      <GeoAlt />
                      <span className="contact-detail">{address}</span>
                    </div>
                  )}
                  {!address && !facilityDetails.contacts?.[0]?.name && !facilityDetails.contacts?.[0]?.role && !facilityDetails.contacts?.[0]?.phone && !facilityDetails.contacts?.[0]?.email && (
                    <div className="vendor-facility-contact-item">
                      No contact information available for this facility
                    </div>
                  )}
                  <div className="view-facility-link">
                    <Link
                      label="View Full Details"
                      size="small"
                      url={`/admin/vendors/${shipment.vendorId}/facility/${shipment.facilityId}/details`}
                    />
                  </div>
                </>
              )}
              {facilityDetails === 'failed' && (
                <div className="vendor-facility-contact-item">
                  Facility details failed to load
                </div>
              )}
              {facilityDetails === null && (
                <div className="vendor-data-loading">
                  <LoadingAnimation fullscreen={false} />
                </div>
              )}
            </div>
          </div>
          {shipment.shipmentStatus === 'FulfillmentError' && (
            <MessageBar color="red" darkMode icon={<XCircleFill />}>
              Shipment failed submission to the vendor
              <Button
                label="Resubmit to Vendor"
                destructive
                size="small"
                onClick={() => console.log("Not implemented yet")}
              />
            </MessageBar>
          )}
        </div>
      </Card.Header>
      {!menuState[shipment.shortId] && (
        <Card.Body>
          {shipment.packages.map((orderPackage) => (
            <PackageCard
              key={orderPackage.id}
              orderId={orderId}
              shipment={shipment}
              orderPackage={orderPackage}
              numPackages={shipment.packages.length}
              showCancelOrderItemModal={showCancelOrderItemModal}
              showCarrierLabelModal={showCarrierLabelModal}
              showChangeVendorModal={showChangeVendorModal}
              showUnassignItemsModal={showUnassignItemsModal}
            />
          ))}
          {shipment.packages.length === 0 && (
            <div>No packages found in shipment</div>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

export default ShipmentCard;