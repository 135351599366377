import React from 'react';
import { Button } from 'app/components';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PermissionDeniedImage } from 'app/assets/images/permission-denied.svg';
import './index.scss';

const PermissionDenied = () => {
  const navigate = useNavigate();

  return (
    <div className="permission-denied">
      <PermissionDeniedImage />
      <div className="title">Permission Denied</div>
      <div className="desc-line-1">If you believe this is an error, please contact the org administrator.</div>
      {/* <div className="desc-line-2">In the meantime, here are some helpful links to get you back on track.</div> */}
      <div className="action-buttons">
        {/* <Button
          className="support-page-button"
          variant="secondary"
          size="small"
          label="Support Page"
          onClick={() => { }}
        /> */}
        <Button
          className="home-button"
          variant="primary"
          size="small"
          label="Home"
          onClick={() => { navigate('/') }}
        />
      </div>
    </div>
  );
}

export default PermissionDenied;
