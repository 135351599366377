import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reset } from 'app/store/actions/notification';
import { Card, Button, TabbedMenu } from 'app/components';
import PublicIntegrations from './PublicIntegrations';
import MyIntegrations from './MyIntegrations';
import './index.scss';

const tabs = [
  {
    path: '/admin/integrations',
    label: 'Integrations',
    content: PublicIntegrations,
  },
  {
    path: 'my-integrations',
    label: 'My Integrations',
    content: MyIntegrations,
  },
];

const IntegrationsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="integrations-view">
      <Card className="integrations-header">
        Integrations
        <Button
          variant="primary"
          size="medium"
          label={"Manage Integrations"}
          onClick={() => {
            dispatch(reset());
            navigate(`/admin/integrations/manage-integrations`);
          }}
        />
      </Card>
      <TabbedMenu tabs={tabs} />
    </div>
  )
}

export default IntegrationsView;