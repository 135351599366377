import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  merchantsSelector,
  merchantsLoadingSelector,
  merchantDetailsSelector,
  merchantDetailsLoadingSelector,
  merchantDetailsErrorsSelector
} from 'app/store/selectors/merchant';
import { getMerchantDetails, getMerchants } from 'app/store/actions/merchant';
import { LoadingAnimation, MessageBar } from 'app/components';
import MerchantCard from './MerchantCard';
import AssociationsCard from './AssociationsCard';
import './index.scss';

const DetailsView = props => {
  const { merchantId } = props;

  const dispatch = useDispatch();
  const loading = useSelector(merchantDetailsLoadingSelector);
  const merchantDetails = useSelector(merchantDetailsSelector);
  const merchantDetailsErrorsFound = useSelector(merchantDetailsErrorsSelector);
  const allMerchantsLoading = useSelector(merchantsLoadingSelector);
  const allMerchants = useSelector(merchantsSelector);

  useEffect(() => {
    if (merchantId && (!merchantDetails || merchantDetails.id !== merchantId)) {
      dispatch(getMerchantDetails(merchantId));
    }
  }, [merchantId, merchantDetails?.id, dispatch]);

  const getMerchantList = () => {
    if (!allMerchants) {
      dispatch(getMerchants({ currentPage: 1, pageSize: 250 }));
    }
  }

  return (
    <div className="merchant-details-view">
      {(loading || allMerchantsLoading) && <LoadingAnimation />}
      {!merchantId && (
        <MerchantCard merchantDetails={merchantDetails} />
      )}
      {merchantDetails && (
        <div className="merchant-details-view-cards">
          <div className="left-col">
            <AssociationsCard
              merchantDetails={merchantDetails}
              allMerchants={allMerchants}
              getMerchantList={getMerchantList}
            />
          </div>
          <div className="right-col">
            <MerchantCard merchantDetails={merchantDetails} />
          </div>
        </div>
      )}
      {merchantDetailsErrorsFound && (
        <MessageBar color="yellow">
          An error occurred while fetching data
        </MessageBar>
      )}
    </div>
  )
}

export default DetailsView;