import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { Globe, ArrowLeftRight } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Modal, Dropdown, Link, LoadingAnimation, MessageBar } from 'app/components';
import { getMerchants } from 'app/store/actions/merchant';
import { getVendors } from 'app/store/actions/vendor';
import { setViewAs } from 'app/store/actions/user';
import { merchantsSelector, merchantsLoadingSelector, merchantsErrorsSelector } from 'app/store/selectors/merchant';
import { vendorsDataSelector, vendorsLoadingSelector, vendorsErrorsSelector } from 'app/store/selectors/vendor';
import { currentUserSelector, viewAsSelector } from 'app/store/selectors/user';
import { Formik } from 'formik';
import { object, string } from 'yup';
import './index.scss';

const ViewAsMenu = () => {
  const dispatch = useDispatch();
  const [showViewAsMenu, setShowViewAsMenu] = useState(false);
  const [entities, setEntities] = useState([]);

  const merchantsData = useSelector(merchantsSelector);
  const merchantsLoading = useSelector(merchantsLoadingSelector);
  const merchantsErrorsFound = useSelector(merchantsErrorsSelector);
  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);
  const vendorsErrorsFound = useSelector(vendorsErrorsSelector);
  const currentUser = useSelector(currentUserSelector);
  const viewAs = useSelector(viewAsSelector);

  useEffect(() => {
    if (showViewAsMenu) {
      if (!merchantsData) {
        dispatch(getMerchants({ currentPage: 1, pageSize: 250 }));
      }
      if (!vendorsData) {
        dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
      }
    }
  }, [showViewAsMenu]);

  useEffect(() => {
    if (showViewAsMenu && vendorsData && merchantsData) {
      const vendorsFormatted = vendorsData.vendors ? vendorsData.vendors.map(vendor => (
        { value: `v-${vendor.id}`, label: vendor.name }
      )) : [];
  
      const merchantsFormatted = merchantsData.merchants ? merchantsData.merchants.map(merchant => (
        { value: `m-${merchant.id}`, label: merchant.name }
      )) : [];
  
      // combine merchants and vendors and sort them
      const combinedEntities = [...merchantsFormatted, ...vendorsFormatted].sort((a, b) => a.label.localeCompare(b.label));
      setEntities(combinedEntities);
    }
  }, [showViewAsMenu, vendorsData, merchantsData]);

  return (
    <div className="view-as-menu">
      <div
        className="view-as-menu-content"
        onClick={() => setShowViewAsMenu(!showViewAsMenu)}
      >
        <div className="view-as-menu-icon">
          <Globe />
        </div>
        <div className="view-as-menu-text">
          <div className="view-as-menu-title">Viewing As</div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 1000, hide: 300 }}
            overlay={<Tooltip id="tooltip-top">{viewAs?.name || `${currentUser?.firstName} ${currentUser?.lastName}`}</Tooltip>}
          >
            <div className="view-as-menu-user">{viewAs?.name || `${currentUser?.firstName} ${currentUser?.lastName}`}</div>
          </OverlayTrigger>
        </div>
        <div className="arrow-icon">
          <ArrowLeftRight />
        </div>
      </div>
      {showViewAsMenu &&
        createPortal(
          <Formik
            initialValues={{
              entityName: ''
            }}
            validationSchema={() =>
              object().shape({
                entityName: string().required('Entity name is required')
              })
            }
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              // we need to splice off the first 2 characters to determine if it's a merchant or vendor
              const entityType = values.entityName.slice(0, 2);
              const entityId = values.entityName.slice(2);
              // if it's a merchant
              let entity = null;
              if (entityType === 'm-') {
                entity = merchantsData.merchants.find(merchant => merchant.id === entityId);
                entity.userType = 'merchant';
              } else if (entityType === 'v-') {
                entity = vendorsData.vendors.find(vendor => vendor.id === entityId);
                entity.userType = 'vendor';
              }

              dispatch(setViewAs({data: entity}));
              setShowViewAsMenu(false);
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal
                  className="view-as-modal"
                  secondaryButtonLabel="Cancel"
                  secondaryButtonOnClick={() => {
                    setShowViewAsMenu(false);
                  }}
                  primaryButtonLabel="Apply"
                  primaryButtonVariant="primary"
                  primaryButtonOnClick={handleSubmit}
                  primaryButtonEnabled={!(vendorsErrorsFound || merchantsErrorsFound) && !merchantsLoading && !vendorsLoading}
                  onClose={() => setShowViewAsMenu(null)}
                  isOpen={showViewAsMenu}
                >
                  {vendorsErrorsFound || merchantsErrorsFound ? (
                    <MessageBar color="yellow">
                      An error occurred while fetching data
                    </MessageBar>
                  ) : (
                    <>
                      <div className="view-as-modal-header">
                        <div className="header-title">View As</div>
                        {!(merchantsLoading || vendorsLoading) && viewAs && (
                          <div className="header-action">
                            <Link
                              label="Clear"
                              onClick={() => {
                                dispatch(setViewAs({data: null}));
                                setShowViewAsMenu(false);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {(merchantsLoading || vendorsLoading) ? (
                        <LoadingAnimation fullscreen={false} />
                      ) : (
                        <Dropdown
                          name="entityName"
                          value={values.entityName}
                          onChange={handleChange}
                          options={entities}
                          showErrorMessages={false}
                          searchable
                        />
                      )}
                    </>
                  )}
                </Modal>
              </form>
            )}
          </Formik>,
          document.body
        )}
    </div>
  );
};

export default ViewAsMenu;
