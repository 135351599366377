import { createAction } from 'redux-actions';

const prefix = "ROUTING";

export const getStrategiesForMerchant = createAction(`${prefix}/GET_STRATEGIES_FOR_MERCHANT`);
export const getStrategiesForMerchantSuccess = createAction(`${prefix}/GET_STRATEGIES_FOR_MERCHANT_SUCCESS`);
export const getStrategiesForMerchantFailure = createAction(`${prefix}/GET_STRATEGIES_FOR_MERCHANT_FAILURE`);

export const getStrategyInfo = createAction(`${prefix}/GET_STRATEGY_INFO`);
export const getStrategyInfoSuccess = createAction(`${prefix}/GET_STRATEGY_INFO_SUCCESS`);
export const getStrategyInfoFailure = createAction(`${prefix}/GET_STRATEGY_INFO_FAILURE`);

export const createRoutingStrategy = createAction(`${prefix}/CREATE_ROUTING_STRATEGY`);
export const createRoutingStrategySuccess = createAction(`${prefix}/CREATE_ROUTING_STRATEGY_SUCCESS`);
export const createRoutingStrategyFailure = createAction(`${prefix}/CREATE_ROUTING_STRATEGY_FAILURE`);

export const updateRoutingStrategy = createAction(`${prefix}/UPDATE_ROUTING_STRATEGY`);
export const updateRoutingStrategySuccess = createAction(`${prefix}/UPDATE_ROUTING_STRATEGY_SUCCESS`);
export const updateRoutingStrategyFailure = createAction(`${prefix}/UPDATE_ROUTING_STRATEGY_FAILURE`);

export const deleteRoutingStrategy = createAction(`${prefix}/DELETE_ROUTING_STRATEGY`);
export const deleteRoutingStrategySuccess = createAction(`${prefix}/DELETE_ROUTING_STRATEGY_SUCCESS`);
export const deleteRoutingStrategyFailure = createAction(`${prefix}/DELETE_ROUTING_STRATEGY_FAILURE`);

export const activateStrategy = createAction(`${prefix}/ACTIVATE_ROUTING_STRATEGY`);
export const activateStrategySuccess = createAction(`${prefix}/ACTIVATE_ROUTING_STRATEGY_SUCCESS`);
export const activateStrategyFailure = createAction(`${prefix}/ACTIVATE_ROUTING_STRATEGY_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);