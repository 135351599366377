import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { GraphUpArrow, GraphDownArrow, Dash } from 'react-bootstrap-icons';
import './index.scss';

const StatusCard = ({
  label,
  icon,
  iconColor = 'green',
  value,
  percent = null, 
  percentColor = 'green'
}) => {
  const navigate = useNavigate();

  const getPercentIcon = (percent) => {
    if (percent > 0) {
      return <GraphUpArrow />;
    } else if (percent < 0) {
      return <GraphDownArrow />;
    } else {
      return <Dash />;
    }
  };

  return (
    <div className="status-card" onClick={() => navigate(`/orders?status=${label}`)}>
      <div className="status-card-top">
        <div className={`status-card-icon color-${iconColor}`}>
          {React.cloneElement(icon)}
        </div>
        <div className="status-card-label">{label}</div>
      </div>
      <div className="status-card-bottom">
        <div className="status-card-value">{value}</div>
        {percent && (
          <div className={`status-card-percent color-${percentColor}`}>
            {`${percent}%`}
            {getPercentIcon(Number(percent))}
          </div>
        )}
      </div>
    </div>
  );
};

StatusCard.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  iconColor: PropTypes.oneOf(['green', 'red', 'yellow']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentColor: PropTypes.oneOf(['green', 'red', 'yellow']),
};

export default StatusCard;
