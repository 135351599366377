import React from 'react';
import { Image } from 'app/components'
import { PlusCircle, DashCircle } from 'react-bootstrap-icons';
import './index.scss';

const ProductCard = props => {
  const { productInfo, onProductSelected, isSelected } = props;

  return (
    <div
      className={`product-card ${productInfo.selected ? 'selected' : ''}`}
      onClick={() => onProductSelected(productInfo.id)}
    >
      <div className="item-image">
        <Image key={productInfo?.images?.[0]} src={productInfo?.images?.[0]} />
        {isSelected ? <DashCircle className="red-fill" /> : <PlusCircle className="green-fill" />}
      </div>
      <div className="item-data">
        <div className="item-sku">{productInfo.title}</div>
      </div>
    </div>
  )
}

export default ProductCard;