import { createAction } from 'redux-actions';

const prefix = "INSIGHT";

export const getAllQueries = createAction(`${prefix}/GET_ALL_QUERIES`);
export const getAllQueriesSuccess = createAction(`${prefix}/GET_ALL_QUERIES_SUCCESS`);
export const getAllQueriesFailure = createAction(`${prefix}/GET_ALL_QUERIES_FAILURE`);

export const runQueryById = createAction(`${prefix}/RUN_QUERY_BY_ID`);
export const runQueryByIdSuccess = createAction(`${prefix}/RUN_QUERY_BY_ID_SUCCESS`);
export const runQueryByIdFailure = createAction(`${prefix}/RUN_QUERY_BY_ID_FAILURE`);

export const reset = createAction(`${prefix}/RESET`);