import React, { useState, useEffect, useRef } from 'react';
import { Form, Dropdown, InputGroup, Spinner } from 'react-bootstrap';
import { globalOrdersSearch, globalOrdersSearchClear } from 'app/store/actions/order';
import { globalOrderSearchDataSelector, globalOrderSearchLoadingSelector } from 'app/store/selectors/order';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from 'react-bootstrap-icons';
import './index.scss';

const OrderSearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchResults = useSelector(globalOrderSearchDataSelector);
  const searchResultsLoading = useSelector(globalOrderSearchLoadingSelector);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (searchTerm.length < 2) {
      clearResults();
      setDebouncedValue(searchTerm);
      setIsDropdownOpen(false);
    } else {
      const timer = setTimeout(() => {
        setDebouncedValue(searchTerm);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedValue.length > 1) {
      dispatch(globalOrdersSearch({ id: debouncedValue, page: 1, pageSize: 5, cb: onSearchResults }));
      setIsDropdownOpen(true); 
    }
  }, [debouncedValue]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        clearResults();
        setSearchTerm('');
        setIsDropdownOpen(false);
      }
    }

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const onSearchResults = (resp) => {
    if (resp.orders.length === 1 && (resp.orders[0].shortId.toLowerCase() === searchTerm.toLowerCase() || resp.orders[0].id.toLowerCase() === searchTerm.toLowerCase())) {
      navigate(`/orders/${resp.orders[0].id}`);
      clearResults();
      setSearchTerm('');
      setIsDropdownOpen(false);
    }
  }

  const clearResults = () => {
    dispatch(globalOrdersSearchClear());
    setIsDropdownOpen(false);
  }

  return (
    <div className="order-search-bar" ref={wrapperRef}>
      <InputGroup>
        <InputGroup.Text>
          <Search />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Search for Order ID"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {searchResultsLoading && (
          <InputGroup.Text>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </InputGroup.Text>
        )}
      </InputGroup>
      {searchResults?.orders && isDropdownOpen && (
        <Dropdown.Menu show>
          {searchResults?.orders?.length > 0 ? searchResults.orders.map((result, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                navigate(`/orders/${result.id}`);
                clearResults();
                setSearchTerm('');
              }}
            >
              {result.shortId}
            </Dropdown.Item>
          )) : (
            <Dropdown.Item>
              No results found
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      )}
    </div>
  );
};

export default OrderSearchBar;
