import { Table, TableHeader, Card, ButtonIcon, Dropdown, Input } from 'app/components'
import { ArrowUp, ArrowDown, PlusLg, Trash3 } from 'react-bootstrap-icons';
import Vendor from './Vendor';
import './index.scss';

const Group = props => {
  const {
    blockIndex,
    groupIndex,
    values,
    data,
    vendorsData,
    editMode,
    errors,
    handleChange,
    submitCount,
    setFieldValue,
    validateForm
  } = props;

  const addGroup = () => {
    const newGroup = {
      groupName: '',
      orderingAlgorithm: '',
      tiebreakerOrderingAlgorithm: '',
      vendors: [{
        vendorId: '',
        facilityId: '',
        rank: '0',
      }]
    };
  
    const groupsBeforeIndex = values.blocks[blockIndex].groups.slice(0, groupIndex + 1);
    const groupsAfterIndex = values.blocks[blockIndex].groups.slice(groupIndex + 1);
    const updatedGroups = [...groupsBeforeIndex, newGroup, ...groupsAfterIndex];
    setFieldValue(`blocks[${blockIndex}].groups`, updatedGroups);
  }

  const removeGroup = () => {
    const updatedGroups = values.blocks[blockIndex].groups.filter((_, index) => index !== groupIndex);
    setFieldValue(`blocks[${blockIndex}].groups`, updatedGroups);
  };

  const promoteGroup = () => {
    if (groupIndex === 0) return; 

    const updatedGroups = [...values.blocks[blockIndex].groups];
    const temp = updatedGroups[groupIndex - 1];
    updatedGroups[groupIndex - 1] = updatedGroups[groupIndex];
    updatedGroups[groupIndex] = temp;
    setFieldValue(`blocks[${blockIndex}].groups`, updatedGroups);
  };

  const demoteGroup = () => {
    if (groupIndex === values.blocks[blockIndex].groups.length - 1) return; 

    const updatedGroups = [...values.blocks[blockIndex].groups];
    const temp = updatedGroups[groupIndex + 1];
    updatedGroups[groupIndex + 1] = updatedGroups[groupIndex];
    updatedGroups[groupIndex] = temp;
    setFieldValue(`blocks[${blockIndex}].groups`, updatedGroups);
  };

  const orderingAlgorithms = [{ "Name": "Distance", "Id": "Distance" }, { "Name": "Cost", "Id": "Cost" }, { "Name": "Ranked", "Id": "Ranked" }, { "Name": "Random", "Id": "Random" }];

  const filteredVendors = vendorsData.vendors
    .filter(obj => obj.name && obj.name.trim() !== "" && obj.facilities.length > 0)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Card className={`group-component ${editMode ? 'edit-mode' : ''}`}>
      <Card.Header className={`group-component-header ${editMode ? 'edit-mode' : ''}`}>
        {editMode ? (
          <>
            <Input
              label="Group Name"
              className="group-name-input"
              name={`blocks.${blockIndex}.groups.${groupIndex}.groupName`}
              value={data.groupName}
              readonly={!editMode}
              onChange={handleChange}
              placeholder="Group Name"
              errorMessage={submitCount > 0 && errors?.blocks?.[blockIndex]?.groups?.[groupIndex]?.groupName}
            />
            <div className="section-options">
              {groupIndex > 0 && (
                <ButtonIcon
                  icon={<ArrowUp />}
                  onClick={() => promoteGroup(groupIndex)}
                />
              )}
              {values.blocks[blockIndex].groups.length > 0 && values.blocks[blockIndex].groups.length !== groupIndex + 1 && (
                <ButtonIcon
                  icon={<ArrowDown />}
                  onClick={() => demoteGroup(groupIndex)}
                />
              )}
              <ButtonIcon
                icon={<PlusLg />}
                onClick={() => addGroup(groupIndex)}
              />
              {values.blocks[blockIndex].groups.length > 1 && (
                <ButtonIcon
                  icon={<Trash3 />}
                  destructive={true}
                  onClick={() => removeGroup(groupIndex)}
                />
              )}
            </div>
          </>
        ) : (
          <div>
            <div className="group-name">{values.blocks[blockIndex]?.groups?.[groupIndex]?.groupName}</div>
            <div className="sorting-mechanisms">
              <div>Sorting Mechanism 1: {values.blocks[blockIndex]?.groups?.[groupIndex]?.orderingAlgorithm}</div>
              {values.blocks[blockIndex]?.groups?.[groupIndex]?.tiebreakerOrderingAlgorithm && (
                <div>Sorting Mechanism 2: {values.blocks[blockIndex]?.groups?.[groupIndex]?.tiebreakerOrderingAlgorithm}</div>
              )}
            </div>
          </div>
        )}
      </Card.Header>
      <div className="group-body">
        {editMode && (
          <div className="top-inputs-container">
            <Dropdown
              label={data.orderingAlgorithm !== 'Random' ? 'Sorting Mechanism 1' : 'Sorting Mechanism'}
              name={`blocks.${blockIndex}.groups.${groupIndex}.orderingAlgorithm`}
              value={data.orderingAlgorithm}
              readonly={!editMode}
              onChange={handleChange}
              options={orderingAlgorithms.map(orderingAlgorithm => (
                { value: orderingAlgorithm.Id, label: orderingAlgorithm.Name }
              ))}
              errorMessage={submitCount > 0 && errors?.blocks?.[blockIndex]?.groups?.[groupIndex]?.orderingAlgorithm}
            />
            {data.orderingAlgorithm !== 'Random' && (
              <Dropdown
                label="Sorting Mechanism 2"
                name={`blocks.${blockIndex}.groups.${groupIndex}.tiebreakerOrderingAlgorithm`}
                value={data.tiebreakerOrderingAlgorithm}
                readonly={!editMode}
                disabled={data.orderingAlgorithm === ''}
                tooltip="<div>If there is a tie between two vendors</div><div>this will be used to break the tie.</div>"
                onChange={handleChange}
                options={orderingAlgorithms
                  .filter(orderingAlgorithm => orderingAlgorithm.Id !== data.orderingAlgorithm || orderingAlgorithm.Id === '')
                  .map(orderingAlgorithm => (
                  { value: orderingAlgorithm.Id, label: orderingAlgorithm.Name }
                ))}
                allowClear={true}
                errorMessage={submitCount > 0 && errors?.blocks?.[blockIndex]?.groups?.[groupIndex]?.tiebreakerOrderingAlgorithm}
              />
            )}
          </div>
        )}
        <div className="vendor-container">
          {editMode && data.vendors.sort((a, b) => a.rank - b.rank).map((vendor, vendorIndex) => (
            <Vendor
              key={vendorIndex}
              blockIndex={blockIndex}
              groupIndex={groupIndex}
              vendorIndex={vendorIndex}
              vendorCount={data.vendors.length}
              data={vendor}
              filteredVendors={filteredVendors}
              values={values}
              editMode={editMode}
              errors={errors}
              handleChange={handleChange}
              submitCount={submitCount}
              setFieldValue={setFieldValue}
              validateForm={validateForm}
            />
          ))}
          {!editMode && (
            <Table size="medium" highlightRows={false}>
               <TableHeader
                  options={[
                    { id: 'vendorName', label: 'Vendor Name', width: '33.3%' },
                    { id: 'facilityName', label: 'Facility Name' },
                    ...(data.orderingAlgorithm === 'Ranked' || data.tiebreakerOrderingAlgorithm === 'Ranked' ? [{ id: 'rank', label: 'Rank' }] : [])
                  ]}
                />
                <tbody className="table-body">
                  {data.vendors.sort((a, b) => a.rank - b.rank).map((vendor) => {
                    const selectedVendor = filteredVendors.find(v => v.id === vendor.vendorId);
                    // locate the facility name
                    let selectedFacility = '';
                    if (selectedVendor && selectedVendor.facilities) {
                      selectedFacility = selectedVendor.facilities.find(f => f.id === vendor.facilityId);
                      if (selectedFacility) {
                        vendor.facilityName = selectedFacility.name;
                      }
                    }
                    return (
                      <tr key={`${vendor.vendorId}-${vendor.facilityId}`}>
                        <td>{selectedVendor?.name || selectedVendor?.legalName}</td>
                        <td>{selectedFacility?.name}</td>
                        {(data.orderingAlgorithm === 'Ranked' || data.tiebreakerOrderingAlgorithm === 'Ranked') && <td>{vendor?.rank}</td>}
                      </tr>
                    )
                  })}
                </tbody>
            </Table>
          )}
        </div>
      </div>
    </Card>
  )
}

export default Group;