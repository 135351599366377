import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';

const TooltipIcon = ({ htmlContent, icon: IconComponent = QuestionCircleFill, placement = 'top', color = '#595959' }) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-${placement}`} style={{ maxWidth: 400 }}>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Tooltip>
      }
    >
      <IconComponent style={{ color: color }} />
    </OverlayTrigger>
  );
};

export default TooltipIcon;
