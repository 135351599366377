import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PermissionDenied, PageNotFound } from 'app/components';
import UsersView from './UsersView';
import UserDetails from './UserDetails';
import RolesView from './RolesView';
import RoleDetails from './RoleDetails';
import { usePermission } from 'app/permissions';
import UserProfile from './UserProfile';

function Users() {
  // get user permissions
  const canCreateUsers = usePermission('users', 'users_create');
  const canManageRoles = usePermission('users', 'roles_create');

  return (
    <Routes>
      <Route path="/" element={<UsersView />} />
      <Route path="/create-user/*" element={canCreateUsers ? <UserDetails /> : <PermissionDenied />} />
      <Route path="/roles" element={canManageRoles ? <RolesView /> : <PermissionDenied />} />
      <Route path="/roles/create-role/*" element={canManageRoles ? <RoleDetails /> : <PermissionDenied />} />
      <Route path="/roles/:roleId/*" element={<RoleDetails />} />
      <Route path="/:userId/*" element={<UserDetails />} />
      <Route path="/profile" element={<UserProfile />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Users;
