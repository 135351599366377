import * as XLSX from 'xlsx';

export const convertJsonDataToApiFormat = (jsonData, vendorDetails) => {
  const data = {
    bulk: [],
  };

  const variantMap = new Map();
  const expectedFields = ["Variant Id", "SKU", "Vendor SKU", "Facility IDs", "Description", "Production Time (in days)", "Product Cost", "Currency"];
  const decorationPrefix = ["Area_", "DPI_", "Height_", "Width_", "PrintMethod_", "ImageFormat_", "DecorationCost_", "VendorArea_"];

  // loop through each tab in the excel file
  // eslint-disable-next-line no-unused-vars
  Object.entries(jsonData).forEach(([_prop, variants]) => {
    const headers = variants[0];

    // if there are no headers for this variant, skip it
    if (!headers) {
      return;
    }

    // determine additional decoration fields dynamically
    const additionalFields = headers.filter(header =>
      !expectedFields.includes(header) &&
      !decorationPrefix.some(prefix => header.startsWith(prefix))
    ).map(header => header.replace(/_\d+$/, '')).filter((value, index, self) => self.indexOf(value) === index);

    // loop through each variant found on this tab
    for (let i = 1; i < variants.length; i++) {
      const variant = variants[i];

      // if someone deleted a row from the excel file, it still may be part of the array (but with empty values), just ignore it
      if (!variant[headers.indexOf("Variant Id")]) {
        continue;
      }

      const productId = String(variant[headers.indexOf("Product Id")] ?? '');
      const variantId = String(variant[headers.indexOf("Variant Id")] ?? '');
      const sku = String(variant[headers.indexOf("SKU")] ?? '');
      const vendorId = String(vendorDetails.vendorId ?? '');
      const key = `${variantId}-${sku}-${vendorId}`;

      if (!variantMap.has(key)) {
        variantMap.set(key, {
          id: productId,
          variantId,
          sku,
          vendorId,
          facilities: []
        });
      }

      const facilitiesField = String(variant[headers.indexOf("Facility IDs")]);
      const facilityIds = facilitiesField === "All" ? vendorDetails.facilities.map(f => f.id) : facilitiesField.split(',').map(id => id.trim());

      // now loop through each facility this vendor owns and add the product to it
      facilityIds.forEach(facilityId => {
        const facility = {
          id: String(facilityId ?? ''),
          vendorSKU: String(variant[headers.indexOf("Vendor SKU")] ?? ''),
          vendorSKUDescription: String(variant[headers.indexOf("Description")] ?? ''),
          outOfStock: false,
          productionTimeDays: variant[headers.indexOf("Production Time (in days)")] || null,
          productCost: {
            amount: variant[headers.indexOf("Product Cost")] || null,
            currency: variant[headers.indexOf("Currency")] || null,
          },
          ...(headers.indexOf("Status") !== -1 && variant[headers.indexOf("Status")] !== undefined ? { status: variant[headers.indexOf("Status")] } : {}),
          decorations: []
        };

        // let's loop through all the images (there could be more than 1)
        let currentImage = 1;

        // eslint-disable-next-line no-constant-condition
        while (true) {
          // get the index of the next image area (if it's -1 then we know there are no more images to process)
          let index = headers.indexOf("Area_" + currentImage);
          // did we find the next image area and is there an image there (just because there is an image header we could have no image)
          if (index >= 0 && variant[index]) {
            // there is an image here, add it
            const decoration = {
              imageSpecs: {},
              attributes: {},
            };

            // add width if it exists and it has a value
            if (headers.indexOf("Width_" + currentImage) >= 0 && variant[headers.indexOf("Width_" + currentImage)]) {
              decoration.imageSpecs.width = String(variant[headers.indexOf("Width_" + currentImage)] ?? '');
            }

            // add height if it exists and it has a value
            if (headers.indexOf("Height_" + currentImage) >= 0 && variant[headers.indexOf("Height_" + currentImage)]) {
              decoration.imageSpecs.height = String(variant[headers.indexOf("Height_" + currentImage)] ?? '');
            }

            // add dpi if it exists and it has a value
            if (headers.indexOf("DPI_" + currentImage) >= 0 && variant[headers.indexOf("DPI_" + currentImage)]) {
              decoration.imageSpecs.dpi = String(variant[headers.indexOf("DPI_" + currentImage)] ?? '');
            }

            // add area if it exists and it has a value
            if (headers.indexOf("Area_" + currentImage) >= 0 && variant[headers.indexOf("Area_" + currentImage)]) {
              decoration.area = String(variant[headers.indexOf("Area_" + currentImage)] ?? '');
            }

            // add vendor area if it exists and it has a value
            if (headers.indexOf("VendorArea_" + currentImage) >= 0 && variant[headers.indexOf("VendorArea_" + currentImage)]) {
              decoration.vendorArea = String(variant[headers.indexOf("VendorArea_" + currentImage)] ?? '');
            }

            // add print method if it exists and it has a value
            if (headers.indexOf("PrintMethod_" + currentImage) >= 0 && variant[headers.indexOf("PrintMethod_" + currentImage)]) {
              decoration.printMethod = String(variant[headers.indexOf("PrintMethod_" + currentImage)] ?? '');
            }

            // add image format if it exists and it has a value
            if (headers.indexOf("ImageFormat_" + currentImage) >= 0 && variant[headers.indexOf("ImageFormat_" + currentImage)]) {
              decoration.imageSpecs.format = String(variant[headers.indexOf("ImageFormat_" + currentImage)] ?? '');
            }

            // add the decoration cost if it exists and it has a value
            if (headers.indexOf("DecorationCost_" + currentImage) >= 0 && variant[headers.indexOf("DecorationCost_" + currentImage)]) {
              decoration.decorationCost = {
                amount: variant[headers.indexOf("DecorationCost_" + currentImage)] || null,
                currency: String(variant[headers.indexOf("Currency")] ?? null),
              };
            }

            // add additional decoration fields dynamically
            additionalFields.forEach(field => {
              const fieldIndex = headers.indexOf(field + "_" + currentImage);
              if (fieldIndex >= 0 && variant[fieldIndex]) {
                decoration.attributes[field] = String(variant[fieldIndex] ?? '');
              }
            });

            facility.decorations.push(decoration);
            currentImage++;
          } else {
            // no more images, end the loop
            break;
          }
        }

        // check for an existing facility with the same id
        const existingVariant = variantMap.get(key);
        const existingFacilityIndex = existingVariant.facilities.findIndex(fac => fac.id === facilityId);

        if (existingFacilityIndex !== -1) {
          // replace the existing facility data
          existingVariant.facilities[existingFacilityIndex] = facility;
        } else {
          // add the new facility data
          existingVariant.facilities.push(facility);
        }
      });
    }
  });

  // ddd all variants to data.bulk
  variantMap.forEach(variantObj => data.bulk.push(variantObj));

  return data;
};

export const downloadAllProductsXLSX = (data) => {
  // create a workbook to store all the data
  const workbook = XLSX.utils.book_new();

  // flatten the data to ensure each facility is represented as a separate row
  const flattenedData = [];
  data.forEach(product => {
    product.facilities.forEach(facility => {
      const baseProductInfo = {
        "Product Id": product.id,
        "Variant Id": product.variantId,
        "SKU": product.sku,
        "Vendor SKU": facility.vendorSku,
        "Facility IDs": facility.id,
        "Description": facility.vendorSkuDescription,
        "Status": facility.status,
        "Production Time (in days)": facility.productionTimeDays,
        "Product Cost": facility.productCost?.amount,
        "Currency": facility.productCost?.currency
      };

      // adding decoration attributes
      facility.decorations.forEach((decoration, index) => {
        baseProductInfo[`DecorationCost_${index + 1}`] = decoration.decorationCost?.amount;
        baseProductInfo[`Area_${index + 1}`] = decoration.area;
        baseProductInfo[`VendorArea_${index + 1}`] = decoration.vendorArea;
        baseProductInfo[`DPI_${index + 1}`] = decoration.imageSpecs?.dpi;
        baseProductInfo[`Height_${index + 1}`] = decoration.imageSpecs?.height;
        baseProductInfo[`Width_${index + 1}`] = decoration.imageSpecs?.width;
        baseProductInfo[`ImageFormat_${index + 1}`] = decoration.imageSpecs?.format;
        baseProductInfo[`PrintMethod_${index + 1}`] = decoration.printMethod;
      });

      // if there was a failed reason, add it to the base product info
      if (product.failedReason) {
        baseProductInfo["Failed Reason"] = product.failedReason;
      }

      flattenedData.push(baseProductInfo);
    });
  });

  // create a worksheet from the flattened data
  const worksheet = XLSX.utils.json_to_sheet(flattenedData);

  // add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

  // generate the workbook blob
  const workbookBlob = new Blob([XLSX.write(workbook, { type: 'buffer' })], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(workbookBlob);
  downloadLink.download = 'ProductVariants.xlsx';

  // simulate a click on the download link
  downloadLink.click();
};

export const parseAxiosError = (axiosError) => {
  if (!axiosError || !axiosError.response || !axiosError.response.data) {
    return 'An unexpected error occurred. Please try again later.';
  }

  const { data } = axiosError.response;

  if (data.errorCode !== 'ValidationError') {
    return 'Upload Error : An unexpected error occurred. Please try again later.';
  }

  const { errors } = data;
  if (!errors || errors.length === 0) {
    return 'Upload Error : Validation failed but no specific errors were provided.';
  }

  const parsedErrors = errors.map(error => {
    const { errorMessage, propertyName } = error;
    const match = errorMessage.match(/Path: \$.bulk\[(\d+)\].facilities\[(\d+)\]\.(\w+)/);
    // from the errorMessage, remove anything after the first period
    const errorMessageParts = errorMessage.split('Path');

    if (match) {
      // eslint-disable-next-line no-unused-vars
      const [_, bulkIndex, facilityIndex, property] = match;
      return `Upload Error : Error in entry ${parseInt(bulkIndex, 10) + 1}, facility ${parseInt(facilityIndex, 10) + 1}, property "${property}": ${errorMessageParts[0]}<br><br>Error in property : ${propertyName}.`;
    }

    return `Upload Error : Error in property "${propertyName}": ${errorMessage}`;
  });

  return parsedErrors.join('\n');
}