import axios from "axios";
import Config from "../config";
import SetupInterceptors from './axiosInterceptors';

export const MERCHANT_SCHEMA = 'MerchantsConfiguration';
export const USER_SCHEMA = 'UsersConfiguration';

class ConfigServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.ConfigMS.Url
    });
    SetupInterceptors(this.api);
  }

  getConfiguration = async (id, schemaName) => {
    try {
      const response = await this.api.get(`/v1/config?ownerId=${id}&schemaName=${schemaName}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching configuration data from API');
    }
  }

  getSchema = async (schemaName) => {
    try {
      const response = await this.api.get(`/v1/schema/${schemaName}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching schema data from API');
    }
  }

  updateConfiguration = async (data) => {
    try {
      const response = await this.api.put(`/v1/config`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating configuration data from API');
    }
  }

  createConfiguration = async (data) => {
    try {
      const response = await this.api.post(`/v1/config`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating configuration data from API');
    }
  }
}

export default new ConfigServices();
