import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './index.scss';

const LoadingAnimation = ({ variant = 'primary', fullscreen = true }) => {
  return (
    <div className={`loading-animation ${fullscreen ? 'fullscreen' : ''}`}>
      <Spinner animation="border" variant={variant} />
    </div>
  );
};

LoadingAnimation.propTypes = {
  variant: PropTypes.string,
  fullscreen: PropTypes.bool,
};

export default LoadingAnimation;
