import { put, takeEvery, call, all } from 'redux-saga/effects';
import {
  getHistory,
  getHistorySuccess,
  getHistoryFailure,
  getLatestHistory,
  getLatestHistorySuccess,
  getLatestHistoryFailure,
} from "app/store/actions/history"
import HistoryServices from 'app/services/historyServices';

function* fetchHistory(action) {
  const { parentType, parentId, entityType, page, pageSize, sortAscending } = action.payload;
  try {
    const resp = yield call([HistoryServices, HistoryServices.getHistory], parentType, parentId, entityType, page, pageSize, sortAscending);
    yield put(getHistorySuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getHistoryFailure(error));
  }
}

function* fetchLatestHistory(action) {
  const { parentType, parentId, entityType, page, pageSize, sortAscending } = action.payload;
  try {
    const resp = yield call([HistoryServices, HistoryServices.getHistory], parentType, parentId, entityType, page, pageSize, sortAscending);
    yield put(getLatestHistorySuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getLatestHistoryFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getHistory.toString(), fetchHistory);
  yield takeEvery(getLatestHistory.toString(), fetchLatestHistory);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}