import React from 'react';
import PropTypes from 'prop-types';
import Button from 'app/components/Button';
import { XLg } from "react-bootstrap-icons";
import ButtonIcon from '../ButtonIcon';
import './index.scss';

const Modal = ({
  title = '',                        
  subtitle = '',                     
  showCloseButton = true,            
  children,
  primaryButtonOnClick,
  primaryButtonVariant = 'primary',  
  primaryButtonLabel = 'Close',      
  primaryButtonEnabled = true,       
  primaryButtonDestructive = false,  
  secondaryButtonOnClick,
  secondaryButtonVariant = 'secondary', 
  secondaryButtonLabel = 'Close',    
  secondaryButtonEnabled = true,     
  hideButtons = false,               
  className = '',                    
  onClose,
  isOpen = true,                     
}) => {
  if (!isOpen) return null;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`gooten-modal ${className}`} onClick={onClose}>
      <div className="gooten-modal-content" onClick={stopPropagation}>
        {title && (
          <div className="gooten-modal-header">
            <div>
              <div className="gooten-modal-title">{title}</div>
              {subtitle && <div className="gooten-modal-subtitle">{subtitle}</div>}
            </div>
            {showCloseButton && (
              <ButtonIcon onClick={onClose} icon={<XLg />} />
            )}
          </div>
        )}
        <div className={`gooten-modal-body ${title ? 'has-title' : ''}`}>{children}</div>
        {!hideButtons && (
          <div className="gooten-modal-footer">
            {secondaryButtonOnClick && (
              <Button
                variant={secondaryButtonVariant}
                size="small"
                label={secondaryButtonLabel}
                onClick={secondaryButtonOnClick}
                disabled={!secondaryButtonEnabled}
              />
            )}
            {primaryButtonOnClick && (
              <Button
                variant={primaryButtonVariant}
                size="small"
                label={primaryButtonLabel}
                onClick={primaryButtonOnClick}
                disabled={!primaryButtonEnabled}
                destructive={primaryButtonDestructive}
              />
            )}
          </div>
        )}
      </div>
      <div className="gooten-modal-backdrop" onClick={onClose}></div>
    </div>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showCloseButton: PropTypes.bool,
  children: PropTypes.node, 
  primaryButtonVariant: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  primaryButtonEnabled: PropTypes.bool,
  primaryButtonDestructive: PropTypes.bool,
  secondaryButtonVariant: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonEnabled: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default Modal;
