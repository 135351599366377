import React from 'react';
import { Card, Table, TableHeader, Pagination } from 'app/components'
import moment from 'moment';
import './index.scss';

const WebhookLogs = (props) => {
  const { webhookLogs, sortBy, sortDir} = props;

  return (
    <Card className="webhook-logs">
      <Card.Header>
        Logs
      </Card.Header>
      <Card.Body>
        <Table size="small" verticalAlign="top" highlightRows={false}>
          <TableHeader
            sortBy={sortBy}
            sortDir={sortDir}
            options={[
              { id: 'dateCreated', label: 'Date Created', orderable: true },
              { id: 'id', label: 'Log ID' },
              { id: 'userId', label: 'User ID' },
              { id: 'description', label: 'Description' },
            ]}
          />
          <tbody className="table-body">
            {webhookLogs.historyLogs.map((log) => (
              <tr className="history-row" key={log.id}>
                <td>{moment(log.dateCreated).local().format('MMM DD, YYYY h:mm:ss a')}</td>
                <td>{log.id}</td>
                <td>{log.userId}</td>
                <td>{log.description}</td>
              </tr>
            ))}
            {webhookLogs.historyLogs.length === 0 && (
              <tr>
                <td colSpan="4" className="no-results">No logs found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination totalItems={webhookLogs.total || 0} />
      </Card.Body>
    </Card>
  )
}

export default WebhookLogs;