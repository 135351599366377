import {
  getAllTransformations,
  getAllTransformationsSuccess,
  getAllTransformationsFailure,
  getTransformationDetails,
  getTransformationDetailsSuccess,
  getTransformationDetailsFailure,
  createTransformation,
  createTransformationSuccess,
  createTransformationFailure,
  addCustomizationToTransformation,
  addCustomizationToTransformationSuccess,
  addCustomizationToTransformationFailure,
  testDeployedTransformation,
  testDeployedTransformationSuccess,
  testDeployedTransformationFailure,
  promoteTransformation,
  promoteTransformationSuccess,
  promoteTransformationFailure,
  clearErrors,
  reset,
} from "app/store/actions/transformation"
import { handleActions } from 'redux-actions';

const initialState = {
  transformations: {
    loading: false,
    data: null,
    error: null,
  },
  transformation:  {
    loading: false,
    data: null,
    error: null,
  },
}

const transformationReducer = handleActions(
  {
    [getAllTransformations]: (state) => ({ 
      ...state,
      transformations: {
        ...state.transformations,
        loading: true,
      }
    }),

    [getAllTransformationsSuccess]: (state, data) => ({
      ...state,
      transformations: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getAllTransformationsFailure]: (state, data) => ({ 
      ...state,
      transformations: {
        ...state.transformations,
        loading: false,
        error: data.payload,
      }
    }),

    [getTransformationDetails]: (state) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: true,
      }
    }),

    [getTransformationDetailsSuccess]: (state, data) => ({
      ...state,
      transformation: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getTransformationDetailsFailure]: (state, data) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: data.payload,
      }
    }),

    [createTransformation]: (state) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: true,
      }
    }),

    [createTransformationSuccess]: (state) => ({
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: null,
      }
    }),

    [createTransformationFailure]: (state, data) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: data.payload,
      }
    }),

    [addCustomizationToTransformation]: (state) => ({
      ...state,
      transformation: {
        ...state.transformation,
        loading: true,
      }
    }),

    [addCustomizationToTransformationSuccess]: (state) => ({
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: null,
      }
    }),

    [addCustomizationToTransformationFailure]: (state, data) => ({
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: data.payload,
      }
    }),

    [testDeployedTransformation]: (state) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: true,
      }
    }),

    [testDeployedTransformationSuccess]: (state) => ({
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: null,
      }
    }),

    [testDeployedTransformationFailure]: (state, data) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: data.payload,
      }
    }),

    [promoteTransformation]: (state) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: true,
      }
    }),

    [promoteTransformationSuccess]: (state, data) => {
      const snippets = JSON.parse(JSON.stringify(state.snippetVersions.data));
      const updatedSnippets = snippets.map(snippet => {
        snippet.published = String(snippet.snippetId) === String(data.payload);
        return snippet;
      });
      
      return ({
      ...state,
      transformation: {
        loading: false,
        data: updatedSnippets,
        error: null,
      }
    })},

    [promoteTransformationFailure]: (state, data) => ({ 
      ...state,
      transformation: {
        ...state.transformation,
        loading: false,
        error: data.payload,
      }
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      transformations: {
        ...state.transformations,
        error: null,
      },
      transformation: {
        ...state.transformation,
        error: null,
      },
    }),

    [reset]: () => ({ 
     ...initialState
    })
  },
  initialState,
)

export default transformationReducer;