import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, SearchBar, Pagination, LoadingAnimation, MessageBar } from 'app/components';
import {
  integrationsDataSelector,
  integrationsLoadingSelector,
  integrationsErrorsSelector,
  merchantIntegrationsDataSelector,
  merchantIntegrationsLoadingSelector,
  merchantIntegrationsErrorsSelector
} from 'app/store/selectors/integration';
import { getIntegrations, getMerchantIntegrations } from 'app/store/actions/integration';
import { currentUserSelector } from 'app/store/selectors/user';
import PluginCard from '../PluginCard';
import './index.scss';

const PublicIntegrations = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const [currentCategory, setCurrentCategory] = useState(null);

  const currentUser = useSelector(currentUserSelector);
  const integrationsData = useSelector(integrationsDataSelector);
  const loading = useSelector(integrationsLoadingSelector);
  const errorsFound = useSelector(integrationsErrorsSelector);
  const merchantIntegrationsData = useSelector(merchantIntegrationsDataSelector);
  const merchantLoading = useSelector(merchantIntegrationsLoadingSelector);
  const merchantErrorsFound = useSelector(merchantIntegrationsErrorsSelector);

  const categories = [
    {
      label: 'All Integrations',
      value: null,
    },
    {
      label: 'Vendors',
      value: 'vendor',
    },
    {
      label: 'Merchants',
      value: 'merchant',
    },
  ];

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getIntegrations({
        category: currentCategory,
        searchString,
        currentPage,
        pageSize,
        isPublished: true,
        canBeOverriden: true,
      }));
      dispatch(getMerchantIntegrations({
        merchantId: currentUser.id,
        category: currentCategory,
        searchString,
        currentPage,
        pageSize,
        isPublished: true,
      }));
    }
  }, [currentCategory, searchString, currentPage, pageSize, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  };

  const updatedIntegrationsData = useMemo(() => {
    if (!integrationsData || !merchantIntegrationsData) {
      return { plugins: [] };
    }
    return {
      ...integrationsData,
      plugins: integrationsData.plugins.map((plugin) => {
        const alreadyConnected = merchantIntegrationsData.plugins.some(
          (merchantPlugin) => merchantPlugin.pluginId === plugin.id
        );
        return { ...plugin, alreadyConnected };
      }),
    };
  }, [integrationsData, merchantIntegrationsData]);

  return (
    <div className="public-integrations">
      {(loading || merchantLoading) && <LoadingAnimation />}
      <Card>
        <div className="public-integrations-header">
          Integrations
        </div>
        <SearchBar
          searchPlaceholderText="Search for an Integration"
          onSearchStringUpdated={onSearchStringUpdated}
          debounceDelay={500}
        />
        <div className="public-integrations-content">
          <div className="content-categories">
            <div className="content-categories-header">
              Categories
            </div>
            <div className="content-categories-list">
              {categories.map((category) => (
                <div
                  key={category.value}
                  className={`content-category ${category.value === currentCategory ? 'active' : ''}`}
                  onClick={() => setCurrentCategory(category.value)}
                >
                  {category.label}
                </div>
              ))}
            </div>
          </div>
          {!errorsFound && !merchantErrorsFound && updatedIntegrationsData && merchantIntegrationsData && (
            <>
              {(updatedIntegrationsData.plugins?.length === 0) && !loading && !merchantLoading ? (
                <div className="no-integrations-found">
                  No Integrations Found
                </div>
              ) : (
                <div className="content-list">
                  {updatedIntegrationsData.plugins?.map(plugin => <PluginCard key={plugin.id} plugin={plugin} /> )}
                </div>
              )}
            </>
          )}
          {(errorsFound || merchantErrorsFound) && (
            <MessageBar color="yellow">
              An error occurred while loading integration data
            </MessageBar>
          )}
        </div>
        <Pagination
          totalItems={12}
          hideBelow={30}
        />
      </Card>
    </div>
  );
}

export default PublicIntegrations;
