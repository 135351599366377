import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class OrderServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.OrdersMS.Url
    });

    SetupInterceptors(this.api);
  }

  getOrders = async (data) => {
    const {
      id,
      externalId,
      status,
      merchantName,
      customerName,
      createdStartDate,
      createdEndDate,
      updated,
      currency,
      customerTotal,
      searchAll,
      page,
      pageSize,
      sortBy,
      sortDir
    } = data;

    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('id', id);
    addParam('externalId', externalId);
    addParam('status', status);
    addParam('merchantName', merchantName);
    addParam('customerName', customerName);
    addParam('createdStartDate', createdStartDate);
    addParam('createdEndDate', createdEndDate);
    addParam('updated', updated);
    addParam('currency', currency);
    addParam('customerTotal', customerTotal);
    addParam('searchAll', searchAll);
    addParam('page', page);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');

    try {
      const response = await this.api.get(`/v1/orders?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching order listing');
    }
  };

  createOrder = async (data) => {
    try {
      const response = await this.api.post(`/v1/orders`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching order listing');
    }
  };

  getOrderDetails = async (orderId) => {
    try {
      const response = await this.api.get(`/v1/orders/${orderId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching order details');
    }
  };

  updateOrderDetails  = async (orderId, data) => {
    try {
      const response = await this.api.patch(`/v1/orders/${orderId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating order details');
    }
  };

  cancelOrder  = async (orderId, data) => {
    try {
      const response = await this.api.post(`/v1/orders/${orderId}/cancel`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error cancelling order');
    }
  };

  cancelOrderItems  = async (orderId, data) => {
    try {
      const response = await this.api.post(`/v1/orders/${orderId}/cancel`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error cancelling order items');
    }
  };

  createRefundRequest = async (data) => {
    try {
      const response = await this.api.post(`/v1/orders/refund`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      } else {
        throw new Error('Error issuing refund request');
      }
    }
  }

  updateItemImageUrl  = async (orderId, itemId, data) => {
    try {
      const response = await this.api.patch(`/v1/orders/${orderId}/orderitems/${itemId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating image url');
    }
  };

  getAuditInfo = async (auditUrl) => {
    try {
      auditUrl = auditUrl.replace(/.*orders/, 'v1/orders');
      if (!auditUrl.includes('/auditLogs')) auditUrl = auditUrl.replace('?', '/auditLogs?');
      auditUrl = auditUrl.replace('DIFF', 'FULL');

      const response = await this.api.get(auditUrl);
      return response.data;
    }
    catch (error) {
      throw new Error('Error fetching audit info');
    }
  }
}

export default new OrderServices();