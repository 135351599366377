import { toast } from 'react-toastify';
import { CountryList } from 'app/constants';

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      toast("Copied To Clipboard", {autoClose: 1000});
    })
    .catch((error) => {
      console.error('Error copying text:', error);
    });
};

export const convertCountryCodeToName = (countryCode) => {
  const country = CountryList.find(country => country.CountryCode === countryCode);
  return country ? country.Name : null;
}

export const generateGUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export const capitalizeFirstLetter = s => s?.charAt(0).toUpperCase() + s?.slice(1);

export const formatCurrency = (currency, amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });

  return formatter.format(amount) + " " + currency;
}
