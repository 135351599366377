import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Dropdown, MultiSelect, LoadingAnimation } from 'app/components';
import { getVendors } from 'app/store/actions/vendor';
import { vendorsDataSelector, vendorsLoadingSelector } from 'app/store/selectors/vendor';
import { assignItemsToShipment } from 'app/store/actions/shipment';

import './index.scss';

const CreateShipmentModal = props => {
  const { orderId, orderItems, showCreateShipmentModal } = props;
  const dispatch = useDispatch();

  const [vendorSelectionType, setVendorSelectionType] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);

  useEffect(() => {
    if (!vendorsData) {
      dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
    }
  }, [vendorsData, dispatch]);

  const getVendorFacilities = () => {
    let vendorFacilities = [];

    for (let i = 0; i < vendorsData.vendors.length; i++) {
      for (let j = 0; j < vendorsData.vendors[i].facilities.length; j++) {
        if (vendorsData.vendors[i].facilities[j].id) {
          vendorFacilities.push({ value: vendorsData.vendors[i].facilities[j].id, label: `${vendorsData.vendors[i].name || vendorsData.vendors[i].legalName} - ${vendorsData.vendors[i].facilities[j].name}` });
        }
      }
    }

    vendorFacilities = vendorFacilities.sort((a, b) => a.label.localeCompare(b.label));

    // remove duplicates
    vendorFacilities = vendorFacilities.filter((facility, index, self) => index === self.findIndex((t) => (t.value === facility.value)));

    return vendorFacilities;
  }

  if (!vendorsData && vendorsLoading) { return <LoadingAnimation /> }

  return (
    <Modal
      title="Create a New Shipment"
      subtitle="Choose the items you want to include in this shipment"
      className="create-shipment-modal"
      hideButtons={true}
      onClose={() => { setSelectedVendor(null); setVendorSelectionType(null); showCreateShipmentModal(null) }}
    >
      <div className="create-shipment-modal-content">
        <MultiSelect
          label="Select Items to Ship"
          options={orderItems.map(item => (
            { value: item.id, label: `Item ${item.shortId || item.id} - ${item.sku}` }
          ))}
          value={selectedItems}
          onChange={setSelectedItems}
          maxDisplayedValueLength={1}
        />
        <Dropdown
          label="Vendor Facility Selection Type"
          placeholder="Select Vendor Facility Selection Type"
          name="vendorSelectionType"
          value={vendorSelectionType}
          onChange={(e) => { setSelectedVendor(null); setVendorSelectionType(e.target.value) }}
          options={[
            { value: 'automatic', label: `Automatic - Let the system choose the vendor facility` },
            { value: 'manual', label: `Manual - You choose the vendor facility` },
          ]}
        />
      </div>
      {vendorSelectionType === 'manual' && (
        <div>
          <Dropdown
            label="Vendor Facility"
            name="selectedVendor"
            value={selectedVendor?.value || ''}
            onChange={(e) => {
              setSelectedVendor({
                value: e.target.value,
                label: e.target.label
              });
            }}
            options={getVendorFacilities()}
            errorMessage={''}
          />
        </div>
      )}
      <div className="action-buttons">
        <Button
          variant="secondary"
          size="small"
          label="Cancel"
          onClick={() => { setVendorSelectionType(null); showCreateShipmentModal(null) }}
        />
        <Button
          variant="primary"
          size="small"
          label="Create Shipment"
          onClick={() => {
            const data = {
              orderId,
              facilityId: selectedVendor?.value || "",
              orderItemIds: selectedItems.map(item => item.value)
            }
            dispatch(assignItemsToShipment({ data, orderId, cb: () => showCreateShipmentModal(null) }));
          }}
          disabled={!(selectedItems.length > 0 && (vendorSelectionType === 'automatic' || selectedVendor !== null))}
        />
      </div>
    </Modal>
  )
}

export default CreateShipmentModal;
