import { createSelector } from 'reselect';

const historySelector = state => state.history;

export const historyDataSelector = createSelector([historySelector], historyStore => historyStore.fullHistory.data);
export const historyLoadingSelector = createSelector([historySelector], historyStore => historyStore.fullHistory.loading);
export const historyErrorsSelector = createSelector([historySelector], historyStore => historyStore.fullHistory.error);

export const latestHistoryDataSelector = createSelector([historySelector], historyStore => historyStore.latestHistory.data);
export const latestHistoryLoadingSelector = createSelector([historySelector], historyStore => historyStore.latestHistory.loading);
export const latestHistoryErrorsSelector = createSelector([historySelector], historyStore => historyStore.latestHistory.error);