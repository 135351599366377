import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconData, ButtonIcon } from 'app/components';
import { disconnectIntegration } from 'app/store/actions/integration';
import { currentUserSelector } from 'app/store/selectors/user';
import { ReactComponent as IndustryWindows } from 'app/assets/images/icons/industry-windows.svg';
import { ReactComponent as CodeBranch } from 'app/assets/images/icons/code-branch.svg';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import './index.scss';

const PluginCard = ({
  plugin,
  merchantView = false, 
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(currentUserSelector);

  const integrationItemOptions = () => [
    {
      value: 'Edit Integration',
      label: 'Edit Integration',
      onClick: () => {
        navigate(`/admin/integrations/edit-variables/${plugin.merchantPluginId}`);
      },
    },
    {
      value: 'Disconnect',
      label: 'Disconnect',
      destructive: true,
      onClick: () => {
        dispatch(
          disconnectIntegration({
            merchantId: currentUser.id,
            merchantPluginId: plugin.merchantPluginId,
          })
        );
      },
    },
  ];

  return (
    <div className="plugin-card">
      <div className="plugin-card-header">
        <div className="header-name">{plugin.name || plugin.pluginName}</div>
        <div className="header-actions">
          {!merchantView && (
            <Button
              label={plugin.alreadyConnected ? 'View' : 'Connect'}
              variant="secondary"
              size="small"
              onClick={() => {
                if (plugin.alreadyConnected) {
                  navigate(`/admin/integrations/my-integrations`);
                } else {
                  navigate(`/admin/integrations/connect-variables/${plugin.id}/${plugin.latestPublishedVersionId}`);
                }
              }}
            />
          )}
          {/* {merchantView &&
            plugin.currentPluginVersionName &&
            plugin.latestPublishedVersionName &&
            plugin.currentPluginVersionName !== plugin.latestPublishedVersionName && (
              <Button
                label="Update"
                variant="secondary"
                size="small"
                onClick={() => {
                  console.log('update');
                }}
              />
            )} */}
          {merchantView && (
            <ButtonIcon icon={<ThreeDotsVertical />} options={integrationItemOptions()} />
          )}
        </div>
      </div>
      <div className="plugin-card-content">
        {plugin.vendorName && (
          <IconData label={plugin.vendorName} icon={<IndustryWindows />} enableClipboardCopy={false} />
        )}
        {(plugin.currentPluginVersionName || plugin.latestPublishedVersionName) && (
          <IconData label={plugin.currentPluginVersionName || plugin.latestPublishedVersionName} icon={<CodeBranch />} />
        )}
      </div>
    </div>
  );
};

PluginCard.propTypes = {
  plugin: PropTypes.shape({
    vendorName: PropTypes.string,
    latestPublishedVersionName: PropTypes.string,
    merchantPluginId: PropTypes.string,
    alreadyConnected: PropTypes.bool,
  }).isRequired,
  merchantView: PropTypes.bool,
};

export default PluginCard;
