import {
  getStrategiesForMerchant,
  getStrategiesForMerchantSuccess,
  getStrategiesForMerchantFailure,
  createRoutingStrategy,
  createRoutingStrategySuccess,
  createRoutingStrategyFailure,
  updateRoutingStrategy,
  updateRoutingStrategySuccess,
  updateRoutingStrategyFailure,
  deleteRoutingStrategy,
  deleteRoutingStrategySuccess,
  deleteRoutingStrategyFailure,
  activateStrategy,
  activateStrategySuccess,
  activateStrategyFailure,
  getStrategyInfo,
  getStrategyInfoSuccess,
  getStrategyInfoFailure,
  clearErrors,
  reset,
} from "app/store/actions/routing"
import { handleActions } from 'redux-actions';

const initialState = {
  merchantStrategies: {
    loading: false,
    data: null,
    error: null,
  },
  createRoutingStrategy: {
    loading: false,
    data: null,
    error: null,
  },
  updateRoutingStrategy: {
    loading: false,
    data: null,
    error: null,
  },
  deleteRoutingStrategy: {
    loading: false,
    data: null,
    error: null,
  },
  activateRoutingStrategy: {
    loading: false,
    error: null,
  },
  strategyInfo: {
    loading: false,
    data: null,
    error: null,
  }
}

const routingReducer = handleActions(
  {
    [getStrategiesForMerchant]: (state) => ({ 
      ...state,
      merchantStrategies: {
        ...state.merchantStrategies,
        loading: true,
      }
    }),

    [getStrategiesForMerchantSuccess]: (state, data) => ({
      ...state,
      merchantStrategies: {
        loading: false,
        data: data.payload.routingStrategies,
        error: null,
      }
    }),

    [getStrategiesForMerchantFailure]: (state, data) => ({ 
      ...state,
      merchantStrategies: {
        ...state.merchantStrategies,
        loading: false,
        error: data.payload,
      }
    }),

    [createRoutingStrategy]: (state) => ({ 
      ...state,
      createRoutingStrategy: {
        ...state.createRoutingStrategy,
        loading: true,
      }
    }),

    [createRoutingStrategySuccess]: (state, data) => ({
      ...state,
      createRoutingStrategy: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [createRoutingStrategyFailure]: (state, data) => ({ 
      ...state,
      createRoutingStrategy: {
        ...state.createRoutingStrategy,
        loading: false,
        error: data.payload,
      }
    }),

    [updateRoutingStrategy]: (state) => ({ 
      ...state,
      updateRoutingStrategy: {
        ...state.updateRoutingStrategy,
        loading: true,
      }
    }),

    [updateRoutingStrategySuccess]: (state, data) => ({
      ...state,
      updateRoutingStrategy: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [updateRoutingStrategyFailure]: (state, data) => ({ 
      ...state,
      updateRoutingStrategy: {
        ...state.updateRoutingStrategy,
        loading: false,
        error: data.payload,
      }
    }),

    [deleteRoutingStrategy]: (state) => ({ 
      ...state,
      deleteRoutingStrategy: {
        ...state.deleteRoutingStrategy,
        loading: true,
      }
    }),

    [deleteRoutingStrategySuccess]: (state, data) => ({
      ...state,
      deleteRoutingStrategy: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [deleteRoutingStrategyFailure]: (state, data) => ({ 
      ...state,
      deleteRoutingStrategy: {
        ...state.deleteRoutingStrategy,
        loading: false,
        error: data.payload,
      }
    }),

    [activateStrategy]: (state) => ({ 
      ...state,
      activateRoutingStrategy: {
        ...state.activateRoutingStrategy,
        loading: true,
      }
    }),

    [activateStrategySuccess]: (state, data) => {
      const merchantStrategiesData = [...state.merchantStrategies.data];
      for (let i = 0; i < merchantStrategiesData.length; i++) {
        merchantStrategiesData[i].isActive = merchantStrategiesData[i].id === data.payload;
      }
      return ({
        ...state,
        merchantStrategies: {
          ...state.merchantStrategies,
          data: merchantStrategiesData,
        },
        activateRoutingStrategy: {
          loading: false,
          error: null,
        }
      })
    },

    [activateStrategyFailure]: (state, data) => ({ 
      ...state,
      activateRoutingStrategy: {
        ...state.activateRoutingStrategy,
        loading: false,
        error: data.payload,
      }
    }),

    [getStrategyInfo]: (state) => ({ 
      ...state,
      strategyInfo: {
        ...state.strategyInfo,
        loading: true,
      }
    }),

    [getStrategyInfoSuccess]: (state, data) => ({
      ...state,
      strategyInfo: {
        loading: false,
        data: data.payload.routingStrategy,
        error: null,
      }
    }),

    [getStrategyInfoFailure]: (state, data) => ({ 
      ...state,
      strategyInfo: {
        ...state.strategyInfo,
        loading: false,
        error: data.payload,
      }
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      merchantStrategies: {
        ...state.merchantStrategies,
        error: null,
      },
      createRoutingStrategy: {
        ...state.createRoutingStrategy,
        error: null,
      },
      updateRoutingStrategy: {
        ...state.updateRoutingStrategy,
        error: null,
      },
      deleteRoutingStrategy: {
        ...state.deleteRoutingStrategy,
        error: null,
      },
      activateRoutingStrategy: {
        ...state.activateRoutingStrategy,
        error: null,
      },
      strategyInfo: {
        ...state.strategyInfo,
        error: null,
      }
    }),

    [reset]: () => ({ 
     ...initialState
    })
  },
  initialState,
)

export default routingReducer;