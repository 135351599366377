import { handleActions } from 'redux-actions';
import {
  getWebhooks,
  getWebhooksSuccess,
  getWebhooksFailure,
  getWebhookDetails,
  getWebhookDetailsSuccess,
  getWebhookDetailsFailure,
  subscribeToWebhook,
  subscribeToWebhookSuccess,
  subscribeToWebhookFailure,
  updateWebhook,
  updateWebhookSuccess,
  updateWebhookFailure,
  deleteWebhookSubscription,
  deleteWebhookSubscriptionSuccess,
  deleteWebhookSubscriptionFailure,
  testWebhookSubscription,
  testWebhookSubscriptionSuccess,
  testWebhookSubscriptionFailure,
  getMessagesAuditInfo,
  getMessagesAuditInfoSuccess,
  getMessagesAuditInfoFailure,
  getWebhookAuditInfo,
  getWebhookAuditInfoSuccess,
  getWebhookAuditInfoFailure,
  clearErrors,
  reset,
} from "app/store/actions/notification";

const initialState = {
  webhooks: {
    loading: false,
    data: null,
    error: null,
  },
  webhook: {
    loading: false,
    data: null,
    error: null,
  },
  webhookTest: {
    loading: false,
    data: null,
    error: null,
  },
  auditInfo: {
    loading: false,
    error: null,
  },
}

const notificationReducer = handleActions(
  {
    [getWebhooks]: (state) => ({ 
      ...state,
      webhooks: {
        ...state.webhooks,
        loading: true,
        error: null,
      },
    }),

    [getWebhooksSuccess]: (state, data) => ({
      ...state,
      webhooks: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getWebhooksFailure]: (state, data) => ({ 
      ...state,
      webhooks: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [getWebhookDetails]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [getWebhookDetailsSuccess]: (state, data) => ({
      ...state,
      webhook: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getWebhookDetailsFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [subscribeToWebhook]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [subscribeToWebhookSuccess]: (state) => ({
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: null,
      },
    }),

    [subscribeToWebhookFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: data,
      },
    }),

    [updateWebhook]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [updateWebhookSuccess]: (state, data) => ({
      ...state,
      webhook: {
        ...state.webhook,
        data: data.payload,
        loading: false,
        error: null,
      },
    }),

    [updateWebhookFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: data,
      },
    }),

    [deleteWebhookSubscription]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [deleteWebhookSubscriptionSuccess]: (state) => ({
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: null,
      },
    }),

    [deleteWebhookSubscriptionFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: data,
      },
    }),

    [testWebhookSubscription]: (state) => ({ 
      ...state,
      webhookTest: {
        data: null,
        loading: true,
        error: null,
      },
    }),

    [testWebhookSubscriptionSuccess]: (state, data) => ({
      ...state,
      webhookTest: {
        data: data.payload,
        loading: false,
        error: null,
      },
    }),

    [testWebhookSubscriptionFailure]: (state, data) => ({ 
      ...state,
      webhookTest: {
        data: null,
        loading: false,
        error: data,
      },
    }),

    [getMessagesAuditInfo]: (state) => ({
      ...state,
      auditInfo: {
        loading: true,
        error: null,
      },
    }),

    [getMessagesAuditInfoSuccess]: (state) => ({
      ...state,
      auditInfo: {
        loading: false,
        error: null,
      },
    }),

    [getMessagesAuditInfoFailure]: (state, data) => ({
      ...state,
      auditInfo: {
        loading: false,
        error: data,
      },
    }),

    [getWebhookAuditInfo]: (state) => ({
      ...state,
      auditInfo: {
        loading: true,
        error: null,
      },
    }),

    [getWebhookAuditInfoSuccess]: (state) => ({
      ...state,
      auditInfo: {
        loading: false,
        error: null,
      },
    }),

    [getWebhookAuditInfoFailure]: (state, data) => ({
      ...state,
      auditInfo: {
        loading: false,
        error: data,
      },
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      webhooks: {
        ...state.webhooks,
        error: null,
      },
      webhook: {
        ...state.webhook,
        error: null,
      },
      webhookTest: {
        ...state.webhookTest,
        error: null,
      },
    }),

    [reset]: () => ({ 
      ...initialState
     })
  },
  initialState,
)

export default notificationReducer;