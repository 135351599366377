import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Modal, Button, Dropdown, LoadingAnimation, TextArea } from 'app/components';
import { getVendors } from 'app/store/actions/vendor';
import { vendorsDataSelector, vendorsLoadingSelector } from 'app/store/selectors/vendor';
import { rerouteShipment } from 'app/store/actions/shipment';
import './index.scss';

const RerouteOrderItemModal = (props) => {
  const { orderId, shipmentId, shipmentShortId, itemInfo, showChangeVendorModal } = props;
  const dispatch = useDispatch();

  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);

  useEffect(() => {
    if (!vendorsData) {
      dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
    }
  }, [vendorsData, dispatch]);

  const getVendorFacilities = (selectedVendor) => {
    if (!vendorsData) return [];
    const vendor = vendorsData.vendors.find((vendor) => vendor.id === selectedVendor);
    return vendor ? vendor.facilities.map((facility) => ({ value: facility.id, label: facility.name })) : [];
  };

  return (
    <Formik
      initialValues={{
        vendorSelectionType: 'automatic',
        selectedVendor: '',
        selectedFacility: '',
        reason: '',
        otherReason: '',
      }}
      validationSchema={object().shape({
        vendorSelectionType: string().required('Vendor selection type is required'),
        selectedVendor: string().when('vendorSelectionType', {
          is: vendorSelectionType => vendorSelectionType === 'manual',
          then: () => string().required('Vendor is required when selection type is manual'),
        }),
        selectedFacility: string().when('vendorSelectionType', {
          is: vendorSelectionType => vendorSelectionType === 'manual',
          then: () => string().required('Facility is required when selection type is manual'),
        }),
        reason: string().required('Reason is required'),
        otherReason: string().when('reason', {
          is: reason => reason === 'Other',
          then: () => string().required('Other reason is required when reason is set to other'),
        }),
      })}
      onSubmit={(values) => {
        const { selectedFacility, reason } = values;
        const data = {
          shipmentId,
          reason: values.otherReason || reason,
        };

        if (itemInfo){
          data.facilityId = selectedFacility || null,
          data.orderItems = [{
            id: itemInfo?.id,
          }];
        }
        dispatch(rerouteShipment({ orderId, data }));
        showChangeVendorModal(null);
      }}
    >
      {({
        values,
        errors,
        handleChange, 
        handleSubmit,
        setFieldValue, 
        submitCount
      }) => (
        <Modal
          className="reroute-order-item-modal"
          title={itemInfo ? `Reroute Item ${itemInfo?.shortId || itemInfo?.id}` : `Reroute Shipment ${shipmentShortId}`}
          hideButtons={true}
          onClose={() => { showChangeVendorModal(null); }}
        >
          {vendorsLoading && <LoadingAnimation />}
          <div className="change-vendor-modal-content">
            <Dropdown
              label="Vendor Facility Selection Type"
              name="vendorSelectionType"
              value={values.vendorSelectionType}
              onChange={(e) => {
                setFieldValue('selectedVendor', '');
                setFieldValue('vendorSelectionType', e.target.value);
              }}
              options={[
                { value: 'automatic', label: `Automatic - Let the system choose a new vendor facility` },
                { value: 'manual', label: `Manual - You choose the new vendor facility` },
              ]}
            />
          </div>
          {values.vendorSelectionType === 'manual' && (
            <div className="facility-selection">
              <Dropdown
                label="Select a Vendor"
                name="selectedVendor"
                value={values.selectedVendor}
                onChange={(e) => setFieldValue('selectedVendor', e.target.value)}
                options={
                  vendorsData?.vendors
                    .filter((vendor) => vendor.facilities.length > 0)
                    .filter((vendor) => vendor.facilities.every((facility) => facility.name))
                    .map((vendor) => ({ value: vendor.id, label: vendor.name || vendor.legalName })) || []
                }
              />
              {values.selectedVendor && (
                <Dropdown
                  label="Select the Facility"
                  name="selectedFacility"
                  value={values.selectedFacility}
                  onChange={(e) => setFieldValue('selectedFacility', e.target.value)}
                  options={getVendorFacilities(values.selectedVendor)}
                />
              )}
            </div>
          )}
          <Dropdown
            label={`Reason to Reroute the ${itemInfo ? 'Item' : 'Shipment'}`}
            name="reason"
            value={values.reason}
            onChange={handleChange}
            options={[
              { value: 'Damaged', label: 'Damaged' },
              { value: 'Wrong Item', label: 'Wrong Item' },
              { value: 'Other', label: 'Other' },
            ]}
            errorMessage={submitCount > 0 && errors.reason}
          />
          {values.reason === 'Other' && (
            <TextArea
              label="Other Reason"
              name="otherReason"
              value={values.otherReason}
              onChange={handleChange}
              placeholder="Add other reason here"
              errorMessage={submitCount > 0 && errors.otherReason}
            />
          )}
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={() => { showChangeVendorModal(null); }}
            />
            <Button
              variant="primary"
              size="small"
              label={itemInfo ? 'Reroute Item' : 'Reroute Shipment'}
              onClick={handleSubmit}
              destructive
              disabled={!(values.vendorSelectionType === 'automatic' || values.selectedVendor)}
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default RerouteOrderItemModal;
