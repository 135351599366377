import { ButtonIcon, Dropdown } from 'app/components'
import { PlusLg, Trash3 } from 'react-bootstrap-icons';

import './index.scss';

const Vendor = props => {
  const {
    blockIndex,
    groupIndex,
    vendorIndex,
    vendorCount,
    values,
    data,
    editMode,
    filteredVendors,
    errors,
    handleChange,
    submitCount,
    setFieldValue,
    validateForm,
  } = props;

  const addVendor = () => {
    const vendorRank = vendorIndex < vendorCount - 1 ? values.blocks[blockIndex].groups[groupIndex].vendors[vendorIndex].rank : parseInt(values.blocks[blockIndex].groups[groupIndex].vendors[vendorIndex].rank) + 1;
    const newVendor = {
      vendorId: '',
      facilityId: '',
      rank: String(vendorRank),
    }
  
    const vendorsBeforeIndex = values.blocks[blockIndex].groups[groupIndex].vendors.slice(0, vendorIndex + 1);
    const vendorsAfterIndex = values.blocks[blockIndex].groups[groupIndex].vendors.slice(vendorIndex + 1);
    const updatedVendors = [...vendorsBeforeIndex, newVendor, ...vendorsAfterIndex];
    setFieldValue(`blocks[${blockIndex}].groups[${groupIndex}].vendors`, updatedVendors);
  }

  const removeVendor = () => {
    const updatedVendors = values.blocks[blockIndex].groups[groupIndex].vendors.filter((_, index) => index !== vendorIndex);
    setFieldValue(`blocks[${blockIndex}].groups[${groupIndex}].vendors`, updatedVendors);
  };

  const selectedVendor = filteredVendors.find(vendor => vendor.id === data.vendorId);
  let filteredFacilities = [];
  if (selectedVendor && selectedVendor.vendorId !== '') {
    filteredFacilities = [...selectedVendor.facilities];
  }

  const alreadySelectedVendors = values.blocks[blockIndex].groups[groupIndex].vendors;
  
  return (
    <div className={`vendor-group ${editMode ? 'edit-mode' : ''}`}>
      <div className="dymamic-header full-width">
        {vendorIndex === 0 && <div className="header-text">Vendor Name</div>}
        <Dropdown
          name={`blocks.${blockIndex}.groups.${groupIndex}.vendors.${vendorIndex}.vendorId`}
          value={data.vendorId}
          searchable={true}
          readonly={!editMode}
          onChange={(e) => {
            const selectedVendor = filteredVendors.find(vendor => vendor.id === e.target.value);
            if (selectedVendor && selectedVendor.vendorId !== '') {
              setFieldValue(`blocks.${blockIndex}.groups.${groupIndex}.vendors.${vendorIndex}.facilityId`, '');
            }
            handleChange(e);
            
            // updating the formik state and immediately validating the form causes it to miss the change.  Wait till the next render to validate.
            setTimeout(() => {
                validateForm();
            }, 1);
          }}
          options={filteredVendors.map(vendor => (
            { value: vendor.id, label: vendor.name }
          ))}
          errorMessage={submitCount > 0 && errors?.blocks?.[blockIndex]?.groups?.[groupIndex]?.vendors?.[vendorIndex]?.vendorId}
        />
      </div>
      <div className="dymamic-header full-width">
        {vendorIndex === 0 && <div className="header-text">Facility Name</div>}
        <Dropdown
          name={`blocks.${blockIndex}.groups.${groupIndex}.vendors.${vendorIndex}.facilityId`}
          placeholder="Select a Facility"
          value={data.facilityId}
          readonly={!editMode}
          disabled={data.vendorId?.length === 0}
          onChange={handleChange}
          options={filteredFacilities.map(facility => {
            let isAlreadyUsed = false;
            for(let j = 0; j < alreadySelectedVendors.length; j++) {
              if (alreadySelectedVendors[j].facilityId === facility.id && facility.id !== null && data.facilityId !== facility.id) {
                isAlreadyUsed = true;
              }
            }
            return { value: facility.id, label: facility.name, disabled: isAlreadyUsed }
          })}
          errorMessage={submitCount > 0 && errors?.blocks?.[blockIndex]?.groups?.[groupIndex]?.vendors?.[vendorIndex]?.facilityId}
        />
      </div>
      {(values.blocks[blockIndex].groups[groupIndex].orderingAlgorithm === 'Ranked' ||
        values.blocks[blockIndex].groups[groupIndex].tiebreakerOrderingAlgorithm === 'Ranked') && (
        <div className="dymamic-header">
          {vendorIndex === 0 && <div className="header-text">Rank</div>}
          <Dropdown
            className="vendor-rank"
            name={`blocks.${blockIndex}.groups.${groupIndex}.vendors.${vendorIndex}.rank`}
            value={data.rank}
            readonly={!editMode}
            onChange={handleChange}
            options={Array.from({ length: vendorCount }, (_, i) => i).map(vendor => (
              { value: vendor, label: vendor }
            ))}
            errorMessage={submitCount > 0 && errors?.blocks?.[blockIndex]?.groups?.[groupIndex]?.vendors?.[vendorIndex]?.rank}
          />
        </div>
      )}
      {editMode && (
        <div className="dymamic-header">
          {vendorIndex === 0 && <div className="header-text">&nbsp;</div>}
          <div className="row-options">
            <div className="top-buttons">
              <ButtonIcon
                icon={<PlusLg />}
                onClick={() => addVendor()}
              />
            </div>
            <div className="bottom-buttons">
              {values.blocks[blockIndex].groups[groupIndex].vendors.length > 1 ? (
                <ButtonIcon
                  icon={<Trash3 />}
                  destructive={true}
                  onClick={() => removeVendor()}
                />
              ) : <div className="button-filler"></div>}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Vendor;