const { capitalizeFirstLetter } = require("app/utils");

export const formatCategoryName = (category) => category?.split('-').map(capitalizeFirstLetter).join(' ');
export const formatSchemaObjName = name => {
    const formatted = name?.replace(/([A-Z])/g, ' $1')?.trim();
    return formatted?.charAt(0)?.toUpperCase() + formatted?.slice(1);
}

export const formatSchemaObjKey = key => {
    if (key?.includes('_')) {
        return key?.split('_').map(capitalizeFirstLetter).join(' ');
    }
    return key?.split(/(?=[A-Z])/).map(capitalizeFirstLetter).join(' ');
}