import { useSelector } from 'react-redux';
import { currentUserSelector, permissionsByResourceSelector } from 'app/store/selectors/user';
import { useMemo } from 'react';

export const usePermission = (resource, action) => {
  const permissionNames = useSelector(state => permissionsByResourceSelector(state, resource));
  return useMemo(() => permissionNames.includes(action), [permissionNames, action]);
};

export const isSuperAdmin = () => {
  const userRoles = useSelector(state => currentUserSelector(state)?.roles);
  return useMemo(() => userRoles?.some(role => role?.name?.toLowerCase() === 'super-admin'), [userRoles]);
}

export const hasRole = (role) => {
  const userRoles = useSelector(state => currentUserSelector(state)?.roles);
  return useMemo(() => userRoles?.some(userRole => userRole?.name?.toLowerCase() === role?.toLowerCase()), [userRoles, role]);
}

export const isMerchant = () => {
  const userAttributes = useSelector(state => currentUserSelector(state)?.attributes);
  return useMemo(() => userAttributes?.entity?.some(v => v?.toLowerCase() === 'merchant'), [userAttributes]);
}

export const isVendor = () => {
  const userAttributes = useSelector(state => currentUserSelector(state)?.attributes);
  return useMemo(() => userAttributes?.entity?.some(v => v?.toLowerCase() === 'vendor' || v?.toLowerCase() === 'facility'), [userAttributes]);
}