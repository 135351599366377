export const Operators = Object.freeze({
  IsEqualTo: "is equal to",
  IsNotEqualTo: "is not equal to",
  Contains: "contains",
  IsLessThan: "is less than",
  IsLessThanOrEqual: "is less than or equal",
  IsGreaterThan: "is greater than",
  IsGreaterThanOrEqual: "is greater than or equal",
  IsBetween: "is between",
});

export const Conditions = Object.freeze({
  ShipToCountry: {
    Name: "ShipToCountry",
    Operators: {
      IsEqualTo: Operators.IsEqualTo,
    },
  },
  Quantity: {
    Name: "Quantity",
    Operators: {
      IsLessThan: Operators.IsLessThan,
      IsGreaterThan: Operators.IsGreaterThan,
      IsBetween: Operators.IsBetween,
    },
  },
  ProductCategory: {
    Name: "ProductCategory",
    Operators: {
      IsEqualTo: Operators.IsEqualTo,
      Contains: Operators.Contains,
    },
  },
  OrderMeshSKU: {
    Name: "OrderMeshSKU",
    Operators: {
      IsEqualTo: Operators.IsEqualTo,
      Contains: Operators.Contains
    },
  },
  CustomerShippingMethod: {
    Name: "CustomerShippingMethod",
    Operators: {
      IsEqualTo: Operators.IsEqualTo
    },
  },
  CustomerOrderTotal: {
    Name: "CustomerOrderTotal",
    Operators: {
      IsEqualTo: Operators.IsEqualTo,
      IsNotEqualTo: Operators.IsNotEqualTo,
      IsLessThan: Operators.IsLessThan,
      IsLessThanOrEqual: Operators.IsLessThanOrEqual,
      IsGreaterThan: Operators.IsGreaterThan,
      IsGreaterThanOrEqual: Operators.IsGreaterThanOrEqual,
      IsBetween: Operators.IsBetween,
    },
  },
  CustomField: {
    Name: "CustomField",
    Operators: {
      IsEqualTo: Operators.IsEqualTo,
      Contains: Operators.Contains,
    },
  },
});
