import React from 'react';
import { Button } from 'app/components';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PageNotFoundImage } from 'app/assets/images/page-not-found.svg';
import './index.scss';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="page-not-found">
      <PageNotFoundImage />
      <div className="title">Oops! Page Not Found</div>
      <div className="desc-line-1">It might have been removed, had it&apos;s name changed, or is temporarily unavailable.</div>
      {/* <div className="desc-line-2">Here are some helpful links to get you back on track.</div> */}
      <div className="action-buttons">
        {/* <Button
          className="support-page-button"
          variant="secondary"
          size="small"
          label="Support Page"
          onClick={() => { }}
        /> */}
        <Button
          className="home-button"
          variant="primary"
          size="small"
          label="Home"
          onClick={() => { navigate('/') }}
        />
      </div>
    </div>
  );
}

export default PageNotFound;
