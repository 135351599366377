import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Modal, Button, Dropdown, TextArea } from 'app/components';
import { cancelShipment } from 'app/store/actions/shipment';
import './index.scss';

const CancelShipmentModal = (props) => {
  const { orderId, shipment, showCancelShipmentModal } = props;
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        reason: '',
        otherReason: '',
      }}
      validationSchema={object().shape({
        reason: string().required('Reason is required'),
        otherReason: string().when('reason', {
          is: reason => reason === 'Other',
          then: () => string().required('Other reason is required when reason is set to other'),
        }),
      })}
      onSubmit={(values) => {
        const data = { cancelReason: values.otherReason || values.reason };
        dispatch(cancelShipment({orderId, shipmentId: shipment.id, data}));
        showCancelShipmentModal(null);
      }}
    >
      {({
        values,
        errors,
        handleChange, 
        handleSubmit,
        submitCount,
        setFieldValue,
      }) => (
        <Modal
          className="cancel-shipment-modal"
          title={`Cancel Shipment ${shipment.shortId || shipment.id}`}
          hideButtons={true}
          onClose={() => { showCancelShipmentModal(null); }}
        >
          <Dropdown
            label="Reason to Cancel the Shipment"
            name="reason"
            value={values.reason}
            onChange={(e) => {
              handleChange(e);
              if (e.target.value !== 'Other') {
                setFieldValue('otherReason', '');
              }
            }}
            options={[
              { value: 'Damaged', label: 'Damaged' },
              { value: 'Wrong Item', label: 'Wrong Item' },
              { value: 'Other', label: 'Other' },
            ]}
            errorMessage={submitCount > 0 && errors.reason}
          />
          {values.reason === 'Other' && (
            <TextArea
              label="Other Reason"
              name="otherReason"
              value={values.otherReason}
              onChange={handleChange}
              placeholder="Add other reason here"
              errorMessage={submitCount > 0 && errors.otherReason}
            />
          )}
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={() => { showCancelShipmentModal(null); }}
            />
            <Button
              variant="primary"
              size="small"
              label="Cancel Shipment"
              destructive
              onClick={handleSubmit}
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default CancelShipmentModal;
