import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Modal, Button, Dropdown, TextArea, Input } from 'app/components'; 
import { cancelShipment, updateShipmentById, updatePackageById } from 'app/store/actions/shipment';
import './index.scss';

const ChangeShipmentStatusModal = (props) => {
  const { orderId, shipment, showChangeShipmentStatusModal } = props;
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        status: '',
        reason: '',
        otherReason: '',
        trackingNumber: '',
        trackingLink: '',
        vendorOrderId: '',
      }}
      validationSchema={object().shape({
        status: string().required('New status is required'),        
      })}
      onSubmit={(values) => {
        if (values.status === 'Canceled') {
          const data = { cancelReason: values.otherReason || values.reason };
          dispatch(cancelShipment({orderId, shipmentId: shipment.id, data, cb: () => showChangeShipmentStatusModal(null)}));
        } else if (values.status === 'Fulfilled') {
          // send the tracking number and URL (not ideal as one one the requests could fail and the other succeed)
          // and only do this if they entered a tracking number or url
          if (values.trackingNumber.length > 0 || values.trackingLink.length > 0) {
            const updatedPackages = shipment.packages.map((pkg, index) => {
              if (index === 0) {
                return {
                  ...pkg,
                  pkgId: pkg.id,
                  carrierTrackingNumber: values.trackingNumber,
                  carrierLink: values.trackingLink,
                };
              }
              return pkg;
            });
    
            const data2 = {
              orderId: orderId,
              shipmentId: shipment.id,
              packages: updatedPackages,
            };
            dispatch(updatePackageById({ data: data2,  }));
          }

          // then update the shipment status
          const data = { shipmentStatus: values.status };
          dispatch(updateShipmentById({orderId, shipmentId: shipment.id, data, cb: () => showChangeShipmentStatusModal(null)}));
        } else if (values.status === 'InProduction') {
          // const data = { vendorOrderId: values.vendorOrderId };
          const data = { shipmentStatus: values.status };
          dispatch(updateShipmentById({orderId, shipmentId: shipment.id, data, cb: () => showChangeShipmentStatusModal(null)}));
        } else if (values.status === 'ReadyForFulfillment') {
          const data = { shipmentStatus: values.status };
          dispatch(updateShipmentById({orderId, shipmentId: shipment.id, data, cb: () => showChangeShipmentStatusModal(null)}));
        } else if (values.status === 'FulfillmentError') {
          const data = { shipmentStatus: values.status };
          dispatch(updateShipmentById({orderId, shipmentId: shipment.id, data, cb: () => showChangeShipmentStatusModal(null)}));
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,
        setFieldValue,
      }) => (
        <Modal
          className="update-shipment-status-modal"
          title="Update Shipment Status"
          hideButtons={true}
          onClose={() => { showChangeShipmentStatusModal(null); }}
        >
          <Dropdown
            label="New Status"
            name="status"
            value={values.status}
            onChange={handleChange}
            options={[
              { value: 'ReadyForFulfillment', label: 'ReadyForFulfillment' },
              { value: 'InProduction', label: 'InProduction' },
              { value: 'Fulfilled', label: 'Fulfilled' },
              { value: 'FulfillmentError', label: 'FulfillmentError' },
              { value: 'Canceled', label: 'Canceled' },
            ].filter(option => option.value !== shipment.shipmentStatus)}
            errorMessage={submitCount > 0 && errors.status}
          />

          {values.status === 'Canceled' && (
            <Dropdown
              label="Reason to change the status"
              name="reason"
              value={values.reason}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value !== 'Other') {
                  setFieldValue('otherReason', '');
                }
              }}
              options={[
                { value: 'Damaged', label: 'Damaged' },
                { value: 'Wrong Item', label: 'Wrong Item' },
                { value: 'Other', label: 'Other' },
              ]}
              errorMessage={submitCount > 0 && errors.reason}
            />
          )}

          {values.reason === 'Other' && (
            <TextArea
              label="Other Reason"
              name="otherReason"
              value={values.otherReason}
              onChange={handleChange}
              placeholder="Add other reason here"
              errorMessage={submitCount > 0 && errors.otherReason}
            />
          )}

          {values.status === 'Fulfilled' && (
            <>
              <Input
                label="Tracking Number"
                name="trackingNumber"
                value={values.trackingNumber}
                onChange={handleChange}
                placeholder="Enter tracking number"
              />
              <Input
                label="Tracking Link"
                name="trackingLink"
                value={values.trackingLink}
                onChange={handleChange}
                placeholder="Enter tracking URL"
              />
            </>
          )}

          {values.status === 'InProduction' && (
            <Input
              label="Vendor Facility Order ID"
              name="vendorOrderId"
              value={values.vendorOrderId}
              onChange={handleChange}
              placeholder="Enter vendor facility order ID"
            />
          )}

          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={() => { showChangeShipmentStatusModal(null); }}
            />
            <Button
              variant="primary"
              size="small"
              label="Update Status"
              onClick={handleSubmit}
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default ChangeShipmentStatusModal;
