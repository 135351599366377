import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {};

const langFolders = [
  'general',
  'orders',
  'partner'
];

const langs = [
  'en',
  'fr',
  'es',
  'pt'
];

for (const lang of langs) {
  resources[lang] = { translation: {} };

  for (const folder of langFolders) {
    const data = require(`./${folder}/${lang}.json`);
    resources[lang].translation[folder] = data;
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });