import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class InsightServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.InsightMS.Url
    });

    SetupInterceptors(this.api);
  }

  getAllQueries = async () => {
    try {
      const response = await this.api.get(`/v1/query`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching queries from API');
    }
  };

  runQueryById = async (queryId, runAs, dateRange) => {
    try {
      let url = `/v1/query/${queryId}/run`;

      if (dateRange) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - dateRange);
        url += `?startDate=${startDate.toISOString()}`;
      }

      // runAs not needed for now...
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Error running query');
    }
  };
}

export default new InsightServices();