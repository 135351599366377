import React from "react";
import { Routes, Route } from "react-router-dom";
import InventoryView from "./InventoryView";
import InventoryBulkUploadView from "./InventoryBulkUploadView";

function Inventory() {
  return (
    <Routes>
      <Route path="/" element={<InventoryView />} />
      <Route path="/upload" element={<InventoryBulkUploadView />} />
    </Routes>
  );
}

export default Inventory;
