import { handleActions } from 'redux-actions';
import { toggleSidebar, setLang } from "app/store/actions/config";

const initialState = {
  sidebar: {
    open: true
  },
  lang: 'en',
}

const configReducer = handleActions(
  {
    [toggleSidebar]: (state) => ({ 
      ...state,
      sidebar: {
        ...state.sidebar,
        open: !state.sidebar.open,
      },
    }),
    [setLang]: (state, { payload }) => ({
      ...state,
      lang: payload,
    }),
  },
  initialState,
)

export default configReducer;