import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const TextArea = ({
  className = '',               
  label = '',                   
  errorMessage = null,          
  hideError = false,            
  name,
  readonly = false,             
  ...rest
}) => {
  const showError = errorMessage && errorMessage.trim().length > 0;

  return (
    <div className={`gooten-text-area ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        className={`form-control ${showError ? 'has-error' : ''}`}
        name={name}
        readOnly={readonly}
        spellCheck="false"
        {...rest}
      />
      {!hideError && <div className="error">{errorMessage}</div>}
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
};

export default TextArea;
