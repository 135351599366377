import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  TableHeader,
  Pagination,
  LoadingAnimation,
  StatusChip,
  SearchBar,
  ToggleSwitch,
  Checkbox,
  Input,
  Dropdown,
} from "app/components";
import { ExclamationCircle } from "react-bootstrap-icons";
import "./index.scss";
import { getProductsByVendor, getVendors } from "app/store/actions/vendor";
import {
  vendorProductDataLoadingSelector,
  vendorProductDataSelector,
  vendorsDataSelector,
  vendorsLoadingSelector,
  vendorFacilityProductsSelector,
} from "app/store/selectors/vendor";
import {
  bulkUpload,
  reset,
  searchInventories,
} from "app/store/actions/inventory";
import {
  bulkUploadErrorSelector,
  bulkUploadLoadingSelector,
  bulkUploadSuccessSelector,
  searchInventoriesDataSelector,
  searchInventoriesLoadingSelector,
} from "app/store/selectors/inventory";
import { toast } from "react-toastify";

const InventoryView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const errorsFound = false;

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pageSize") || "50", 10);
  const searchString = searchParams.get("searchString") || null;
  const [selectedVendorId, setSelectedVendorId] = useState(
    searchParams.get("vendorId") || null
  );

  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsDataLoading = useSelector(vendorsLoadingSelector);
  const productsData = useSelector(vendorProductDataSelector);
  const vendorFacilityProducts = useSelector(vendorFacilityProductsSelector);
  const productsDataLoading = useSelector(vendorProductDataLoadingSelector);
  const inventoryData = useSelector(searchInventoriesDataSelector);
  const inventoryDataLoading = useSelector(searchInventoriesLoadingSelector);
  const bulkUploadSuccess = useSelector(bulkUploadSuccessSelector);
  const bulkUploadError = useSelector(bulkUploadErrorSelector);
  const bulkUploadLoading = useSelector(bulkUploadLoadingSelector);

  const loading =
    vendorsDataLoading ||
    productsDataLoading ||
    inventoryDataLoading ||
    bulkUploadLoading;

  const [displayData, setDisplayData] = useState([]);
  const [isMasterChecked, setIsMasterChecked] = useState(false);

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (!vendorsData?.vendors?.length) {
      dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
    }

    if (searchString && searchString.length > 0) {
      dispatch(
        getProductsByVendor({
          currentPage: currentPage,
          pageSize: pageSize,
          searchString: searchString,
          showBy: "facility",
          vendorId: selectedVendorId,
        })
      );
    }
  }, [searchString, currentPage, pageSize, selectedVendorId, location]);

  useEffect(() => {
    if (vendorFacilityProducts && vendorsData) {
      let vendorSkuLocationList = vendorFacilityProducts.map(
        (p) => ({
          vendorId: p.vendorId,
          sku: p.sku,
          locationId: p.facilityId,
        })
      );
      dispatch(
        searchInventories({
          page: 1,
          pageSize: 250,
          vendorSkuLocationList: vendorSkuLocationList,
        })
      );
      setDisplayData(
        vendorFacilityProducts.map((p) => ({
          id: p.id,
          vendorId: p.vendorId,
          vendorName: vendorsData.vendors.find((v) => v.id === p.vendorId).name,
          vendorFacilityId: p.facilityId,
          orderMeshSKU: p.sku,
          vendorSKU: p.vendorSku,
          skuStatus: p.status,
        }))
      );
    }
  }, [vendorFacilityProducts, vendorsData, location]);

  useEffect(() => {
    if (inventoryData && inventoryData.inventories) {
      const updatedDisplayData = displayData.map((dd) => {
        const matchingInventory = inventoryData.inventories.find(
          (inventory) =>
            dd.orderMeshSKU === inventory.sku &&
            dd.vendorId === inventory.vendorId &&
            dd.vendorFacilityId === inventory.locationId
        );
        if (matchingInventory) {
          return {
            ...dd,
            isInfinite: matchingInventory.hasInfiniteInventory,
            availableQuantity: matchingInventory.availableQuantity,
          };
        }
        return {
          ...dd,
          isInfinite: null,
          availableQuantity: null,
        };
      });
      setDisplayData(updatedDisplayData);
    }
  }, [inventoryData]);

  useEffect(() => {
    if (bulkUploadSuccess && bulkUploadSuccess.errors.length) {
      bulkUploadSuccess.errors.map((error) => {
        toast.error(error);
      });
    }
    if (bulkUploadError) {
      toast.error("Something went wrong while updating the inventory.");
    }
  }, [bulkUploadSuccess, bulkUploadError]);

  const onSelectedVendorUpdated = (e) => {
    const vendorId = e.target.value;
    setSelectedVendorId(vendorId);

    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    vendorId
      ? updatedSearchParams.set("vendorId", vendorId)
      : updatedSearchParams.delete("vendorId");

    updatedSearchParams.set("page", 1);
    setSearchParams(updatedSearchParams.toString());
  };

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    searchString
      ? updatedSearchParams.set("searchString", searchString)
      : updatedSearchParams.delete("searchString");

    if (selectedVendorId) {
      updatedSearchParams.set("vendorId", selectedVendorId);
    }

    updatedSearchParams.set("page", 1);
    setSearchParams(updatedSearchParams.toString());
  };

  const handleCheckboxChange = (id) => (e) => {
    const checked = e;
    const updatedInventory = displayData.map((item) =>
      item.id === id ? { ...item, checked } : item
    );
    setDisplayData(updatedInventory);

    const allChecked = updatedInventory.every((item) => item.checked);
    setIsMasterChecked(allChecked);
  };

  const handleMasterCheckboxChange = (e) => {
    const checked = e;
    setIsMasterChecked(checked);
    setDisplayData(displayData.map((item) => ({ ...item, checked })));
  };

  const doUpdate = (id, updatedDisplayData) => {
    const dataToUpdate = updatedDisplayData.filter(
      (d) => d.id == id || d.checked
    );

    const mappedArray = dataToUpdate.map((item) => {
      return {
        vendorId: item.vendorId,
        vendorFacilityId: item.vendorFacilityId,
        sku: item.orderMeshSKU,
        vendorSku: item.vendorSKU,
        quantity: item.availableQuantity,
        status:
          item.skuStatus?.toLowerCase() == "available"
            ? "Available"
            : "OutOfStock",
        isInfinite: item.isInfinite,
      };
    });

    dispatch(bulkUpload({ data: mappedArray }));
  };

  const toggleIsInfinite = (id) => (value) => {
    let updatedDisplayData = displayData.map((item) =>
      item.id === id || item.checked ? { ...item, isInfinite: value } : item
    );
    setDisplayData(updatedDisplayData);

    doUpdate(id, updatedDisplayData);
  };

  const handleQuantityChange = (id) => (e) => {
    const value = e.target.value;
    let updatedDisplayData = displayData.map((item) =>
      item.id === id || item.checked
        ? { ...item, availableQuantity: value }
        : item
    );
    setDisplayData(updatedDisplayData);
  };

  const handleSkuStatusChange = (id) => (e) => {
    const value = e.target.value;
    let updatedDisplayData = displayData.map((item) =>
      item.id === id || item.checked ? { ...item, skuStatus: value } : item
    );
    setDisplayData(updatedDisplayData);
    toggleSkuStatusEdit(
      updatedDisplayData.find((i) => i.id == id),
      false
    );
    doUpdate(id, updatedDisplayData);
  };

  const toggleQuantityEdit = (data, toggle) => {
    let updatedDisplayData = displayData.map((item) =>
      item.id === data.id || item.checked
        ? { ...item, isEditableQuantity: toggle }
        : item
    );
    setDisplayData(updatedDisplayData);
  };

  const toggleSkuStatusEdit = (data, toggle) => {
    setDisplayData((inventory) =>
      inventory.map((item) =>
        item.id === data.id || item.checked
          ? { ...item, isEditableSku: toggle }
          : item
      )
    );
  };

  const displayInventoryRow = (data) => {
    return (
      <tr className="inventory-row" key={data.id}>
        <td>
          <Checkbox
            size="medium"
            checked={data.checked}
            onChange={handleCheckboxChange(data.id)}
          />
        </td>
        <td>{data.vendorName}</td>
        <td>{data.vendorFacilityId}</td>
        <td>{data.orderMeshSKU}</td>
        <td>{data.vendorSKU}</td>
        <td>
          <Input
            name="availableQuantity"
            className={
              data.skuStatus == "Available" && data.isEditableQuantity
                ? "editable-input-cell"
                : "disabled-input-cell"
            }
            value={data.availableQuantity}
            onChange={handleQuantityChange(data.id)}
            onBlur={() => {
              doUpdate(data.id, displayData);
              toggleQuantityEdit(data, false);
            }}
            onFocus={() => toggleQuantityEdit(data, true)}
            type="number"
          />
        </td>
        <td className="inventory-toggle-switch">
          <ToggleSwitch
            isOn={data.isInfinite}
            disabled={data.skuStatus !== "Available"}
            onToggle={toggleIsInfinite(data.id)}
          />
        </td>
        <td className="inventory-status-chip">
          {data.isEditableSku ? (
            <Dropdown
              className="editable-dropdown-cell"
              name="skuStatus"
              value={data.skuStatus}
              onChange={handleSkuStatusChange(data.id)}
              options={[
                { value: "Available", label: "Available" },
                { value: "OutOfStock", label: "Out of stock" },
              ]}
            />
          ) : (
            <StatusChip
              type="inventory"
              status={data.skuStatus}
              onClick={() => toggleSkuStatusEdit(data, true)}
            />
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="inventory-view">
      {loading && <LoadingAnimation />}
      <Card>
        <div className="inventory-header">
          Inventory Bulk Updates
          <Button
            variant="primary"
            size="medium"
            label={"Bulk Updates"}
            disabled={errorsFound}
            onClick={() => {
              navigate(`upload`);
            }}
          />
        </div>
        <div className="search-bar-and-filters">
          <Dropdown
            label="Select a Vendor first"
            name="selectVendor"
            className="vendor-selection"
            searchable={true}
            disabled={false}
            value={selectedVendorId}
            onChange={(e) => {
              onSelectedVendorUpdated(e);
            }}
            options={
              vendorsData?.vendors?.map((v) => ({
                value: v.id,
                label: v.name,
              })) || []
            }
            placeholder="Select a Vendor first"
            errorMessage={errorsFound}
          />
          <SearchBar
            searchPlaceholderText="Search for a OrderMesh SKU or Vendor SKU"
            onSearchStringUpdated={onSearchStringUpdated}
            debounceDelay={500}
            disabled={errorsFound || !selectedVendorId}
          />
        </div>
        <Table size="medium">
          <TableHeader
            options={[
              { id: "check", isMasterCheckbox: true, label: "" },
              { id: "vendorName", label: "Vendor Name", orderable: false },
              {
                id: "vendorFacilityId",
                label: "Vendor Facility Id",
                orderable: false,
              },
              { id: "orderMeshSKU", label: "OrderMesh SKU", orderable: false },
              { id: "vendorSKU", label: "Vendor SKU", orderable: false },
              { id: "qAvailable", label: "Q. Available", orderable: false },
              { id: "isInfinite", label: "Is Infinite", orderable: false },
              {
                id: "status",
                label: "Status",
                align: "center",
                orderable: false,
              },
            ]}
            isMasterChecked={isMasterChecked}
            onMasterCheckboxChange={handleMasterCheckboxChange}
          />
          <tbody className="table-body">
            {displayData &&
              displayData?.map((item) => displayInventoryRow(item))}
            {displayData.length === 0 &&
              searchString &&
              !errorsFound &&
              !loading && (
                <tr className="inventory-row">
                  <td colSpan="8" className="error-message">
                    No Results Found
                  </td>
                </tr>
              )}
            {errorsFound && !loading && (
              <tr>
                <td colSpan="8" className="error-message">
                  <ExclamationCircle />
                  <span className="centered-message">
                    Error fetching inventory data
                  </span>
                </td>
              </tr>
            )}
            {!searchString && !loading && (
              <tr>
                <td colSpan="8" className="error-message">
                  <ExclamationCircle />
                  <span className="centered-message">
                    No inventory to show first search for OrderMesh SKU or
                    Vendor SKU
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {displayData && productsData && (
          <Pagination totalItems={productsData.total} hideBelow={30} />
        )}
      </Card>
    </div>
  );
};

export default InventoryView;
