import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.scss';

const TabbedMenu = ({
  tabs,
  showTabs = true,
  queryKey = null,  
  ...restProps
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const getTabIndexFromQuery = () => {
    if (!queryKey) return -1;
    const params = new URLSearchParams(location.search);
    const tab = params.get(queryKey);
    const tabIndex = tabs.findIndex((t) => t.query === tab);
    return tabIndex === -1 ? 0 : tabIndex;
  };

  const getTabIndexFromPath = () => {
    const urlPath = location.pathname;
    const urlSearch = location.search;

    const tabIndex = tabs.findIndex((tab) => {
      const [tabPath, tabSearch] = tab.path.split('?');
      const pathMatch = urlPath.endsWith(tabPath) || tabPath === '';
      const searchMatch =
        urlSearch === `?${tabSearch}` || tabSearch === undefined;
      return pathMatch && searchMatch;
    });
    return tabIndex === -1 ? 0 : tabIndex;
  };

  const [activeTab, setActiveTab] = useState(() => {
    const queryIndex = getTabIndexFromQuery();
    const pathIndex = getTabIndexFromPath();
    return queryIndex !== -1 ? queryIndex : pathIndex;
  });

  useEffect(() => {
    const queryIndex = getTabIndexFromQuery();
    const pathIndex = getTabIndexFromPath();
    const newIndex = queryIndex !== -1 ? queryIndex : pathIndex;
    if (newIndex !== activeTab) {
      setActiveTab(newIndex);
    }
  }, [location.search, location.pathname, tabs]);

  const handleTabChange = (index) => {
    if (queryKey) {
      const tab = tabs[index];
      navigate(`?${queryKey}=${tab.query}`);
      setActiveTab(index);
    } else {
      navigate(tabs[index].path);
      setActiveTab(index);
    }
  };

  return (
    <div className="gooten-tabbed-menu">
      {showTabs && (
        <div className="tabbed-menu__tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tabbed-menu__tab ${
                activeTab === index ? 'active' : ''
              }`}
              onClick={() => handleTabChange(index)}
            >
              {t(tab.label)}
              <div
                className={`tabbed-menu__tab-underline ${
                  activeTab === index ? 'animate' : ''
                }`}
              ></div>
            </div>
          ))}
        </div>
      )}
      {tabs[activeTab]?.content && (
        <div className="tabbed-menu__content">
          {React.createElement(tabs[activeTab].content, {
            ...restProps,
            ...tabs[activeTab].props,
          })}
        </div>
      )}
    </div>
  );
};

TabbedMenu.propTypes = {
  tabs: PropTypes.array.isRequired,
  showTabs: PropTypes.bool,
  queryKey: PropTypes.string,
};

export default TabbedMenu;
