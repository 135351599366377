import { createSelector } from 'reselect';

const userSelector = state => state.user;

export const userAuthSelector = createSelector([userSelector], userView => userView.auth);

export const permissionsByResourceSelector = createSelector([userSelector, (_, resource) => resource], (userView, resource) => (userView.permissions && userView.permissions[resource]?.map(permission => permission.name)) || []);

export const currentUserSelector = createSelector([userSelector], userView => userView.currentUser?.data);

export const usersDataSelector = createSelector([userSelector], userView => userView.users.data);
export const usersLoadingSelector = createSelector([userSelector], userView => userView.users.loading);
export const usersErrorsSelector = createSelector([userSelector], userView => userView.users.error);

export const userDetailsSelector = createSelector([userSelector], userView => userView.user.data);
export const userDetailsLoadingSelector = createSelector([userSelector], userView => userView.user.loading);
export const userDetailsErrorsSelector = createSelector([userSelector], userView => userView.user.errors);

// those are all the roles that system has...
export const rolesDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const rolesLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const rolesErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const rolesWithPermissionsDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const rolesWithPermissionsLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const rolesWithPermissionsErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const roleDetailsSelector = createSelector([userSelector], userView => userView.role?.data);
export const roleDetailsLoadingSelector = createSelector([userSelector], userView => userView.role?.loading);
export const roleDetailsErrorsSelector = createSelector([userSelector], userView => userView.role?.errors);

export const resourcesDataSelector = createSelector([userSelector], userView => userView.resources?.data);
export const resourcesLoadingSelector = createSelector([userSelector], userView => userView.resources?.loading);
export const resourcesErrorsSelector = createSelector([userSelector], userView => userView.resources?.errors);

export const searchRolesDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const searchRolesLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const searchRolesErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const groupsSelector = createSelector([userSelector], userView => userView.groups?.items);
export const selectedGroupSelector = createSelector([userSelector], userView => userView.groups?.selected);

export const viewAsSelector = createSelector([userSelector], userView => userView.viewAs);

// selector to get subscriber dynamically
export const subscriberSelector = createSelector(
  [currentUserSelector, viewAsSelector],
  (currentUser, viewAs) => {
    // look in the currentUser.roles array and see if any role 'name' is admin
    const isAdmin = currentUser?.roles?.find(role => role.name === 'admin');

    if (viewAs) {
      return { id: viewAs.id, role: viewAs.userType };
    } else if (isAdmin) {
      return { id: isAdmin.id, role: 'admin' };
    } else if (currentUser?.attributes?.vendorIds?.[0]) {
      return { id: currentUser.attributes.vendorIds[0], role: 'vendor' };
    } else if (currentUser?.attributes?.merchantIds?.[0]) {
      return { id: currentUser.attributes.merchantIds[0], role: 'merchant' };
    } else {
      return null;
    }
  }
);

export const userSchemaSelector = createSelector([userSelector], userView => userView.userSchema?.data);
export const userSchemaLoadingSelector = createSelector([userSelector], userView => userView.userSchema?.loading); 

export const userConfigurationSelector = createSelector([userSelector], userView => userView.userConfiguration?.data);
export const userConfigurationLoadingSelector = createSelector([userSelector], userView => userView.userConfiguration?.loading);